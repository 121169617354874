import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "../Utils";

const Itinerary = () => {
  const mytour = useSelector((state) => state.oneTourReducer);
  const [itinerarylist, setItinerarylist] = useState([]);
  const itineraries = useSelector((state) => state.itineraryReducer);
  const marron = "#562b05";

  useEffect(() => {
    // Vous pouvez déplacer cette logique directement dans l'effet useEffect
    // pour éviter la duplication de code dans la fonction map.
    const newitinerarylist = Array.from({ length: 20 }, (_, i) => i + 1).map(
      (i) => {
        const dbdaykey = `jour${i}`;
        const itineraryid = mytour[dbdaykey];
        const myitinerary =
          !isEmpty(itineraries) &&
          itineraries.find((itinerary) => itinerary.id === itineraryid);

        // Vérifiez si mytour[dbdaykey] est défini et différent de "0",
        // si myitinerary n'est pas vide et si cities contient la ville associée à l'itinéraire.
        if (mytour[dbdaykey] && mytour[dbdaykey] !== "0") {
          return myitinerary.id;
        }
      }
    );
    // Mettez à jour la liste des villes après avoir effectué toutes les itérations.
    setItinerarylist(newitinerarylist);
  }, [mytour, itineraries]);
  // console.log(itinerarylist);

  const hideorshowitinerary = (id) => {
    const itinerary =
      !isEmpty(itinerarylist) &&
      itinerarylist.find((itinerary) => itinerary && itinerary === id);
    if (itinerary) {
      return "showme";
    } else {
      return "hideme";
    }
  };


  
  return (
    <g className="itineraries">
      {/* **********************************************************  
                                  { Antananarivo - Andasibe }
            ************************************************************ */}
      <g id="Antananarivo_-_Andasibe" className={hideorshowitinerary("13")}>
        <path
          id="Tana-Andasibe"
          d="M271.979,312.81c0,0-14.072-14.005-40.19-6.136"
        />
        <g
          id="Car_Antananarivo_-_Andasibe"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="91.112" cy="105.748" r="0.151" />
          <circle cx="87.167" cy="105.748" r="0.151" />
          <g id="car">
            <path
              fill="none"
              d="M91.479,104.144l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0.001-0.005,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.057,0.801-1.057,1.388c0,0.252,0.205,0.456,0.457,0.456h0.267c0.075,0.377,0.408,0.661,0.806,0.661
                                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.075,0.377,0.408,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C92.603,104.794,92.13,104.243,91.479,104.144z M88.986,103.413c0.547,0,1.072,0.228,1.445,0.626
                                h-1.74l-0.253-0.624c0.021-0.001,0.043-0.002,0.064-0.002H88.986z M88.121,103.453l0.237,0.586h-1.252
                                C87.373,103.739,87.729,103.534,88.121,103.453z M87.168,106.26c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                                c0.283,0,0.513,0.23,0.513,0.513C87.68,106.031,87.45,106.26,87.168,106.26z M91.112,106.26c-0.283,0-0.513-0.23-0.513-0.512
                                c0-0.283,0.23-0.513,0.513-0.513c0.283,0,0.512,0.23,0.512,0.513C91.624,106.031,91.395,106.26,91.112,106.26z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                  { Antananarivo - Antsirabe }
            ************************************************************ */}
      <g id="Antananarivo_-_Antsirabe" className={hideorshowitinerary("37")}>
        <path
          id="Tana_-_Antsirabe_1_"
          d="M232.789,305.673c0,0,7.118,23.217-16.073,43.217"
        />
        <g
          id="Car__Antananarivo_Antsirabe"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="88.621" cy="116.424" r="0.151" />
          <circle cx="84.676" cy="116.424" r="0.151" />
          <path
            fill="#562B05"
            d="M88.988,114.82l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.005,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.057,0.8-1.057,1.388c0,0.252,0.205,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.075,0.376,0.408,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C90.112,115.47,89.639,114.919,88.988,114.82z M86.495,114.089c0.547,0,1.072,0.228,1.445,0.626
                                h-1.74l-0.253-0.624c0.021-0.001,0.043-0.002,0.064-0.002H86.495z M85.629,114.129l0.237,0.587h-1.252
                                C84.882,114.415,85.237,114.211,85.629,114.129z M84.677,116.937c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                                c0.283,0,0.513,0.23,0.513,0.513C85.189,116.707,84.959,116.937,84.677,116.937z M88.621,116.937c-0.283,0-0.513-0.23-0.513-0.512
                                c0-0.283,0.23-0.513,0.513-0.513c0.283,0,0.512,0.23,0.512,0.513C89.133,116.707,88.904,116.937,88.621,116.937z"
          />
        </g>
      </g>

      {/* **********************************************************  
                                  { Antsirabe - Ranomafana }
            ************************************************************ */}
      <g id="Antsirabe_-_Ranomafana_1_" className={hideorshowitinerary("55")}>
        <path
          id="Antsirabe_-_Ranomafana"
          d="M216.716,348.89c0,0-0.185,61.772,15.073,53.354"
        />
        <g
          id="Car_Antsirabe_-__Ranomafana"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="74.03" cy="133.112" r="0.151" />
          <circle cx="70.085" cy="133.112" r="0.151" />
          <path
            fill="#562B05"
            d="M74.397,131.508l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.005,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.057,0.8-1.057,1.388c0,0.252,0.205,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                                s0.731-0.285,0.806-0.661h2.332c0.075,0.376,0.408,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C75.521,132.157,75.049,131.607,74.397,131.508z M71.904,130.777
                                c0.547,0,1.072,0.228,1.445,0.626h-1.74l-0.253-0.624c0.021-0.001,0.043-0.002,0.064-0.002H71.904z M71.039,130.817l0.237,0.587
                                h-1.252C70.292,131.103,70.647,130.898,71.039,130.817z M70.086,133.624c-0.283,0-0.513-0.23-0.513-0.512s0.23-0.513,0.513-0.513
                                c0.283,0,0.513,0.23,0.513,0.513S70.368,133.624,70.086,133.624z M74.03,133.624c-0.283,0-0.513-0.23-0.513-0.512
                                s0.23-0.513,0.513-0.513c0.283,0,0.512,0.23,0.512,0.513S74.313,133.624,74.03,133.624z"
          />
        </g>
      </g>

      {/* **********************************************************  
                                  { Ranomafana - Fianarantsoa }
            ************************************************************ */}
      <g
        id="Ranomafana_-_Fianarantsoa_1_"
        className={hideorshowitinerary("56")}
      >
        <path
          id="Ranomafana_-_Fianarantsoa"
          d="M216.81,413.39c0,0,1.027-12.086,13.813-10.086"
        />

        <g
          id="Car_Fianarantsoa_Ranohira_1_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="83.563" cy="146.859" r="0.151" />
          <circle cx="79.618" cy="146.859" r="0.151" />
          <path
            fill="#562B05"
            d="M83.93,145.255l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                                s0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C85.054,145.904,84.581,145.354,83.93,145.255z M81.437,144.524c0.547,0,1.072,0.228,1.445,0.626
                                h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H81.437z M80.572,144.564l0.237,0.587h-1.252
                                C79.825,144.85,80.18,144.645,80.572,144.564z M79.619,147.371c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                                c0.282,0,0.512,0.23,0.512,0.513C80.131,147.142,79.901,147.371,79.619,147.371z M83.563,147.371c-0.283,0-0.512-0.23-0.512-0.512
                                c0-0.283,0.229-0.513,0.512-0.513s0.512,0.23,0.512,0.513C84.075,147.142,83.846,147.371,83.563,147.371z"
          />
        </g>
      </g>

      {/* **********************************************************  
                                  { Fianarantsoa - Ranohira }
            ************************************************************ */}
      <g id="_Fianarantsoa_-_Ranohira" className={hideorshowitinerary("57")}>
        <path
          id="Fianarantsoa_-_Ranohira"
          d="M215.058,414.39c0,0-34.532,46.719-63.15,45.647"
        />
        <g
          id="Car_Fianarantsoa_Ranohira"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="72.94" cy="159.987" r="0.151" />
          <circle cx="68.995" cy="159.987" r="0.151" />
          <path
            fill="#562B05"
            d="M73.307,158.382l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.005,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.057,0.8-1.057,1.388c0,0.252,0.205,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.075,0.376,0.408,0.661,0.806,0.661c0.398,0,0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C74.431,159.032,73.958,158.482,73.307,158.382z M70.814,157.652
                                c0.547,0,1.072,0.228,1.445,0.626h-1.74l-0.253-0.624c0.021-0.001,0.043-0.002,0.064-0.002H70.814z M69.949,157.691l0.237,0.587
                                h-1.252C69.201,157.978,69.557,157.773,69.949,157.691z M68.996,160.499c-0.283,0-0.513-0.23-0.513-0.512
                                c0-0.283,0.23-0.513,0.513-0.513c0.283,0,0.513,0.23,0.513,0.513C69.508,160.269,69.278,160.499,68.996,160.499z M72.94,160.499
                                c-0.283,0-0.512-0.23-0.512-0.512c0-0.283,0.23-0.513,0.512-0.513s0.512,0.23,0.512,0.513
                                C73.452,160.269,73.223,160.499,72.94,160.499z"
          />
        </g>
      </g>

      {/* **********************************************************  
                                  { Ranohira - Tuléar }
            ************************************************************ */}
      <g id="Ranohira_-_Tulear" className={hideorshowitinerary("45")}>
        <path
          id="Ranohira_-_Tulear_-_Ifaty_1_"
          d="
                            M151.032,460.89c-56.875,18.086-62.529,34.271-62.529,34.271s-5.028-1.638-6.062-15.497"
        />
        <g
          id="Car_Ranohira_-_Tulear"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="45.815" cy="171.11" r="0.151" />
          <circle cx="41.87" cy="171.11" r="0.151" />
          <path
            fill="#562B05"
            d="M46.182,169.505l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.005,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.057,0.8-1.057,1.388c0,0.252,0.205,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.075,0.376,0.408,0.661,0.806,0.661c0.398,0,0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C47.305,170.155,46.833,169.605,46.182,169.505z M43.689,168.775
                                c0.547,0,1.072,0.228,1.445,0.626h-1.74l-0.253-0.624c0.021-0.001,0.043-0.002,0.064-0.002H43.689z M42.823,168.814l0.237,0.587
                                h-1.252C42.076,169.101,42.431,168.896,42.823,168.814z M41.87,171.622c-0.283,0-0.513-0.23-0.513-0.512s0.23-0.513,0.513-0.513
                                c0.283,0,0.513,0.23,0.513,0.513S42.153,171.622,41.87,171.622z M45.815,171.622c-0.283,0-0.513-0.23-0.513-0.512
                                s0.23-0.513,0.513-0.513c0.283,0,0.512,0.23,0.512,0.513S46.097,171.622,45.815,171.622z"
          />
        </g>
      </g>

      {/* **********************************************************  
                                  { Tuléar - Antananarivo }
            ************************************************************ */}
      <g id="Tulear_Tana" className={hideorshowitinerary("46")}>
        <path
          id="Tulear-_Antananarivo"
          className="planepath"
          d="M231.723,307.1c0,0-119.815,24.704-143.219,188.061"
        />
        <g id="Plane_Tulear_-Tana">
          <path
            className="plane"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            d="M48.022,129.799c-0.007,0-0.015-0.001-0.022-0.002
                                c-0.039-0.007-0.071-0.033-0.087-0.069l-1.949-4.457l-1.399,1.399l0.39,1.281c0.013,0.042,0.001,0.087-0.03,0.118l-0.763,0.762
                                c-0.027,0.027-0.066,0.039-0.104,0.033c-0.038-0.007-0.07-0.032-0.087-0.066l-0.891-1.9l-1.93-0.905
                                c-0.035-0.017-0.06-0.049-0.066-0.087c-0.007-0.038,0.006-0.077,0.033-0.104l0.763-0.763c0.031-0.031,0.076-0.042,0.119-0.03
                                l1.28,0.39l1.407-1.407l-4.457-1.949c-0.036-0.016-0.062-0.048-0.069-0.087c-0.007-0.039,0.005-0.078,0.033-0.106l0.893-0.893
                                c0.028-0.028,0.069-0.04,0.108-0.032l5.415,1.144l2.671-2.672c0.171-0.171,0.398-0.264,0.639-0.264
                                c0.242,0,0.468,0.094,0.639,0.264c0.171,0.171,0.265,0.398,0.265,0.639c0,0.242-0.094,0.468-0.265,0.639l-2.671,2.671l1.144,5.414
                                c0.008,0.039-0.004,0.08-0.032,0.108l-0.893,0.893C48.084,129.787,48.053,129.799,48.022,129.799z M49.364,119.482h0.001H49.364z"
          />
        </g>
      </g>

      {/* **********************************************************  
                                  { Plane Tana - Morondava }
            ************************************************************ */}
      <g id="Plane_Tana_-_Morondava" className={hideorshowitinerary("53")}>
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M65.407,120.114c0.007,0.001,0.014,0.005,0.02,0.007
                c0.036,0.017,0.06,0.051,0.067,0.09l0.71,4.812l1.717-0.982l-0.04-1.338c-0.002-0.044,0.022-0.084,0.059-0.106l0.937-0.535
                c0.033-0.019,0.074-0.02,0.109-0.005c0.034,0.018,0.059,0.05,0.066,0.087l0.361,2.067l1.625,1.381
                c0.029,0.026,0.045,0.063,0.041,0.101c-0.003,0.039-0.026,0.072-0.059,0.092l-0.936,0.536c-0.038,0.022-0.084,0.021-0.123-0.002
                l-1.132-0.713l-1.728,0.988l3.789,3.052c0.03,0.025,0.047,0.063,0.043,0.102c-0.004,0.04-0.025,0.075-0.06,0.093l-1.095,0.628
                c-0.035,0.019-0.078,0.02-0.113,0.002l-4.924-2.526l-3.279,1.876c-0.21,0.12-0.454,0.15-0.686,0.087
                c-0.234-0.063-0.427-0.213-0.547-0.423c-0.12-0.209-0.15-0.453-0.088-0.685c0.063-0.234,0.214-0.428,0.424-0.547l3.279-1.876
                l0.318-5.525c0.003-0.039,0.025-0.075,0.059-0.095l1.097-0.627C65.344,120.11,65.377,120.106,65.407,120.114z M61.402,129.716
                L61.402,129.716L61.402,129.716z"
        />
        <path
          id="Tana-Morondava"
          className="planepath"
          d="M231.544,307.138
                    c0,0-82.413,26.781-122.939,63.682"
        />
      </g>

      {/* **********************************************************  
                                  { Plane - Morondava - Tana }
            ************************************************************ */}
      <g id="plane-morondava-tana" className={hideorshowitinerary("17")}>
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M59.505,116.404c-0.006-0.003-0.013-0.007-0.019-0.011
                    c-0.033-0.021-0.053-0.058-0.054-0.097l-0.05-4.864l-1.835,0.74l-0.142,1.332c-0.005,0.043-0.033,0.08-0.074,0.097l-1.001,0.402
                    c-0.036,0.015-0.076,0.01-0.109-0.01c-0.032-0.022-0.052-0.057-0.054-0.095l-0.077-2.097l-1.422-1.588
                    c-0.025-0.03-0.036-0.069-0.027-0.106c0.009-0.038,0.036-0.069,0.071-0.083l1.001-0.404c0.04-0.016,0.086-0.009,0.121,0.019
                    l1.025,0.86l1.845-0.744l-3.339-3.538c-0.026-0.029-0.038-0.068-0.029-0.107c0.009-0.039,0.035-0.07,0.072-0.085l1.171-0.472
                    c0.037-0.015,0.079-0.01,0.112,0.013l4.536,3.171l3.503-1.414c0.224-0.091,0.47-0.087,0.692,0.007
                    c0.222,0.095,0.394,0.27,0.484,0.493c0.091,0.224,0.088,0.47-0.006,0.692c-0.095,0.223-0.27,0.394-0.494,0.484l-3.503,1.413
                    l-1.066,5.43c-0.008,0.039-0.035,0.072-0.072,0.087l-1.171,0.472C59.567,116.417,59.533,116.416,59.505,116.404z M64.777,107.435
                    L64.777,107.435L64.777,107.435z"
        />
        <path
          id="Morondava-Tana"
          className="planepath"
          d="M109,369.516 c0,0,68.965-25.439,122.544-60.917"
        />
      </g>

      {/* **********************************************************
                                  { Car - Nosy Be - Ankarana }
            ************************************************************ */}
      <g id="Car_Nosy_Be_-_Ankarana" className={hideorshowitinerary("30")}>
        <g
          id="Car_8_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="107.882" cy="34.917" r="0.151" />
          <circle cx="103.937" cy="34.917" r="0.151" />
          <path
            fill="#562B05"
            d="M108.249,33.313l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C109.373,33.962,108.9,33.412,108.249,33.313z M105.756,32.582c0.547,0,1.072,0.228,1.445,0.626
                    h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H105.756L105.756,32.582z M104.891,32.622l0.237,0.587h-1.252
                    C104.144,32.908,104.499,32.703,104.891,32.622z M103.938,35.429c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                    c0.282,0,0.512,0.23,0.512,0.513C104.45,35.2,104.22,35.429,103.938,35.429z M107.882,35.429c-0.283,0-0.512-0.23-0.512-0.512
                    c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C108.394,35.2,108.165,35.429,107.882,35.429z"
          />
        </g>
        <path
          id="Noys_Be_Ankarana"
          fill="none"
          stroke="#000000"
          strokeMiterlimit="10"
          d="M301.407,68.648 c2,2.167,1.167,3.833,0,7.426s-3.833,2.241-7.167,6.241s-0.166,9-7.666,9.879s-10.5,7.621-13.334,4.454s-5.104-8.908-5.104-8.908"
        />
      </g>

      {/* **********************************************************
                                  { Car - Ankarana - Nosy Be  }
            ************************************************************ */}
      <g id="Car_Nosy_Be_-_Ankarana" className={hideorshowitinerary("62")}>
        <g
          id="Car_8_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="107.882" cy="34.917" r="0.151" />
          <circle cx="103.937" cy="34.917" r="0.151" />
          <path
            fill="#562B05"
            d="M108.249,33.313l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C109.373,33.962,108.9,33.412,108.249,33.313z M105.756,32.582c0.547,0,1.072,0.228,1.445,0.626
                    h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H105.756L105.756,32.582z M104.891,32.622l0.237,0.587h-1.252
                    C104.144,32.908,104.499,32.703,104.891,32.622z M103.938,35.429c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                    c0.282,0,0.512,0.23,0.512,0.513C104.45,35.2,104.22,35.429,103.938,35.429z M107.882,35.429c-0.283,0-0.512-0.23-0.512-0.512
                    c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C108.394,35.2,108.165,35.429,107.882,35.429z"
          />
        </g>
        <path
          id="Noys_Be_Ankarana"
          fill="none"
          stroke="#000000"
          strokeMiterlimit="10"
          d="M301.407,68.648 c2,2.167,1.167,3.833,0,7.426s-3.833,2.241-7.167,6.241s-0.166,9-7.666,9.879s-10.5,7.621-13.334,4.454s-5.104-8.908-5.104-8.908"
        />
      </g>

      {/* **********************************************************  
                        { Ankarana - Diego }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("32")}
        // className="showme"
      >
        <path
          id="Ankarana_-_Diego"
          d="M306.483,44.648c0,0,9.258,7,4.591,12.5
              s-4.667,5.5-4.667,5.5s-3.331,5.167-4.749,6.167"
        />
      </g>

      {/* **********************************************************  
                            { Car - Montagne d'Ambre - Nosy Be }
            ************************************************************ */}
      <g
        id="Car_Montagne_d_x27_Ambre_-_Nosy_Be"
        className={hideorshowitinerary("35")}
      >
        <g
          id="Car_9_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="112.825" cy="29.972" r="0.151" />
          <circle cx="108.881" cy="29.972" r="0.151" />
          <path
            fill="#562B05"
            d="M113.192,28.368l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                c0.252,0,0.457-0.205,0.457-0.456C114.317,29.017,113.844,28.467,113.192,28.368z M110.7,27.637c0.547,0,1.072,0.228,1.445,0.626
                h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H110.7L110.7,27.637z M109.835,27.677l0.237,0.587h-1.252
                C109.087,27.963,109.443,27.758,109.835,27.677z M108.882,30.484c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                c0.282,0,0.512,0.23,0.512,0.513C109.394,30.255,109.163,30.484,108.882,30.484z M112.825,30.484c-0.283,0-0.512-0.23-0.512-0.512
                c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C113.338,30.255,113.109,30.484,112.825,30.484z"
          />
        </g>
        <path
          id="Montagne_d_x27_Ambre_-_Nosy_BE"
          d="M304.658,55.315
              c0,0,5.582,0.833,4.749,3.833c-0.201,0.726-0.833,3-0.833,3s-0.515,2.833-2.091,4s-1.346,2.398-2.743,4.693
              s-4.269,7.351-6.324,8.823s-4.116,8.455-6.028,10.12s-9.022,4.565-10.918,4.631s-2.696,1.782-2.696,1.782s-3.389,2.058-5.794-1.169
              s-4.547-7.288-4.547-7.288"
        />
      </g>

      {/* **********************************************************  
                        { Car  - Toamasina - Soanierana Ivongo }
            ************************************************************ */}
      <g
        id="Car_Toamasina_-_Soanierana_Ivongo"
        className={hideorshowitinerary("65")}
      >
      <path id="Toamasina_Soanierana" d="M322.407,223.128c0,0-3.676-0.323-7.838,8.01s-6.963,16.333-6.063,21.333
        c0.899,5,4.666,7.814,3.252,16.164c-1.414,8.35-3.252,10.915-3.252,10.915"/>
        <g
          id="Car_3_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="109.364" cy="87.979" r="0.151" />
          <circle cx="105.419" cy="87.979" r="0.151" />
          <path
            fill="#562B05"
            d="M109.731,86.375l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C110.855,87.024,110.382,86.474,109.731,86.375z M107.238,85.644
                    c0.547,0,1.072,0.228,1.445,0.626h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H107.238L107.238,85.644z
                    M106.373,85.684l0.237,0.587h-1.252C105.626,85.97,105.981,85.765,106.373,85.684z M105.42,88.491
                    c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513c0.282,0,0.512,0.23,0.512,0.513
                    C105.932,88.262,105.702,88.491,105.42,88.491z M109.364,88.491c-0.283,0-0.512-0.23-0.512-0.512c0-0.283,0.229-0.513,0.512-0.513
                    c0.283,0,0.512,0.23,0.512,0.513C109.876,88.262,109.647,88.491,109.364,88.491z"
          />
        </g>
      </g>

      {/* **********************************************************  
                        { Antsirabe - Ambositra }
            ************************************************************ */}

      <g id="Car_Antsirabe_-_Zafimaniry" className={hideorshowitinerary("39")}>
        <path d="M215.622,348.891c0,0,0.03,19.317,8.25,27" />
        <g
          id="Car_2_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="88.067" cy="126.304" r="0.151" />
          <circle cx="84.122" cy="126.304" r="0.151" />
          <path
            fill="#562B05"
            d="M88.434,124.7l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C89.558,125.349,89.085,124.799,88.434,124.7z M85.941,123.968c0.547,0,1.072,0.228,1.445,0.626
                    h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H85.941L85.941,123.968z M85.076,124.009l0.237,0.587h-1.252
                    C84.329,124.294,84.684,124.089,85.076,124.009z M84.123,126.815c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                    c0.282,0,0.512,0.23,0.512,0.513C84.635,126.587,84.405,126.815,84.123,126.815z M88.067,126.815c-0.283,0-0.512-0.23-0.512-0.512
                    c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C88.579,126.587,88.35,126.815,88.067,126.815z"
          />
        </g>
      </g>

      {/* **********************************************************  
                        { Ambositra - Ranomafana }
            ************************************************************ */}

      <g id="Car_Zafimaniry_-_Ranomafana" className={hideorshowitinerary("40")}>
        <path d="M231.79,403.081c0,0-9.764-9.468-8.073-26.747" />
        <g
          id="Car_5_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="89.907" cy="136.848" r="0.151" />
          <circle cx="85.962" cy="136.848" r="0.151" />
          <path
            fill="#562B05"
            d="M90.274,135.244l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
                c0.252,0,0.457-0.205,0.457-0.456C91.398,135.894,90.925,135.343,90.274,135.244z M87.781,134.513
                c0.547,0,1.072,0.228,1.445,0.626h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H87.781L87.781,134.513z
                M86.916,134.553l0.237,0.587h-1.252C86.169,134.839,86.524,134.634,86.916,134.553z M85.963,137.36
                c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513c0.282,0,0.512,0.23,0.512,0.513
                C86.475,137.131,86.245,137.36,85.963,137.36z M89.907,137.36c-0.283,0-0.512-0.23-0.512-0.512c0-0.283,0.229-0.513,0.512-0.513
                c0.283,0,0.512,0.23,0.512,0.513C90.419,137.131,90.19,137.36,89.907,137.36z"
          />
        </g>
      </g>

      {/* **********************************************************  
                        { Ranomafana - Andringitra }
            ************************************************************ */}
      <g
        id="Car_Ranomafana_-_Andringitra"
        className={hideorshowitinerary("41")}
      >
        <path d="M212.407,439.305c0.25-1-2.328-2.485-1.652-12.25c0.254-3.668,8.992-29.896,20.802-23.932" />
        <g
          id="Car_6_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="81.821" cy="150.774" r="0.151" />
          <circle cx="77.876" cy="150.774" r="0.151" />
          <path
            fill="#562B05"
            d="M82.188,149.17l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C83.312,149.819,82.839,149.269,82.188,149.17z M79.695,148.438c0.547,0,1.072,0.228,1.445,0.626
                    h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H79.695L79.695,148.438z M78.83,148.479l0.237,0.587h-1.252
                    C78.083,148.764,78.438,148.559,78.83,148.479z M77.877,151.285c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                    c0.282,0,0.512,0.23,0.512,0.513C78.389,151.057,78.159,151.285,77.877,151.285z M81.821,151.285c-0.283,0-0.512-0.23-0.512-0.512
                    c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C82.333,151.057,82.104,151.285,81.821,151.285z"
          />
        </g>
      </g>

      {/* **********************************************************  
                        { Andringitra - Ranohira }
            ************************************************************ */}
      <g id="Car_Andringitra_-_Ranohira" className={hideorshowitinerary("43")}>
        <path
          d="M152.157,460.89c0,0,7-5.751,18.5-7.543s13-0.099,18.5-9.569c5.5-9.472,7.611-13.367,14-12.223
                        c4.422,0.793,6.969,4.5,9.484,7.75"
        />

        <g
          id="Car_7_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="73.808" cy="159.044" r="0.151" />
          <circle cx="69.864" cy="159.044" r="0.151" />
          <path
            fill="#562B05"
            d="M74.175,157.439l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747H71.2
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C75.3,158.089,74.827,157.539,74.175,157.439z M71.683,156.708c0.547,0,1.072,0.228,1.445,0.626
                    h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H71.683L71.683,156.708z M70.818,156.749l0.237,0.587h-1.252
                    C70.07,157.034,70.426,156.829,70.818,156.749z M69.865,159.555c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                    c0.282,0,0.512,0.23,0.512,0.513C70.377,159.327,70.147,159.555,69.865,159.555z M73.808,159.555c-0.283,0-0.512-0.23-0.512-0.512
                    c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C74.321,159.327,74.092,159.555,73.808,159.555z"
          />
        </g>
      </g>

      {/* **********************************************************  
                        { Andringitra - Ranomafana }
            ************************************************************ */}
      <g
        id="Car_Ranomafana_-_Andringitra"
        className={hideorshowitinerary("72")}
      >
        <path d="M212.407,439.305c0.25-1-2.328-2.485-1.652-12.25c0.254-3.668,8.992-29.896,20.802-23.932" />
        <g
          id="Car_6_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="81.821" cy="150.774" r="0.151" />
          <circle cx="77.876" cy="150.774" r="0.151" />
          <path
            fill="#562B05"
            d="M82.188,149.17l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C83.312,149.819,82.839,149.269,82.188,149.17z M79.695,148.438c0.547,0,1.072,0.228,1.445,0.626
                    h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H79.695L79.695,148.438z M78.83,148.479l0.237,0.587h-1.252
                    C78.083,148.764,78.438,148.559,78.83,148.479z M77.877,151.285c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                    c0.282,0,0.512,0.23,0.512,0.513C78.389,151.057,78.159,151.285,77.877,151.285z M81.821,151.285c-0.283,0-0.512-0.23-0.512-0.512
                    c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C82.333,151.057,82.104,151.285,81.821,151.285z"
          />
        </g>
      </g>
      {/* **********************************************************  
                        { Car Diego - Montagne d'Ambre }
            ************************************************************ */}
      <g
        id="Car_Diego_-_Montagne_d_x27_Ambre"
        className={hideorshowitinerary("34")}
      >
        <g
          id="Car_10_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="101.445" cy="18.504" r="0.151" />
          <circle cx="97.5" cy="18.504" r="0.151" />
          <path
            fill="#562B05"
            d="M101.812,16.9l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
                c0.252,0,0.457-0.205,0.457-0.456C102.936,17.549,102.463,16.999,101.812,16.9z M99.319,16.168c0.547,0,1.072,0.228,1.445,0.626
                h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H99.319L99.319,16.168z M98.454,16.209l0.237,0.587h-1.252
                C97.707,16.494,98.062,16.289,98.454,16.209z M97.501,19.015c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                c0.282,0,0.512,0.23,0.512,0.513C98.013,18.787,97.783,19.015,97.501,19.015z M101.445,19.015c-0.283,0-0.512-0.23-0.512-0.512
                c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C101.957,18.787,101.728,19.015,101.445,19.015z"
          />
        </g>
        <path
          id="Diego_-Montagne_D_x27_ambre"
          d="M305.658,44.648 c0,0,8.49,5.5,6.703,8s-6.287,3.167-7.787,3.167"
        />
      </g>

      {/* **********************************************************  
                        { Car Tana - Ankarafantsika }
            ************************************************************ */}
      <g id="Car_Tana_-_Ankarafantsika" className={hideorshowitinerary("0")}>
        <g
          id="Car_12_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="71.937" cy="92.777" r="0.151" />
          <circle cx="67.992" cy="92.777" r="0.151" />
          <path
            fill="#562B05"
            d="M72.304,91.173l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
              c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
              c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
              c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661s0.731-0.285,0.806-0.661h0.228
              c0.252,0,0.457-0.205,0.457-0.456C73.429,91.822,72.955,91.272,72.304,91.173z M69.811,90.442c0.547,0,1.072,0.228,1.445,0.626
              h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H69.811L69.811,90.442z M68.946,90.482l0.237,0.587h-1.252
              C68.199,90.768,68.554,90.563,68.946,90.482z M67.993,93.289c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
              c0.282,0,0.512,0.23,0.512,0.513C68.505,93.06,68.275,93.289,67.993,93.289z M71.937,93.289c-0.283,0-0.512-0.23-0.512-0.512
              c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C72.449,93.06,72.22,93.289,71.937,93.289z"
          />
        </g>
      </g>

      {/* **********************************************************  
                        { Plane Sainte Marie - Antananarivo }
            ************************************************************ */}

      <g
        id="Plane_Sainte_Marie__Tana"
        className={hideorshowitinerary("67")}
        // className='showme'
      >
        <path
          id="Sainte-_Marie_-_Tana"
          className="planepath"
          d="M231.555,308.272c17.815-56.206,101.341-86.8,101.341-86.8"
        />
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M92.982,78.676c0.007,0,0.015,0.002,0.022,0.002
              c0.039,0.007,0.071,0.034,0.087,0.07l1.915,4.471l1.409-1.388l-0.381-1.284c-0.012-0.042,0-0.087,0.031-0.117l0.769-0.756
              c0.027-0.027,0.066-0.039,0.104-0.033c0.038,0.008,0.07,0.033,0.087,0.067l0.876,1.907l1.924,0.92
              c0.034,0.018,0.06,0.05,0.065,0.088c0.007,0.038-0.007,0.077-0.034,0.104l-0.769,0.757c-0.031,0.03-0.076,0.041-0.119,0.029
              l-1.277-0.4l-1.418,1.396l4.442,1.983c0.035,0.016,0.062,0.048,0.068,0.087c0.007,0.04-0.006,0.079-0.034,0.106l-0.899,0.886
              c-0.029,0.027-0.07,0.039-0.109,0.031l-5.406-1.185l-2.692,2.651c-0.172,0.17-0.4,0.261-0.641,0.259
              c-0.242-0.001-0.467-0.097-0.637-0.269c-0.169-0.172-0.262-0.4-0.26-0.641c0.002-0.242,0.097-0.468,0.27-0.637l2.691-2.65
              l-1.103-5.423c-0.007-0.039,0.005-0.08,0.033-0.108l0.9-0.886C92.92,78.688,92.951,78.676,92.982,78.676z M91.561,88.982
              L91.561,88.982L91.561,88.982z"
        />
      </g>

      {/* **********************************************************  
                        { Plane Nosy Be - Antananarivo }
            ************************************************************ */}
      <g
        id="Plane_Nosy_Be_-_Tana"
        className={hideorshowitinerary("18")}
        // className='showme'
      >
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M71.69,62.109c0.005-0.005,0.011-0.01,0.016-0.015
            c0.031-0.024,0.073-0.03,0.11-0.018l4.598,1.586l-0.081-1.977l-1.206-0.581c-0.039-0.019-0.064-0.058-0.066-0.102l-0.043-1.077
            c-0.002-0.038,0.016-0.075,0.046-0.099c0.032-0.023,0.072-0.03,0.108-0.02l2.001,0.632l1.974-0.806
            c0.036-0.014,0.077-0.011,0.109,0.011c0.033,0.02,0.052,0.056,0.054,0.095l0.044,1.079c0.002,0.043-0.021,0.084-0.058,0.107
            l-1.154,0.677l0.081,1.988l4.454-1.957c0.035-0.015,0.077-0.013,0.11,0.008c0.034,0.022,0.055,0.057,0.055,0.097L82.893,63
            c0.001,0.04-0.018,0.078-0.05,0.101l-4.51,3.207l0.155,3.775c0.01,0.241-0.076,0.472-0.239,0.649
            c-0.164,0.178-0.386,0.28-0.627,0.291c-0.241,0.009-0.472-0.075-0.649-0.238c-0.178-0.164-0.281-0.386-0.29-0.628l-0.154-3.773
            l-4.757-2.828c-0.033-0.021-0.056-0.057-0.057-0.097l-0.052-1.262C71.658,62.163,71.67,62.132,71.69,62.109z M78.368,70.087
            L78.368,70.087L78.368,70.087z"
        />
        <path
          id="NosyBe-Tana"
          className="planepath"
          d="M261.274,74.678 c0,0-36.371,94.315-30.619,235.221"
        />
      </g>

      {/* **********************************************************  
                        { Plane Nosy Be - Antananarivo + Go }
            ************************************************************ */}
      <g
        id="Plane_Nosy_Be_-_Tana"
        className={hideorshowitinerary("63")}
        // className='showme'
      >
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M71.69,62.109c0.005-0.005,0.011-0.01,0.016-0.015
            c0.031-0.024,0.073-0.03,0.11-0.018l4.598,1.586l-0.081-1.977l-1.206-0.581c-0.039-0.019-0.064-0.058-0.066-0.102l-0.043-1.077
            c-0.002-0.038,0.016-0.075,0.046-0.099c0.032-0.023,0.072-0.03,0.108-0.02l2.001,0.632l1.974-0.806
            c0.036-0.014,0.077-0.011,0.109,0.011c0.033,0.02,0.052,0.056,0.054,0.095l0.044,1.079c0.002,0.043-0.021,0.084-0.058,0.107
            l-1.154,0.677l0.081,1.988l4.454-1.957c0.035-0.015,0.077-0.013,0.11,0.008c0.034,0.022,0.055,0.057,0.055,0.097L82.893,63
            c0.001,0.04-0.018,0.078-0.05,0.101l-4.51,3.207l0.155,3.775c0.01,0.241-0.076,0.472-0.239,0.649
            c-0.164,0.178-0.386,0.28-0.627,0.291c-0.241,0.009-0.472-0.075-0.649-0.238c-0.178-0.164-0.281-0.386-0.29-0.628l-0.154-3.773
            l-4.757-2.828c-0.033-0.021-0.056-0.057-0.057-0.097l-0.052-1.262C71.658,62.163,71.67,62.132,71.69,62.109z M78.368,70.087
            L78.368,70.087L78.368,70.087z"
        />
        <path
          id="NosyBe-Tana"
          className="planepath"
          d="M261.274,74.678 c0,0-36.371,94.315-30.619,235.221"
        />
      </g>

      {/* **********************************************************  
                        { Plane Antanaanrivo - Nosy Be }
            ************************************************************ */}
      <g id="Plane_Tana_-__Nosy_Be" className={hideorshowitinerary("18")}>
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M105.667,64.519c-0.005,0.005-0.011,0.01-0.016,0.015
            c-0.031,0.025-0.072,0.032-0.109,0.02l-4.637-1.468l0.132,1.974l1.221,0.55c0.039,0.018,0.066,0.056,0.068,0.1l0.071,1.076
            c0.002,0.038-0.014,0.076-0.043,0.1c-0.031,0.023-0.071,0.031-0.107,0.022l-2.016-0.581l-1.952,0.857
            c-0.036,0.015-0.077,0.013-0.109-0.008c-0.033-0.02-0.054-0.055-0.056-0.093l-0.072-1.077c-0.003-0.043,0.019-0.084,0.056-0.109
            l1.136-0.706L99.1,63.206l-4.402,2.071c-0.035,0.016-0.076,0.015-0.111-0.005c-0.034-0.021-0.056-0.056-0.058-0.096l-0.085-1.26
            c-0.002-0.04,0.016-0.079,0.048-0.103l4.426-3.322l-0.252-3.77c-0.016-0.241,0.064-0.474,0.222-0.655
            c0.159-0.182,0.378-0.29,0.62-0.307c0.24-0.015,0.474,0.063,0.655,0.222c0.182,0.159,0.291,0.379,0.306,0.62l0.251,3.768
            l4.828,2.704c0.034,0.02,0.058,0.056,0.06,0.095l0.084,1.26C105.698,64.464,105.687,64.495,105.667,64.519z M98.786,56.715
            L98.786,56.715L98.786,56.715z"
        />

        <path
          id="Tana_-_Nosy_Be"
          className="planepath"
          d="M230.789,307.673
            c0,0,44.108-89.125,32.529-232.987"
        />
      </g>

      {/* **********************************************************  
                        { Plane Toamasina - Antananarivo }
            ************************************************************ */}
      <g
        id="Plane_Toamasina_-_Tana"
        className={hideorshowitinerary("0")}
        // className="showme"
      >
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M92.133,88.274c0.007,0,0.015,0.002,0.022,0.002
            c0.039,0.007,0.071,0.034,0.087,0.07l1.915,4.471l1.409-1.388l-0.381-1.284c-0.012-0.042,0-0.087,0.031-0.117l0.769-0.756
            c0.027-0.027,0.066-0.039,0.104-0.033c0.038,0.008,0.07,0.033,0.087,0.067l0.876,1.907l1.924,0.92
            c0.034,0.018,0.06,0.05,0.065,0.088c0.007,0.038-0.007,0.077-0.034,0.104l-0.769,0.757c-0.031,0.03-0.076,0.041-0.119,0.029
            l-1.277-0.4l-1.418,1.396l4.442,1.983c0.035,0.016,0.062,0.048,0.068,0.087c0.007,0.04-0.006,0.079-0.034,0.106l-0.899,0.886
            c-0.029,0.027-0.07,0.039-0.109,0.031l-5.406-1.185l-2.692,2.651c-0.172,0.17-0.4,0.261-0.641,0.259
            c-0.242-0.001-0.467-0.097-0.637-0.269c-0.169-0.172-0.262-0.4-0.26-0.641c0.002-0.242,0.097-0.468,0.27-0.637l2.691-2.65
            l-1.103-5.423c-0.007-0.039,0.005-0.08,0.033-0.108l0.9-0.886C92.072,88.286,92.102,88.274,92.133,88.274z M90.713,98.58
            L90.713,98.58L90.713,98.58z"
        />
        <path
          id="Toamasina_-_Tana"
          className="planepath"
          d="M306.483,279.551c0,0-43.077,5.87-73.743,27.898"
        />
      </g>

      {/* **********************************************************  
                        { Plane Antananarivo - Ranohira }
            ************************************************************ */}
      <g id="Plane_Tana_-_Ranohira" className={hideorshowitinerary("15")}>
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M68.801,131.327c0.007-0.003,0.014-0.006,0.02-0.009
            c0.037-0.013,0.079-0.006,0.11,0.017l3.878,2.936l0.537-1.904l-0.966-0.927c-0.031-0.03-0.043-0.075-0.031-0.117l0.294-1.037
            c0.01-0.037,0.038-0.067,0.074-0.08c0.037-0.012,0.077-0.006,0.108,0.015l1.705,1.222l2.127-0.153
            c0.039-0.002,0.077,0.014,0.1,0.043c0.025,0.029,0.032,0.07,0.022,0.107l-0.293,1.039c-0.011,0.041-0.046,0.073-0.089,0.084
            l-1.307,0.285l-0.54,1.915l4.842-0.478c0.038-0.003,0.077,0.011,0.102,0.042c0.025,0.031,0.034,0.071,0.023,0.109l-0.342,1.215
            c-0.011,0.039-0.041,0.069-0.079,0.081l-5.283,1.648l-1.026,3.636c-0.066,0.232-0.219,0.425-0.429,0.543
            c-0.211,0.119-0.454,0.147-0.686,0.082c-0.232-0.065-0.426-0.218-0.543-0.428c-0.118-0.211-0.147-0.454-0.081-0.687l1.026-3.635
            l-3.644-4.165c-0.025-0.031-0.036-0.072-0.025-0.11l0.343-1.216C68.754,131.368,68.774,131.342,68.801,131.327z M72.671,140.984
            L72.671,140.984L72.671,140.984z"
        />
        <path
          id="Tana_-_Ranohira"
          className="planepath"
          d="M231.074,309.272c0,0-73.75,75.703-80.042,151.618"
        />
      </g>

      {/* **********************************************************  
                        { Plane Ranohira - Bekopaka }
            ************************************************************ */}
      <g id="Plane_Ranohira_-_Bekopaka" className={hideorshowitinerary("16")}>
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M60.356,132.974c-0.004,0.006-0.011,0.011-0.015,0.016
            c-0.03,0.026-0.071,0.035-0.108,0.026l-4.709-1.22l0.236,1.964l1.248,0.485c0.041,0.016,0.069,0.053,0.074,0.096l0.128,1.071
            c0.005,0.038-0.01,0.076-0.038,0.103c-0.03,0.025-0.069,0.035-0.106,0.027l-2.044-0.473l-1.904,0.959
            c-0.035,0.017-0.076,0.017-0.109-0.002c-0.034-0.018-0.056-0.052-0.061-0.09l-0.129-1.071c-0.005-0.043,0.014-0.086,0.05-0.113
            l1.098-0.765l-0.238-1.976l-4.286,2.302c-0.034,0.018-0.076,0.019-0.111,0c-0.036-0.019-0.059-0.052-0.063-0.092l-0.151-1.253
            c-0.004-0.04,0.012-0.08,0.042-0.105l4.244-3.552l-0.451-3.751c-0.029-0.24,0.038-0.476,0.187-0.666
            c0.149-0.19,0.363-0.31,0.603-0.339c0.239-0.029,0.476,0.038,0.666,0.186c0.19,0.149,0.311,0.363,0.339,0.603l0.451,3.75
            l4.964,2.445c0.035,0.018,0.06,0.052,0.065,0.092l0.15,1.254C60.384,132.918,60.375,132.949,60.356,132.974z M53.072,125.546
            L53.072,125.546L53.072,125.546z"
        />
        <path
          id="Ranohira_-_Bekopaka"
          className="planepath"
          d="M128.908,310.272c0,0-1.361,64.917,20.902,149.392"
        />
      </g>

      {/* **********************************************************  
                        { Morondava - Bekopaka }
            ************************************************************ */}
      <g className={hideorshowitinerary("9")}>
        <path
          id="Bekopaka_-_Morondava"
          d="M109.656,369.891c0,0,15.71-12.336,18.125-59.623"
        />
      </g>

      {/* **********************************************************  
                        { Morondava - Bekopaka }
            ************************************************************ */}
      <g className={hideorshowitinerary("50")}>
        <path
          id="Andasibe_-_Ankanin_x27_ny_Nofy"
          d="M273.289,310.272c0,0,18.188-13.678,28.903-6.823"
        />
      </g>

      {/* **********************************************************  
                        { Antananarivo - Miandrivazo }
            ************************************************************ */}
      <g className={hideorshowitinerary("5")}>
        <path
          id="Antananarivo_-_Miandrivazo"
          d="M155.872,336.158c0,0,33.029,20.371,59.75,12.732c6.063-1.733,10.105-10,16.167-43.219"
        />
        <g>
          <path id="Car_-_Antananarivo_-_Miandrivazo" fill="#562B05" d="M220.177,354.799l-1.737-0.265l-0.511-0.559
            c-1.217-1.335-2.951-2.1-4.758-2.1h-1.354c-0.295,0-0.587,0.022-0.874,0.064c-0.005,0-0.008,0-0.017,0.003
            c-1.694,0.247-3.217,1.188-4.192,2.633c-1.729,0.63-2.968,2.248-2.968,3.9c0,0.708,0.573,1.281,1.284,1.281h0.751
            c0.211,1.057,1.146,1.857,2.265,1.857s2.054-0.801,2.265-1.857h6.553c0.208,1.057,1.144,1.857,2.266,1.857
            c1.121,0,2.054-0.801,2.265-1.857h0.641c0.708,0,1.284-0.576,1.284-1.281C223.338,356.625,222.009,355.079,220.177,354.799z
            M213.174,352.744c1.537,0,3.013,0.641,4.061,1.759h-4.887l-0.711-1.753c0.06-0.003,0.118-0.006,0.18-0.006H213.174L213.174,352.744
            z M210.743,352.859l0.666,1.649h-3.518C208.641,353.66,209.641,353.084,210.743,352.859z M208.065,360.744
            c-0.795,0-1.441-0.646-1.441-1.438c0-0.795,0.646-1.441,1.441-1.441c0.792,0,1.438,0.646,1.438,1.441
            C209.504,360.103,208.857,360.744,208.065,360.744z M219.145,360.744c-0.796,0-1.439-0.646-1.439-1.438
            c0-0.795,0.644-1.441,1.439-1.441c0.795,0,1.438,0.646,1.438,1.441C220.587,360.103,219.943,360.744,219.145,360.744z"/>
        </g>
      </g>

      {/* **********************************************************  
                        { Miandrivazo - BeloTsiribihina }
            ************************************************************ */}
      <g className={hideorshowitinerary("7")}>
        <path
          id="Descente_Tsiribihina"
          className="river"
          d="M119.304,344.855c0,0,23.123-12.379,37.566-7.965"
        />
      </g>

      {/* **********************************************************  
                        { Tsiribihina - Bekopaka }
            ************************************************************ */}
      <g className={hideorshowitinerary("7")}>
        <path
          id="Belo-Sur-Tsiribihina_-_Bekopaka"
          d="M127.235,309.39c0,0,1.811,17.569-9.063,36.5"
        />
        <g>
          <path id="Car_-_Tsiribihina_-_Bekopaka" fill="#562B05" d="M119.866,310.65l-1.737-0.265l-0.511-0.559
            c-1.217-1.335-2.951-2.1-4.758-2.1h-1.354c-0.295,0-0.587,0.022-0.874,0.064c-0.005,0-0.008,0-0.017,0.003
            c-1.694,0.247-3.217,1.188-4.192,2.633c-1.729,0.63-2.968,2.248-2.968,3.9c0,0.708,0.573,1.281,1.284,1.281h0.751
            c0.211,1.057,1.146,1.857,2.265,1.857s2.054-0.801,2.265-1.857h6.553c0.208,1.057,1.144,1.857,2.266,1.857
            c1.121,0,2.054-0.801,2.265-1.857h0.641c0.708,0,1.284-0.576,1.284-1.281C123.027,312.476,121.698,310.93,119.866,310.65z
            M112.863,308.595c1.537,0,3.013,0.641,4.061,1.759h-4.887l-0.711-1.753c0.06-0.003,0.118-0.006,0.18-0.006H112.863L112.863,308.595
            z M110.432,308.71l0.666,1.649h-3.518C108.331,309.511,109.331,308.935,110.432,308.71z M107.755,316.595
            c-0.795,0-1.441-0.646-1.441-1.438c0-0.795,0.646-1.441,1.441-1.441c0.792,0,1.438,0.646,1.438,1.441
            C109.193,315.955,108.547,316.595,107.755,316.595z M118.835,316.595c-0.796,0-1.439-0.646-1.439-1.438
            c0-0.795,0.644-1.441,1.439-1.441c0.795,0,1.438,0.646,1.438,1.441C120.276,315.955,119.633,316.595,118.835,316.595z"/>
        </g>
      </g>

      {/* **********************************************************  
                        { Morondava - BeloMer }
            ************************************************************ */}
      <g className={hideorshowitinerary("10")}>
        <path
          id="Morondava_-_Belo_sur_Mer"
          d="M97.407,391.348c0,0,11.966-15.014,12.25-21.938"
        />
        {/* <g>
          <path id="Car_-_Morondava_-_Belo" fill="#562B05" d="M129.652,371.703l-1.737-0.266l-0.511-0.559c-1.217-1.334-2.951-2.1-4.758-2.1
            h-1.354c-0.295,0-0.587,0.023-0.874,0.064c-0.005,0-0.008,0-0.017,0.004c-1.694,0.246-3.217,1.188-4.192,2.633
            c-1.729,0.629-2.968,2.248-2.968,3.9c0,0.707,0.573,1.281,1.284,1.281h0.751c0.211,1.057,1.146,1.857,2.265,1.857
            s2.054-0.801,2.265-1.857h6.553c0.208,1.057,1.144,1.857,2.266,1.857c1.121,0,2.054-0.801,2.265-1.857h0.641
            c0.708,0,1.284-0.576,1.284-1.281C132.813,373.529,131.484,371.982,129.652,371.703z M122.649,369.648
            c1.537,0,3.013,0.641,4.061,1.758h-4.887l-0.711-1.752c0.06-0.004,0.118-0.006,0.18-0.006H122.649L122.649,369.648z
            M120.219,369.764l0.666,1.648h-3.518C118.117,370.564,119.117,369.988,120.219,369.764z M117.541,377.648
            c-0.795,0-1.441-0.646-1.441-1.439c0-0.795,0.646-1.441,1.441-1.441c0.792,0,1.438,0.646,1.438,1.441
            C118.979,377.008,118.333,377.648,117.541,377.648z M128.621,377.648c-0.796,0-1.439-0.646-1.439-1.439
            c0-0.795,0.644-1.441,1.439-1.441c0.795,0,1.438,0.646,1.438,1.441C130.062,377.008,129.419,377.648,128.621,377.648z"/>
        </g> */}
      </g>

      {/* **********************************************************  
                        { BeloMer - Tana }
            ************************************************************ */}
      <g className={hideorshowitinerary("11")}>
        <path
          className="plane"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          d="M59.505,116.404c-0.006-0.003-0.013-0.007-0.019-0.011
                c-0.033-0.021-0.053-0.058-0.054-0.097l-0.05-4.864l-1.835,0.74l-0.142,1.332c-0.005,0.043-0.033,0.08-0.074,0.097l-1.001,0.402
                c-0.036,0.015-0.076,0.01-0.109-0.01c-0.032-0.022-0.052-0.057-0.054-0.095l-0.077-2.097l-1.422-1.588
                c-0.025-0.03-0.036-0.069-0.027-0.106c0.009-0.038,0.036-0.069,0.071-0.083l1.001-0.404c0.04-0.016,0.086-0.009,0.121,0.019
                l1.025,0.86l1.845-0.744l-3.339-3.538c-0.026-0.029-0.038-0.068-0.029-0.107c0.009-0.039,0.035-0.07,0.072-0.085l1.171-0.472
                c0.037-0.015,0.079-0.01,0.112,0.013l4.536,3.171l3.503-1.414c0.224-0.091,0.47-0.087,0.692,0.007
                c0.222,0.095,0.394,0.27,0.484,0.493c0.091,0.224,0.088,0.47-0.006,0.692c-0.095,0.223-0.27,0.394-0.494,0.484l-3.503,1.413
                l-1.066,5.43c-0.008,0.039-0.035,0.072-0.072,0.087l-1.171,0.472C59.567,116.417,59.533,116.416,59.505,116.404z M64.777,107.435
                L64.777,107.435L64.777,107.435z"
        />

        <path
          id="Belo-Tana"
          className="planepath"
          d="M109,369.516 c0,0,68.965-25.439,122.544-60.917"
        />
      </g>

      {/* **********************************************************  
                        { Tana - Diego }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("59")}
        // className="showme"
      >
      <path
        className="plane"
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        d="M105.667,64.519c-0.005,0.005-0.011,0.01-0.016,0.015
          c-0.031,0.025-0.072,0.032-0.109,0.02l-4.637-1.468l0.132,1.974l1.221,0.55c0.039,0.018,0.066,0.056,0.068,0.1l0.071,1.076
          c0.002,0.038-0.014,0.076-0.043,0.1c-0.031,0.023-0.071,0.031-0.107,0.022l-2.016-0.581l-1.952,0.857
          c-0.036,0.015-0.077,0.013-0.109-0.008c-0.033-0.02-0.054-0.055-0.056-0.093l-0.072-1.077c-0.003-0.043,0.019-0.084,0.056-0.109
          l1.136-0.706L99.1,63.206l-4.402,2.071c-0.035,0.016-0.076,0.015-0.111-0.005c-0.034-0.021-0.056-0.056-0.058-0.096l-0.085-1.26
          c-0.002-0.04,0.016-0.079,0.048-0.103l4.426-3.322l-0.252-3.77c-0.016-0.241,0.064-0.474,0.222-0.655
          c0.159-0.182,0.378-0.29,0.62-0.307c0.24-0.015,0.474,0.063,0.655,0.222c0.182,0.159,0.291,0.379,0.306,0.62l0.251,3.768
          l4.828,2.704c0.034,0.02,0.058,0.056,0.06,0.095l0.084,1.26C105.698,64.464,105.687,64.495,105.667,64.519z M98.786,56.715
          L98.786,56.715L98.786,56.715z"
      />
        <path
          id="Tana-Diego"
          className="planepath"
          d="M231.79,309.273 c0,0,83.195-105.227,71.406-265.348"
        />
      </g>

      {/* **********************************************************  
                        { Toamasina - Soanierana Ivongo }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("0")}
        // className="showme"
      >
        <path
          id="Toamasina_Soanierana"
          d="M322.407,223.128c0,0-3.676-0.323-7.838,8.01s-6.963,16.333-6.063,21.333
              s4.666,7.814,3.252,16.164s-3.252,10.915-3.252,10.915"
        />
      </g>

      {/* **********************************************************  
                        { Soanierana Ivongo - Sainte Marie }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("0")}
        // className="showme"
      >
        <path
          id="Soanierana_Ivongo_-_Sainte_Marie"
          className="river"
          d="M332.66,225.054c0,0-6.735-0.03-8.403-0.249"
        />
      </g>

      {/* **********************************************************  
                        { Andasibe - Toamasina }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("0")}
        // className="showme"
      >
        <path
          id="Andasibe_-_Toamasina"
          d="M271.979,310.272c0,0,15.095,4.79,20.428,0.619s5.362-9.708,11.934-19.372
                c-2.806,4.126,6.184-10.083,4.166-10.799"
        />
      </g>

      {/* **********************************************************  
                        { FCE }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("295")}
        // className="showme"
      >
        <path
          id="FCE"
          d="M215.622,412.367c0,0,8.392-2.863,15.922,5.29s15.863,9.82,17.863,14.82s2,8.412,2,8.412"
        />
      </g>

      {/* **********************************************************  
                        { Manakara - Ranomafana }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("297")}
        // className="showme"
      >
        <path
          id="Manakara_-_Ranomafana"
          d="M252.074,441.143c0,0-4.666-6.185-3-14.759s4.721-11.908-0.307-16.241s-9.952-0.979-12.082-4
                s-3.516-3.173-6.063-2.753"
        />
      </g>
      {/* **********************************************************  
                        { Ranomafana - Ranohira }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("298")}
        // className="showme"
      >
      <path
        id="Ranomafana_-_Fianarantsoa"
        d="M216.81,413.39c0,0,1.027-12.086,13.813-10.086"
      />

      
        <path
          id="Fianarantsoa_-_Ranohira"
          d="M215.058,414.39c0,0-34.532,46.719-63.15,45.647"
        />
        <g
          id="Car_Fianarantsoa_Ranohira"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="72.94" cy="159.987" r="0.151" />
          <circle cx="68.995" cy="159.987" r="0.151" />
          <path
            fill="#562B05"
            d="M73.307,158.382l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.005,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.057,0.8-1.057,1.388c0,0.252,0.205,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.075,0.376,0.408,0.661,0.806,0.661c0.398,0,0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C74.431,159.032,73.958,158.482,73.307,158.382z M70.814,157.652
                                c0.547,0,1.072,0.228,1.445,0.626h-1.74l-0.253-0.624c0.021-0.001,0.043-0.002,0.064-0.002H70.814z M69.949,157.691l0.237,0.587
                                h-1.252C69.201,157.978,69.557,157.773,69.949,157.691z M68.996,160.499c-0.283,0-0.513-0.23-0.513-0.512
                                c0-0.283,0.23-0.513,0.513-0.513c0.283,0,0.513,0.23,0.513,0.513C69.508,160.269,69.278,160.499,68.996,160.499z M72.94,160.499
                                c-0.283,0-0.512-0.23-0.512-0.512c0-0.283,0.23-0.513,0.512-0.513s0.512,0.23,0.512,0.513
                                C73.452,160.269,73.223,160.499,72.94,160.499z"
          />
        </g>
      </g>
      
      {/* **********************************************************  
                        { Belo - Manja }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("285")}
        // className="showme"
      >
        <path id="Belo_-_Manja" stroke="#0077CC" strokeMiterlimit="10" d="M96.873,393.391c0,0,2.704,12.871,16.369,18.018" />
        <g>
          <path id="Belo_-_Manja_1_" fill="#562B05" d="M125.017,386.312l-1.737-0.265l-0.511-0.559c-1.217-1.335-2.951-2.1-4.758-2.1h-1.354
            c-0.295,0-0.587,0.022-0.874,0.064c-0.005,0-0.008,0-0.017,0.003c-1.694,0.247-3.217,1.188-4.192,2.633
            c-1.729,0.63-2.968,2.248-2.968,3.9c0,0.708,0.573,1.281,1.284,1.281h0.751c0.211,1.057,1.146,1.857,2.265,1.857
            s2.054-0.801,2.265-1.857h6.553c0.208,1.057,1.144,1.857,2.266,1.857c1.121,0,2.054-0.801,2.265-1.857h0.641
            c0.708,0,1.284-0.576,1.284-1.281C128.178,388.139,126.849,386.593,125.017,386.312z M118.014,384.258
            c1.537,0,3.013,0.641,4.061,1.759h-4.887l-0.711-1.753c0.06-0.003,0.118-0.006,0.18-0.006H118.014L118.014,384.258z
            M115.584,384.373l0.666,1.649h-3.518C113.482,385.174,114.482,384.598,115.584,384.373z M112.906,392.258
            c-0.795,0-1.441-0.646-1.441-1.438c0-0.795,0.646-1.441,1.441-1.441c0.792,0,1.438,0.646,1.438,1.441
            C114.344,391.617,113.698,392.258,112.906,392.258z M123.986,392.258c-0.796,0-1.439-0.646-1.439-1.438
            c0-0.795,0.644-1.441,1.439-1.441c0.795,0,1.438,0.646,1.438,1.441C125.427,391.617,124.784,392.258,123.986,392.258z"/>
        </g>

      </g>
      
      {/* **********************************************************  
                        { Manja - Morombe }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("286")}
        // className="showme"
      >
        <path id="Manja_-_Morombe" stroke="#0077CC" strokeMiterlimit="10" d="M77.383,426.37c0,0,13.835-14.49,33.275-14.49" />
        <g>
        </g>

      </g>



      
      {/* **********************************************************  
                        { Morombe - Salary }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("292")}
        // className="showme"
      >

        <path id="Morombe_-_Salary" stroke="#0077CC" strokeMiterlimit="10" d="M73.853,459.26c0,0,37.259-22.281,3.532-31.868" />
        <g>
          <path id="Morombe_-_Salary_1_" fill="#562B05" d="M113.803,437.312l-1.737-0.265l-0.511-0.559c-1.217-1.335-2.951-2.1-4.758-2.1
            h-1.354c-0.295,0-0.587,0.022-0.874,0.064c-0.005,0-0.008,0-0.017,0.003c-1.694,0.247-3.217,1.188-4.192,2.633
            c-1.729,0.63-2.968,2.248-2.968,3.9c0,0.708,0.573,1.281,1.284,1.281h0.751c0.211,1.057,1.146,1.857,2.265,1.857
            s2.054-0.801,2.265-1.857h6.553c0.208,1.057,1.144,1.857,2.266,1.857c1.121,0,2.054-0.801,2.265-1.857h0.641
            c0.708,0,1.284-0.576,1.284-1.281C116.965,439.139,115.635,437.593,113.803,437.312z M106.801,435.258
            c1.537,0,3.013,0.641,4.061,1.759h-4.887l-0.711-1.753c0.06-0.003,0.118-0.006,0.18-0.006H106.801L106.801,435.258z M104.37,435.373
            l0.666,1.649h-3.518C102.268,436.174,103.268,435.598,104.37,435.373z M101.692,443.258c-0.795,0-1.441-0.646-1.441-1.438
            c0-0.795,0.646-1.441,1.441-1.441c0.792,0,1.438,0.646,1.438,1.441C103.131,442.617,102.484,443.258,101.692,443.258z
            M112.772,443.258c-0.796,0-1.439-0.646-1.439-1.438c0-0.795,0.644-1.441,1.439-1.441c0.795,0,1.438,0.646,1.438,1.441
            C114.214,442.617,113.57,443.258,112.772,443.258z"/>
        </g>

      </g>



      
      {/* **********************************************************  
                        { Salary - Ifaty }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("288")}
        // className="showme"
      >

        <path id="Salary_-_Ifaty" stroke="#0077CC" strokeMiterlimit="10" d="M83.445,484.1c0,0,12.854-35.875-9.592-23.21" />
        <g>
            <path id="Salary_-_Ifaty_1_" fill="#562B05" d="M115.998,462.881l-1.737-0.265l-0.511-0.559c-1.217-1.335-2.951-2.1-4.758-2.1
            h-1.354c-0.295,0-0.587,0.022-0.874,0.064c-0.005,0-0.008,0-0.017,0.003c-1.694,0.247-3.217,1.188-4.192,2.633
            c-1.729,0.63-2.968,2.248-2.968,3.9c0,0.708,0.573,1.281,1.284,1.281h0.751c0.211,1.057,1.146,1.857,2.265,1.857
            s2.054-0.801,2.265-1.857h6.553c0.208,1.057,1.144,1.857,2.266,1.857c1.121,0,2.054-0.801,2.265-1.857h0.641
            c0.708,0,1.284-0.576,1.284-1.281C119.159,464.707,117.83,463.161,115.998,462.881z M108.995,460.826
            c1.537,0,3.013,0.641,4.061,1.759h-4.887l-0.711-1.753c0.06-0.003,0.118-0.006,0.18-0.006H108.995L108.995,460.826z
            M106.564,460.941l0.666,1.649h-3.518C104.463,461.742,105.463,461.166,106.564,460.941z M103.886,468.826
            c-0.795,0-1.441-0.646-1.441-1.438c0-0.795,0.646-1.441,1.441-1.441c0.792,0,1.438,0.646,1.438,1.441
            C105.325,468.185,104.678,468.826,103.886,468.826z M114.967,468.826c-0.796,0-1.439-0.646-1.439-1.438
            c0-0.795,0.644-1.441,1.439-1.441c0.795,0,1.438,0.646,1.438,1.441C116.408,468.185,115.764,468.826,114.967,468.826z"/>
        </g>

      </g>


      {/* **********************************************************  
                                  { Ifaty - Ranohira }
            ************************************************************ */}
      <g id="Ranohira_-_Tulear" className={hideorshowitinerary("70")}>
        <path
          id="Ranohira_-_Tulear_-_Ifaty_1_"
          d="
                            M151.032,460.89c-56.875,18.086-62.529,34.271-62.529,34.271s-5.028-1.638-6.062-15.497"
        />
        <g
          id="Car_Ranohira_-_Tulear"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="45.815" cy="171.11" r="0.151" />
          <circle cx="41.87" cy="171.11" r="0.151" />
          <path
            fill="#562B05"
            d="M46.182,169.505l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.483
                                c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.005,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                                c-0.615,0.224-1.057,0.8-1.057,1.388c0,0.252,0.205,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                                c0.398,0,0.731-0.285,0.806-0.661h2.332c0.075,0.376,0.408,0.661,0.806,0.661c0.398,0,0.731-0.285,0.806-0.661h0.228
                                c0.252,0,0.457-0.205,0.457-0.456C47.305,170.155,46.833,169.605,46.182,169.505z M43.689,168.775
                                c0.547,0,1.072,0.228,1.445,0.626h-1.74l-0.253-0.624c0.021-0.001,0.043-0.002,0.064-0.002H43.689z M42.823,168.814l0.237,0.587
                                h-1.252C42.076,169.101,42.431,168.896,42.823,168.814z M41.87,171.622c-0.283,0-0.513-0.23-0.513-0.512s0.23-0.513,0.513-0.513
                                c0.283,0,0.513,0.23,0.513,0.513S42.153,171.622,41.87,171.622z M45.815,171.622c-0.283,0-0.513-0.23-0.513-0.512
                                s0.23-0.513,0.513-0.513c0.283,0,0.512,0.23,0.512,0.513S46.097,171.622,45.815,171.622z"
          />
        </g>
      </g>

      
      {/* **********************************************************  
                        { Ranohira - Fianarantsoa }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("289")}
        // className="showme"
      >

      <path id="Ranohira_-_Fianarantsoa" fill="#562B05" stroke="#FDFAF7" strokeMiterlimit="10" d="M149.613,460.89
        c0,0,42.845-8.954,65.444-44.52"/>
        <g id="Car_Ranohira_-_Fianarantsoa">
          <g id="Car_11_" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
            <circle cx="75.648" cy="157.83" r="0.151"/>
            <circle cx="71.703" cy="157.83" r="0.151"/>
            <path fill="#562B05" d="M76.015,156.225l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.482
              c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
              c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
              c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
              c0.252,0,0.457-0.205,0.457-0.456C77.14,156.875,76.667,156.325,76.015,156.225z M73.522,155.494c0.547,0,1.072,0.228,1.445,0.626
              h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H73.522L73.522,155.494z M72.657,155.535l0.237,0.587h-1.252
              C71.91,155.82,72.265,155.615,72.657,155.535z M71.704,158.341c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
              c0.282,0,0.512,0.23,0.512,0.513C72.216,158.113,71.986,158.341,71.704,158.341z M75.648,158.341c-0.283,0-0.512-0.23-0.512-0.512
              c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C76.161,158.113,75.932,158.341,75.648,158.341z"/>
          </g>
        </g>
        


      </g>



      
      {/* **********************************************************  
                        { Fianarantsoa - Antsirabe }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("290")}
        // className="showme"
      >
      <path id="Fianarantsoa_-_Antsirabe" fill="#562B05" stroke="#FDFAF7" strokeMiterlimit="10" d="M215.057,349.685
        c0,0,11.416,47.119,2.633,65.119"/>
        <g id="Car_Fianarantsoa-_Antsirabe">
          <g id="Car_13_" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
            <circle cx="83.978" cy="135.116" r="0.151"/>
            <circle cx="80.033" cy="135.116" r="0.151"/>
            <path fill="#562B05" d="M84.345,133.511l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.482
              c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
              c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
              c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
              c0.252,0,0.457-0.205,0.457-0.456C85.47,134.161,84.997,133.611,84.345,133.511z M81.852,132.78c0.547,0,1.072,0.228,1.445,0.626
              h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H81.852L81.852,132.78z M80.987,132.821l0.237,0.587h-1.252
              C80.24,133.106,80.595,132.901,80.987,132.821z M80.034,135.627c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
              c0.282,0,0.512,0.23,0.512,0.513C80.546,135.399,80.316,135.627,80.034,135.627z M83.978,135.627c-0.283,0-0.512-0.23-0.512-0.512
              c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C84.491,135.399,84.261,135.627,83.978,135.627z"/>
          </g>
        </g>
        


      </g>


      
      {/* **********************************************************  
                        { Antsirabe - Fianarantsoa }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("294")}
        // className="showme"
      >
      <path id="Fianarantsoa_-_Antsirabe" fill="#562B05" stroke="#FDFAF7" strokeMiterlimit="10" d="M215.057,349.685
        c0,0,11.416,47.119,2.633,65.119"/>
        <g id="Car_Fianarantsoa-_Antsirabe">
          <g id="Car_13_" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
            <circle cx="83.978" cy="135.116" r="0.151"/>
            <circle cx="80.033" cy="135.116" r="0.151"/>
            <path fill="#562B05" d="M84.345,133.511l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747h-0.482
              c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
              c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
              c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
              c0.252,0,0.457-0.205,0.457-0.456C85.47,134.161,84.997,133.611,84.345,133.511z M81.852,132.78c0.547,0,1.072,0.228,1.445,0.626
              h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H81.852L81.852,132.78z M80.987,132.821l0.237,0.587h-1.252
              C80.24,133.106,80.595,132.901,80.987,132.821z M80.034,135.627c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
              c0.282,0,0.512,0.23,0.512,0.513C80.546,135.399,80.316,135.627,80.034,135.627z M83.978,135.627c-0.283,0-0.512-0.23-0.512-0.512
              c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C84.491,135.399,84.261,135.627,83.978,135.627z"/>
          </g>
        </g>
        


      </g>




      
      {/* **********************************************************  
                        { Ranomafana - Ambositra}
            ************************************************************ */}
      <g
        className={hideorshowitinerary("73")}
        // className="showme"
      >

      <path id="Ranomafana_-_Ambositra" fill="#562B05" d="M229.723,403.391c0,0-12.033-12.135-6.72-26.641"/>
        


      </g>



      
     
      {/* **********************************************************  
                        { Ranohira - Andringitra }
            ************************************************************ */}
      <g id="Car_Andringitra_-_Ranohira" className={hideorshowitinerary("71")}>
        <path
          d="M152.157,460.89c0,0,7-5.751,18.5-7.543s13-0.099,18.5-9.569c5.5-9.472,7.611-13.367,14-12.223
                        c4.422,0.793,6.969,4.5,9.484,7.75"
        />

        <g
          id="Car_7_"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <circle cx="73.808" cy="159.044" r="0.151" />
          <circle cx="69.864" cy="159.044" r="0.151" />
          <path
            fill="#562B05"
            d="M74.175,157.439l-0.618-0.094l-0.182-0.199c-0.433-0.475-1.05-0.747-1.693-0.747H71.2
                    c-0.105,0-0.209,0.008-0.311,0.023c-0.002,0-0.003,0-0.006,0.001c-0.603,0.088-1.145,0.423-1.492,0.937
                    c-0.615,0.224-1.056,0.8-1.056,1.388c0,0.252,0.204,0.456,0.457,0.456h0.267c0.075,0.376,0.408,0.661,0.806,0.661
                    c0.398,0,0.731-0.285,0.806-0.661h2.332c0.074,0.376,0.407,0.661,0.806,0.661c0.399,0,0.731-0.285,0.806-0.661h0.228
                    c0.252,0,0.457-0.205,0.457-0.456C75.3,158.089,74.827,157.539,74.175,157.439z M71.683,156.708c0.547,0,1.072,0.228,1.445,0.626
                    h-1.739l-0.253-0.624c0.021-0.001,0.042-0.002,0.064-0.002H71.683L71.683,156.708z M70.818,156.749l0.237,0.587h-1.252
                    C70.07,157.034,70.426,156.829,70.818,156.749z M69.865,159.555c-0.283,0-0.513-0.23-0.513-0.512c0-0.283,0.23-0.513,0.513-0.513
                    c0.282,0,0.512,0.23,0.512,0.513C70.377,159.327,70.147,159.555,69.865,159.555z M73.808,159.555c-0.283,0-0.512-0.23-0.512-0.512
                    c0-0.283,0.229-0.513,0.512-0.513c0.283,0,0.512,0.23,0.512,0.513C74.321,159.327,74.092,159.555,73.808,159.555z"
          />
        </g>
      </g>



      
      {/* **********************************************************  
                        { Ambositra - Antananarivo }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("74")}
        // className="showme"
      >
        <path id="Ambositra_-_Antananarivo" fill="#562B05" d="M230.514,308.899c0,0-16.636,45.728-6.643,66.992"/>
        


      </g>


      
      {/* **********************************************************  
                        { Tulear - Antananarivo }
            ************************************************************ */}

      
      <g id="Tulear_Tana" className={hideorshowitinerary("68")}>
        <path
          id="Tulear-_Antananarivo"
          className="planepath"
          d="M231.723,307.1c0,0-119.815,24.704-143.219,188.061"
        />
        
        <g id="Plane_Tana_-_Tulear" 
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
          <path className="plane" fill="#562B05" stroke="#FDFAF7" strokeMiterlimit="10" d="M55.201,133.575c0.007,0,0.015,0.003,0.022,0.004
            c0.038,0.011,0.068,0.041,0.08,0.077l1.503,4.626l1.529-1.255l-0.262-1.313c-0.009-0.043,0.008-0.087,0.041-0.114l0.834-0.684
            c0.03-0.025,0.07-0.032,0.107-0.023c0.037,0.012,0.067,0.039,0.08,0.075l0.7,1.978l1.832,1.09c0.033,0.02,0.055,0.055,0.057,0.093
            c0.003,0.039-0.014,0.075-0.043,0.1l-0.834,0.685c-0.034,0.027-0.08,0.034-0.122,0.018l-1.235-0.514l-1.538,1.263l4.245,2.376
            c0.034,0.019,0.057,0.054,0.06,0.094c0.003,0.04-0.012,0.077-0.044,0.103l-0.975,0.801c-0.032,0.025-0.074,0.033-0.111,0.021
            l-5.277-1.668l-2.92,2.397c-0.187,0.154-0.422,0.224-0.662,0.2c-0.24-0.023-0.456-0.139-0.61-0.326
            c-0.153-0.186-0.224-0.422-0.201-0.661c0.024-0.24,0.139-0.458,0.326-0.61l2.919-2.396l-0.607-5.5
            c-0.004-0.039,0.012-0.079,0.042-0.104l0.977-0.801C55.139,133.581,55.17,133.572,55.201,133.575z M52.854,143.71L52.854,143.71
            L52.854,143.71z"/>
        </g>
      </g>


      
      {/* **********************************************************  
                        { Manakara - Ranomafana }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("0")}
        // className="showme"
      >
        


      </g>



      
      {/* **********************************************************  
                        { Manakara - Ranomafana }
            ************************************************************ */}
      <g
        className={hideorshowitinerary("0")}
        // className="showme"
      >
        


      </g>
    </g>
  );
};

export default Itinerary;

import React from 'react';

const Madagascar = () => {
    return (
   
        <g id="g2627" transform="scale(2)">
          <path
            fill="#00A7A7"
            d="M183.123,174.804c-31.302,79-24,144.508-126,144.5c-55.051-0.004-64.521-53.237-51.336-159
                        c14.336-115,111.21-159,146.034-160.261C213.623-2.196,219.002,84.254,183.123,174.804z"
          />
          <path
            id="path706"
            fill="#D2B48C"
            d="M167.777,107.742h-0.332l-0.332,0.664v0.333h-0.333l0.333,0.663l-0.333,0.333l-0.332,1.001
                        l-0.665,0.333h-0.332v1.001h-0.332l-0.332,0.665v0.332v-0.332l-0.665,0.665v0.331l-0.332,0.332v0.332h-0.333v1.002l0.333,0.332
                        v0.333l-0.665,0.664v0.333h0.332v0.332h-0.332v-0.332l-0.332,0.332l-0.332,1.002v0.663l-0.333,0.665l0.333,0.332l0.332-0.332
                        v-0.665l0.664-1.002l0.333-0.332l0.332-0.663v-0.333l0.332-0.332l0.333-1.002l0.332-0.332v-0.665h0.332l-0.332,0.333v0.332
                        l0.663-0.665v-0.332l0.333-0.663v-0.333l0.664-1.671l1.002-2.342v-1.334L167.777,107.742z"
          />
          <path
            id="path150"
            fill="#D2B48C"
            d="M127.9,37.002v0.341h0.341H127.9v0.342h0.681v0.34l-0.34,0.34v0.682H127.9v0.34l0.341,0.34
                        v1.028l0.34,0.34l-0.34,0.342h0.34l0.342,0.34h0.34l-0.34-0.682l0.34,0.342l1.028,0.681h0.34v-0.341h1.028L132,42.461l0.681-0.342
                        l0.341-0.341v0.341l0.341-0.341v-0.682h-0.682L132,40.416l0.34-0.34v-0.342v0.342l0.341-0.342l0.341,0.342v-1.369h-0.682v-0.342
                        l0.341-0.34v-0.682h-0.341v0.342H132v-0.342h-0.341v-0.341l0.341-0.34l-0.341-0.34H132v-1.028l-0.682,0.34v-0.34h-0.681
                        l-0.341,0.34h-0.341v0.34l0.341,0.342v0.341l0.341,0.682l-0.341,0.34h-0.681l0.34-0.34h-0.34v0.34h-0.682v-0.34h-0.341v-0.342
                        h-0.682L127.9,37.002z"
          />
          <path
            id="path160"
            fill="#D2B48C"
            d="M126.872,38.717L126.872,38.717l0.34,0.342h-0.34h0.681v-0.342l0.341-0.34v-0.34l-0.341,0.34
                        h-0.341L126.872,38.717z"
          />
          <path
            id="path168"
            fill="#D2B48C"
            d="M132.364,43.869l0.34,0.681l0.341,0.342l0.342-0.342h0.341l0.34-0.34v-0.34l-0.34-0.682
                        l-0.683-0.34l-0.681,0.682V43.869z"
          />
          <path
            id="path22"
            fill="#D2B48C"
            d="M151.975,14.529l-0.227,0.198v0.226h-0.225l-0.198,0.198h-0.227l0.227,0.226h0.198h0.282v0.141
                        v0.226h-0.199v0.424l-0.225-0.198v0.198h-0.424V15.97l-0.225-0.226v0.648l0.225,0.226v-0.226h0.228v0.874h0.197v0.198v0.226h0.225
                        v0.225h-0.225l-0.197-0.225l0.197-0.226h-0.197l-0.228,0.226l0.228,0.225h-0.228V17.69v-0.226h-0.225v-0.199h-0.197V17.04
                        l-0.227-0.226h-0.228v0.226h-0.198l0.198,0.225h-0.647l-0.425,0.423h0.226l0.199,0.225l0.226-0.225h0.226l0.198-0.226l0.228,0.451
                        h0.227l-0.227,0.198h-0.228v0.226h0.228l0.227-0.226l0.422,0.226h0.197l-0.197,0.424l0.197,0.226h-0.423v-0.226h-0.197v0.226
                        l-0.227,0.198v0.226h-0.228v0.423l0.228,0.226v0.649l-0.228,0.196h-0.197h-0.226l-0.226-0.198h-0.425v0.649h-0.196l0.196,0.198
                        l-0.196,0.226l-0.227-0.226l-0.226,0.226v-0.226l0.226-0.198h-0.226l-0.424,0.424l-0.226-0.226l-0.197,0.226v0.226h0.197
                        l-0.197,0.198h-0.228v-0.199l-0.226,0.198l-0.423,0.226v0.226h0.226l0.198,0.198h-0.198l-0.226-0.198l-0.197,0.198l0.197,0.226
                        v0.198v0.226h-0.196v0.424h-0.226l0.226,0.648l-0.226,0.226v0.424h-0.227l-0.198,0.226h-0.225v0.198h-0.423l0.226-0.198
                        l-0.226-0.226v-0.198l-0.229-0.226v-0.226h-0.198l-0.225-0.198h0.225v-0.226l-0.225-0.226v-0.198l-0.227,0.198l-0.197-0.198h-1.1
                        v-0.226h-0.198v-0.226l-0.45,0.226v0.649l-0.425,0.226h0.425v0.198h0.225h0.228v-0.199h0.198v0.649l0.227,0.198l-0.227,0.226h0.647
                        v0.226l0.227,0.198l-0.227,0.226h0.227v0.649h0.226v-0.226l0.197,0.226l0.227-0.226v-0.2h0.198v0.198l0.224-0.198l0.229,0.198
                        v0.226l-0.229,0.198h-0.224v0.452l-0.198,0.198v0.226l0.198-0.226h0.224l-0.224,0.226v0.226l-0.198,0.198v0.423h0.648v0.226
                        l0.226,0.226v0.198h-0.226l0.226,0.452v0.846l0.198,0.226h0.225v0.198l0.198,0.226v0.226l0.227,0.198v0.226v0.874l0.226,0.423
                        l0.197-0.226v0.226h-0.197h-0.451l-0.198,0.649h0.198l0.227,0.198h-0.424v0.226h-0.225v0.423h0.225v-0.198h0.198v0.198h0.227v0.226
                        h-0.227l-0.198-0.226v0.226l-0.225-0.226v0.451l0.225,0.198h-0.225l-0.225-0.198l0.225-0.451h-0.225l-0.199,0.451l0.199,0.194
                        l-0.199,0.226v-0.226l-0.227,0.226l0.227,0.198h-0.45l0.224,0.226h-0.224h-0.198v0.226h-0.227v0.424l-0.197,0.226v0.198h-0.226
                        v0.423h0.226v0.226h-0.226v-0.226l-0.227,0.226l-0.197,0.226h0.197v0.198l-0.197,0.226h0.197v0.226h-0.197v0.198h0.197v0.226
                        h-0.197v0.226v0.198h0.197v0.225l-0.195,0.196l0.197,0.226h-0.197l0.197,0.225h0.226v0.198l0.226,0.226h-0.451v0.198h-0.197
                        l0.197,0.226h-0.423l0.227,0.226l-0.227,0.198h-0.226l0.226,0.226h-0.226l0.226,0.226h-0.226v0.198l0.226,0.226h0.227l0.197,0.198
                        H143.2l-0.648,0.649l0.226,0.226h-0.648v0.213h-0.226l-0.422,0.422v-0.226l-0.423,0.226l-0.227-0.226v0.452l-0.225-0.226h-1.073
                        l-0.226,0.226h0.226l-0.226,0.198v-0.198v-0.226l-0.648-0.226h-0.198l-0.451-0.423v-0.225h-0.424l0.227-0.198l-0.227-0.226v-0.198
                        h-0.648l0.227,0.198l-0.227,0.226l0.227,0.423v0.424h0.198v0.451h0.225v0.198l0.227,0.226v0.198h-0.227l0.424,0.226h-0.198
                        l0.198,0.423V45.9h-0.198h-0.229l0.227-0.226v-0.198l-0.227,0.198l-0.225-0.198h-0.198l0.198,0.198h-0.425L136.74,45.9h0.197v0.226
                        L136.74,45.9v-0.226l-0.225-0.198h-0.227v0.198h0.227v0.451l-0.227-0.226h-0.197l-0.228-0.226v0.226h-0.226l-0.198,0.226v0.198
                        v0.226v0.198l-0.227,0.226V46.55l-0.226-0.226l-0.198-0.423l-0.227,0.226h-0.197l-0.227,0.423l0.227,0.198v0.226l0.197,0.226
                        l0.227,0.198l-0.227,0.225v0.226h-0.197v0.198h-0.227v0.451v0.198l-0.225,0.226h0.225v0.198h0.227v0.226h-0.227l-0.225,0.225
                        l0.225,0.198V49.57h0.227v0.198v0.225h0.197v0.423h-0.197v0.226h-0.451l0.225,0.198h-0.225v0.226h-0.197l-0.228,0.226h-0.423v0.198
                        h-0.227v-0.198l-0.197,0.198h-0.228l-0.226-0.424l-0.423-0.423l-0.648-0.226v-0.423h0.228h0.201v-0.225h-0.423l0.226-0.198h-0.226
                        v-0.874l-0.198-0.226h-0.227l0.227-0.649h-0.227v-0.648h-0.648v-0.198h-0.227v0.423h-0.197l-0.451,0.226l0.225-0.226h0.226v-0.423
                        h0.198l-0.198-0.226l-0.226,0.424v-0.429h0.226l-0.226-0.226v-0.198l-0.225-0.226v-0.423h-0.649l-0.424,0.226h0.424l0.227,0.198
                        l0.226,0.423h-0.451h-0.198v0.226h-0.227v0.226l-0.222-0.226v0.508h-0.198v-0.027l-0.198-0.198l0.198-0.226v-0.226h-0.195h-0.226
                        v-0.423h0.226l0.197-0.226V45.73l-0.423-0.225v-0.226h-0.227v0.451l-0.423,0.847l-0.226,0.226h0.452v0.226h0.197v0.423h-0.197
                        v-0.226h-0.227l0.227-0.198h-0.227l-0.227,0.198l-0.197-0.198h-0.648l0.197,0.198l-0.197,0.226v0.424l-0.226,0.423l0.226,0.226
                        l-0.226,0.874h0.649l0.225-0.226v0.423h-0.874l-0.198,0.451l0.198,0.198l-0.423,0.424l0.225,0.451l0.198,0.198l0.226,0.226v-0.226
                        h0.197l-0.197,0.226h-0.226v0.649l0.226,0.424v0.649l0.648,0.226h0.197l0.453,0.198v0.226l0.197-0.226v0.451l0.225-0.226v-0.226
                        h0.227v0.677h0.197v-0.17h0.338v-0.085h0.311l-0.226,0.226v0.198h0.226l-0.226,0.226l-0.198,0.226v0.028h-0.339v0.536h0.085v1.012
                        h0.227l0.423-0.226l-0.197,0.226l0.197,0.226v0.198l0.227,0.423v0.226h-0.227l0.227,0.226l-0.227,0.198v0.648h0.452v0.226h-0.452
                        h-0.197l-0.226,0.226v0.197l-0.227-0.197v0.423v0.198v0.226h0.227l-0.227,0.226h0.451v0.198v0.226h-0.226v0.226h-0.198h-0.197
                        v0.424l0.169,0.395v0.254h-0.169v0.423h-0.226v0.226h-0.226l0.226-0.226V61.73h-0.226v-0.199h-0.197v-0.226l0.197-0.198v-0.226
                        v-0.226h-0.197l0.197-0.197l0.226-0.875v-0.226l0.226-0.648l0.197-0.198v-0.423h-0.197l0.197-0.226h-0.197l-0.451-0.226
                        l-0.198,0.226v-0.423l-0.452-0.226h-0.197l-0.226-0.226v0.452l-0.228,0.198l0.452,0.226l0.197,0.226v0.198l0.226,0.225v0.846
                        l-0.226,0.451v0.198l-0.423,0.451l-0.228-0.226v-0.226l-0.196,0.226h-0.227v0.226h-0.648v-0.226h-0.198l-0.227-0.226v-0.198
                        l-0.424-0.226h-0.225l0.225,0.226l-0.225,0.198h-0.228v-0.198l-0.197,0.423v0.648h0.197l-0.197,0.226h0.197l-0.197,0.198h-0.227
                        l-0.197,0.424l-0.225,0.226l-0.424,0.226v0.198l-0.226,0.649V63.9l-0.197,0.226v0.198h0.197l0.226,0.226v0.198l-0.226,0.226h0.226
                        V65.2l0.425,0.42v0.423l-0.423,0.226v0.198l0.423,0.226l-0.228,0.226l0.228,0.198h-0.228l0.228,0.451l0.424-0.226h0.225v-0.226
                        h0.199l0.225-0.198l-0.225,0.424h-0.199l-0.422,0.423v0.226h-0.227v0.648l-0.423,0.423h-0.423v0.423h-0.226l0.226,0.226
                        l-0.226,0.423l-0.425,0.226l-0.224,0.423l-0.227,0.226v0.198h0.227l-0.227,0.451l-0.424,0.423v0.226l-0.197,0.198v0.226
                        l-0.45,0.423h0.228v0.225l-0.228,0.424l-0.425,0.198l-0.226,0.226h0.226l-0.226,0.226l0.452,0.198l-0.452,0.451l0.226,0.198
                        l-0.226,0.226l-0.423,0.198l0.423-0.424v-0.198v-0.226l-0.198,0.226h-0.225l-0.196,0.419l-0.226,0.198v0.451l-0.423,0.423
                        l0.198,0.226h-0.198l-0.451,0.423h-0.2v0.198l-0.227,0.226V77.43h-0.422l-0.227-0.198v-0.875l0.227-0.423v-0.423l-0.227-0.649
                        v-0.226l0.424-0.423l0.225-0.424l0.426-0.226v-0.226l0.451-0.424l0.198-0.423l0.226-0.226v-0.198l0.226-0.648h-0.451L118,70.966
                        l-0.423-0.422v-0.226h0.198V70.12h-0.198L118,69.473h-0.226l-0.424-0.424v-0.451h-0.225l-0.2,0.451l-0.647,0.198l-0.874,1.101
                        l-1.072,1.072l-0.227,0.649l-0.424,0.226l0.198,0.198v0.424h0.226l0.424-0.198v0.198h0.451v-0.424l0.197-0.198h0.227v0.198h0.422
                        v0.226h-0.197v0.198l-0.451,0.226l0.227,0.226h-0.227l-0.197,0.198v-0.424l-0.226,0.226v0.198h-0.227l-0.424,0.226h-0.216
                        l-0.198,0.226h-0.226l-0.197,0.198l0.197,0.226l-0.197,0.226h-0.226l-0.226,0.198l-0.423,0.226v0.198h0.226v0.226l-0.226,0.226
                        l-0.198,0.423v0.198h-0.226l-1.071,0.875h-0.452l-0.423,0.226v0.423h-0.226l-0.197,0.226l-0.452,0.649v0.649l-0.198,0.198
                        l0.198,0.226h0.227l0.226,0.198v0.649h0.423v-0.202h0.227v0.423l-0.227-0.226v0.451h0.227l-0.227,0.198h0.423l0.452-0.198h0.197
                        l0.225,0.198l-0.225,1.1l0.423,0.424l0.226,0.648h1.072l0.226-0.225h0.227l0.198,0.225h-0.423l-0.226,0.225h-0.198v0.198h-0.226
                        l-0.198,0.45h-0.452l0.452,0.198l-0.226,0.226l-0.649-0.226v0.226l-0.225-0.226v-0.198l-0.197-0.226h-0.226h-0.227l-0.197,0.226
                        l0.197,0.198h-0.197l0.197,0.226l0.227,0.423h-0.227l-0.197-0.423v0.196v0.226l-0.227-0.424v0.424v0.422l-0.423,0.452v-0.237
                        l0.226-0.226l0.198-0.422h-0.198l-0.45-0.424v1.072l-0.425,0.649V86.08l-0.422-0.423v-0.225l-0.224-0.226v-0.198h-0.198
                        l-0.225,0.198v0.226l-0.227,0.874h-0.197l0.197,0.198h-0.197v0.451l-0.226,0.198l-0.423-0.198l-0.227,0.198h-0.198l0.198-0.198
                        l0.227-0.451h0.423l0.226-0.198v-1.099l0.423-0.424v-0.649l0.225-0.226l0.198-0.423v-0.198l-0.198,0.198v-0.198h0.198h0.224v-0.226
                        l0.424-0.226v-0.198l0.65-0.423v-0.226l-0.227-0.226l-0.198-0.198l-0.423-0.226h-0.451v-0.225l-0.423-0.196l-0.227-0.226h0.227
                        l-0.227-0.424v-0.423h0.227v-0.226h-0.227v-0.423h-0.197l-0.648,0.198l-0.874,0.451l-0.649,0.423l-0.422,0.198l-0.226,0.451
                        l-0.847,0.424l-1.52,1.523l-0.875,0.423v0.226l-0.423,0.198l-0.649,0.648l-0.225-0.198v0.198l-0.424,0.226l-0.422,0.422h-0.227
                        l-0.642,0.649v0.226l0.225,0.198h-0.225l-0.197-0.198V85.43h-0.226l-0.423,0.648l-0.226,0.226v0.226l-1.073,0.648l-0.452,0.649
                        l-0.196,0.198l-0.226,0.452v0.198l-0.198,0.423l-0.451,0.452h0.226v0.197h0.648h0.226l0.424-0.197h0.197l-0.197,0.197l0.197,0.226
                        h-0.197H96.01v0.649l-0.422,0.226v0.198l-0.226,0.226H94.94v0.423l0.226,0.226h0.198l0.226-0.226l0.648,0.226l1.297,0.649
                        l0.423,0.423l0.226,0.423l0.423,0.649v0.226l-0.648-0.423h-0.226l-0.198-0.226l0.423,0.875l0.423,0.198l0.225,0.423l-0.225-0.197
                        l-0.649-0.423h-0.875l0.451,0.423h0.424l0.226,0.197h-0.452l-0.846-0.423l-0.649-0.423l0.423,0.846l-0.423-0.423l-0.648-0.198
                        l0.198,0.423l0.226,0.198h-0.226l-0.192-0.198l-0.226-0.423l-0.197-0.226l-0.141,0.141l0.141,0.283l-0.226-0.198l0.085-0.085
                        l-0.537-1.016v-0.197l-0.197-0.649v-0.451l-0.648-0.197h-0.225v-0.226l0.226-0.226v0.226l0.226-0.226v-0.848l0.198-0.226v-0.198
                        l-0.198-0.225v-0.425h-0.223l-0.226-0.226h-0.423h-1.523l-0.198,0.226h-0.226l-1.296,1.299v0.197h-0.226l-0.198,0.226v0.226
                        l0.198,0.198l0.451-0.198l0.198-0.226v-0.226h0.226l0.423-0.423v0.226l0.226-0.226l0.198,0.226h-0.198l-0.451,0.197l0.226,0.226
                        v0.226h-0.226l-0.198,0.198v-0.198h-0.226v0.198l-0.226,0.226l0.226,0.226h0.424v0.198h0.226l0.226,0.226l-0.45-0.226l0.226,0.226
                        h-0.226l-0.141,0.141l0.141,0.283l-0.2-0.229l0.058-0.057l-0.058-0.141h-0.226l-0.423-0.424h-0.226l-0.226,0.198h-0.197v0.169
                        h-0.141v-0.028h-0.028l-0.226-0.198h-0.197l-0.226,0.198v-0.198l-0.226-0.451V91.41h0.226l-0.226-0.226h0.226v-0.198h-0.226
                        l-0.846-0.225v-0.198l-0.226,0.198l-0.226-0.198h-0.197v0.198l0.197,0.225h-0.197v-0.225h-0.226l-0.424,0.423l-0.423,0.226h0.423
                        v0.226l-0.423-0.226l-0.226-0.226v0.226l-0.226,0.226l-0.197-0.227h-0.422H83.75l0.226-0.226h-0.875l-0.847-0.424l0.198,0.226
                        l-0.424,0.198v0.226l-0.226,0.226l-0.424,0.198l-0.226,0.226h0.226l-0.423,0.226l0.197,0.198l0.226-0.198v0.424h0.226v0.226v0.649
                        l-0.226,0.197v0.874l0.226-0.226v0.226h-0.226h-0.423v0.592l-0.058,0.057l-0.197,0.225l0.423,0.424l0.226,0.226v0.198l-0.226-0.423
                        h-0.226l0.226,0.226h-0.226v0.198h-0.197v0.226h-0.226l0.226-0.226v-0.649h-0.226l-0.198,0.226l0.198,0.226h-0.197l-0.226-0.226
                        l-0.226,0.423l-0.198,0.226v-0.226l0.423-0.423h0.226v-0.423h0.198v-0.225v-0.226l-0.227-0.198L80.05,94.46l0.198-0.226h-0.194
                        l-0.226,0.226l-0.226-0.226l-0.424,0.226l-1.297,0.197l-0.423,0.226H76.16l-0.226,0.198v0.226h0.226v0.423l0.198,0.226h0.226
                        l0.197-0.226v0.226h0.452l-0.226,0.226h-0.226v0.198v0.226l-0.197,0.198l-0.226-0.198l-0.198,0.198h-0.451v0.452h0.451l0.198,0.423
                        H76.16v-0.226h-0.226v0.226l-0.226-0.226H75.51v0.226h-0.226v0.226l-0.226-0.226H74.86h-0.226l-0.226-0.226h0.226v-0.424h0.226
                        l-0.226-0.423V96.38l0.226,0.226V96.38l0.198-0.198l-0.198-0.451h-0.226l-0.226-0.198v-0.225v-1.299h-0.423l-0.226,0.226h-0.418
                        l-0.875,0.847h-0.423l-0.225,0.226l-1.072,1.298l-1.072,1.071l-0.649,0.452l-0.647,0.196l-0.226,0.226h0.226l-0.226,0.226h0.226
                        l0.198,0.197h0.226L69,99.2h-0.196l-0.226-0.226h-0.424h-0.649l-0.226-0.197L66.856,99.2v0.198L66.63,99.2v0.198h-0.196
                        l-0.226,0.226h0.226l-0.226,0.226H66.01v0.198h-0.226V99.85h-0.649l-0.226-0.226h-0.423l0.226-0.226l0.424,0.226l0.225-0.226
                        h-0.225L64.91,99.2l-0.649,0.198h-1.07L62.768,99.2l-0.226,0.198V99.2l-0.225,0.198L61.894,99.2h-0.226v-0.226L61.469,99.2h-0.649
                        l-1.072-0.226h-0.225L59.75,99.2h0.198v0.198H59.75L59.524,99.2v0.198L59.1,98.749l-0.226,0.226h-0.225v0.226l-0.196-0.226
                        l-0.226,0.226v0.423h0.226v0.423h-0.226v0.226l-0.198,1.071h-0.226l-0.226,0.424l0.226,0.648l-0.226-0.424v0.649l0.452,0.648v0.226
                        l0.198,0.422h0.226v0.226v0.198l0.198,0.226l0.226,0.649l-0.226,1.071l-0.424,0.226l-0.198,0.227v-0.649l-0.226,0.197v0.226
                        l-0.226,0.198l0.226,0.451l0.423,0.649v0.846v0.875l-0.198,0.227h-0.226l-0.226,0.197l-0.423,0.875l-0.424,0.197h-0.226v0.226
                        l0.226,0.227h-0.226l-0.198,0.423l-0.226,0.226v0.198h0.226v0.225h-0.226v-0.225h-0.226v0.225l-0.198,0.424h-0.226v0.226
                        l-0.226,0.424l-0.198,0.647v0.228l-0.221-0.232v0.424l-0.226,0.227v-0.227l-0.619,1.294l0.198-0.199h0.226l-0.423,0.199v0.226
                        v0.197l-0.113-0.084l-0.226,0.197l-0.112,0.339v-0.226l-0.649,0.875h0.226l-0.226,0.226l-0.198-0.226l-0.226,0.226l-0.225,0.62
                        v0.875l-0.198,0.422v-0.648l-0.226,0.227v0.422l-0.198,0.452h0.198l-0.198,0.197h-0.226v0.424l-0.226,0.227v-0.197l-0.423,0.423
                        l-0.424,0.846v0.648h-0.226v-0.423h-0.226v0.423h-0.113v-0.085l-0.028,0.085v0.028l-0.169,0.733l-0.226,0.228l-0.423,1.071v0.227
                        l-0.225-0.228v0.227l-0.198,0.424v0.226l0.424,0.424v0.226v0.197h-0.227l0.227-0.197l-0.424-0.452v0.875h0.198l-0.198,0.226v0.198
                        v0.227l-0.226,0.226l0.423,0.424h0.227v0.226l-0.227-0.226h-0.198l0.423,0.648l0.225-0.226h0.203l-0.198,0.224l0.198,0.423
                        l0.395,0.396h0.028h0.113v0.283h-0.113H49.51l0.198,0.227v0.084h0.113v0.227l0.226,0.226l0.424,0.197v0.227v1.946l-0.198,0.226
                        l-0.226,0.424l0.424,0.191h-0.424v1.298v0.648h-0.14l0.027,0.142v0.451v0.62l0.226,0.452v0.197h0.198l-0.198,0.226l0.198,0.227
                        h-0.198l0.198,0.197v0.225h-0.141l0.141,0.424v0.227h0.226v0.226l-0.226-0.226l0.226,0.875v0.198v0.45l0.225,0.198h-0.225v0.226
                        l0.225,0.848v0.451l-0.225,0.648v0.198l0.225,0.226h-0.225v0.423v0.424l0.423,0.226H50.81l0.198,0.649l1.072,1.1v0.198l0.226,0.226
                        v0.197l0.648,0.874v0.227h-0.198v0.197l0.649,0.874l0.198,0.847h0.226v-0.198l0.424,0.424v0.226l0.226,0.197h-0.226l-0.226-0.197
                        v-0.226l-0.198-0.227v0.227l0.424,0.874l0.423,1.072v1.522h-0.198v0.649v1.071l0.198,0.228h-0.423v0.647v1.297v0.227l0.198,0.424
                        v0.228l0.451,0.647v0.197H55.1v0.226h-0.198l0.198,0.197l1.101,1.524l0.648,0.874l-0.226,0.197l0.226,0.452l0.198,0.197h0.226
                        l0.226,0.226l0.198,0.424l0.226,0.227l0.423,0.647l0.424,0.848v1.749l0.226,0.196v0.227l-0.226,0.226v-0.226h-0.198v0.226h-0.228
                        v0.648l-0.198-0.226v0.647H57.92v0.423h-0.226l0.226,0.227h-0.226v0.424h0.226l0.198,0.226v0.197l-0.198-0.197l-0.226,0.424
                        l0.226,0.425l-0.226,0.45v0.424v0.227l-0.198-0.227l-0.226,0.227v-0.227l-0.226,0.227l0.226,0.197h-0.226l0.226,0.648l0.226,0.424
                        v-0.197h0.198v0.197h-0.198v0.227h0.423l0.649-0.227l0.423-0.424v0.227l-0.423,0.424v0.647h0.198l0.226,0.227h-0.226l0.451,1.298
                        v0.198l-0.225,0.226v0.423l-0.423,0.228h0.113v0.057l-0.085,0.085v0.423l-0.197,0.227v0.196l0.197-0.196l-0.197,0.423l0.423-0.227
                        l-0.226,0.227H58.4v-0.227h-0.226l-0.226,0.425l0.226,0.225H58.4l-0.226,0.228l-0.226-0.228v0.228H57.75v0.198l-0.226,0.228h0.424
                        l-0.198,0.224l-0.226-0.224h-0.199l-0.226,0.422h-0.225v0.226l-0.198,0.648v0.197l-0.226-0.197v0.424h-0.226v0.227l-0.198,0.198
                        l-0.226,0.451l-0.226,0.197l0.451-0.197l-0.226,0.197h-0.226l0.226,0.226h-0.226v0.197v0.027v0.197l-0.198,0.423l0.169-0.563
                        l-0.169,0.367l-0.423,0.196v0.451l-0.226,0.198v0.227h0.226l0.198,0.227h-0.198h-0.226v0.424l-0.226,0.423h0.226l-0.226,0.227
                        l-0.198,0.196h-0.226l0.226,0.228h-0.423v-0.228l-0.452,0.649l-0.198-0.199l-0.226,0.199v0.226l-0.219,0.216v-0.226l-0.198,0.424
                        v0.227v0.225l-0.423,0.423l0.198-0.423v-0.225l-0.423,0.225v0.423h-0.226l0.226,0.196l-0.424,0.875v0.875l-0.227,0.647h-0.226
                        v0.649l-0.423,0.648v0.197h-0.198l-0.226,0.228h-0.226l-0.198,0.226v0.196v0.227l0.198,0.198l0.226-0.198h0.226v0.198h-0.226
                        l-0.226,0.227H49.79l-0.085,0.084h-0.198v-0.228v-0.422h-0.223h-0.198v0.422l-0.451,0.452l-0.198,0.423l-0.226,0.228l-0.226,0.648
                        h-0.197v0.847l-0.452,0.45v0.621l-0.198,0.228l0.198,0.874l-0.198,0.647l-0.226,0.424l0.226,0.227h-0.223v1.071l-0.193,0.201v0.424
                        l-0.226,0.227l-0.226,0.423v0.423l-0.424,0.423v0.424l-0.198,0.452h-0.226l-0.226,0.196h0.226l-1.072,0.452v0.197h0.198v0.425
                        v0.226H44.35l0.198-0.226l-0.198-0.198h-0.452l0.226-0.227h0.226l-0.226-0.197v-0.227h-0.226l-0.423,0.424l-0.226,0.227v-0.227
                        l0.451-0.424h-0.226h-0.648l-0.226,0.227h-0.198h-0.226h-0.191v0.197h-0.227l0.226,0.227v0.112l0.197,0.086h-0.197v-0.086
                        l-0.226-0.112l-0.226,0.198h0.226v0.451l0.226,0.196l-0.226,0.227l0.226,0.227v0.197l-0.649-0.846l-0.226,0.197h-0.226v-0.197
                        l-0.198-0.228h-0.226l-0.226,0.425v0.451l0.226,0.197l0.226-0.197l0.198,0.197h-0.198l-0.452,0.226v-0.423h-0.198v-0.451
                        l0.198-0.425h-0.198v-0.226l-0.226,0.648v0.451l0.226,0.196v0.227l0.198,0.198v0.226v0.225h-0.198l0.198-0.225l-0.198-0.227h-0.226
                        v-0.621l-0.198,0.424v0.197v0.227v0.225h0.198l-0.198,0.199v0.227l-0.226-0.426v0.849l-0.226,0.451l0.451-0.227h0.198l-0.198,0.227
                        h-0.226v0.196l0.226,0.228h-0.226v0.227h-0.226v0.423v0.198h0.649l0.226,0.228h-0.649l0.226,0.647v0.227h0.198v0.225l-0.198-0.225
                        v0.225l0.198,0.198l-0.198,0.227v-0.227l-0.226,0.648v0.227l0.226,0.196v0.226l0.198-0.226v0.226l0.226,0.228h-0.226l-0.198-0.228
                        h-0.226h-0.226l0.226-0.226l-0.226-0.196v-0.227l-0.198,0.227l-0.226-0.227v0.423l-0.423,0.452l-0.226,0.646l-0.846,0.426
                        l-0.226,0.195l-0.224,0.646l0.226,0.45l0.225-0.225v0.225h-0.225l-0.226-0.225v1.72l0.226,0.227v0.423l-0.226,0.228v-0.228
                        l-0.198,0.228v0.227v0.197l-0.226,0.226v-0.226l0.226-0.197l-0.226-0.227h-0.226l0.226-0.228v-0.846l-0.226,0.196v0.452
                        l-0.197-0.228v0.228l-0.226,0.196l0.226,0.424v0.45l-0.221,0.2l-0.226-0.197v0.197l-0.198,0.227h0.198v0.563h-0.113l0.057,0.197
                        l-0.451,0.649v0.875H34.79l0.198,0.225v0.197H34.79l0.196,0.647l-0.198,0.417v0.451h0.198l0.226-0.229v0.875l0.649-0.646
                        l0.198,0.196v0.45l0.226,0.196v0.228l-0.226,0.226v0.199l-0.198-0.424l-0.226,0.226h-0.198l-0.226-0.226h-0.226v0.226h-0.197v0.425
                        h-0.226v0.422h0.226l0.198,0.229l-0.198,0.421v0.424l0.198,0.873l0.451,0.198l-0.226,0.225l0.226,0.227v1.299l0.424,0.847v2.171
                        l0.198,0.227v0.198l0.226,0.227l-0.226,0.226l0.451,0.198v0.451l0.198,0.424v0.423l0.226,0.424l0.225,0.649v0.227l-0.225,0.226
                        l0.225,0.196v0.424l0.198,0.451v0.197l0.649,0.65l0.226,0.45l0.649,0.425l0.228,0.396v0.423l0.198,0.228v0.197h0.423v0.678h0.452
                        l0.198,0.197h0.226v0.228l0.648,0.196l0.648,0.647v0.229l0.226,0.423v0.226l-0.226,0.425l0.226,0.226v0.423l0.198,0.423v2.596
                        h0.226v0.227h-0.227l-0.198-0.227v0.227l0.423,0.228v0.196l0.423,0.451h0.226l0.198,0.198l0.226,0.424v-0.198l0.226,0.198
                        l0.198-0.198v0.648l0.226,0.197l0.423,0.228l0.424,0.647v0.648h-0.202v0.647h-0.226l-0.198-0.227v0.227l0.198,0.227v0.424
                        l0.226-0.226v0.423h0.198h0.451l0.198,0.226l-0.649-0.226v0.226l-0.198-0.231l-0.226,0.451h-0.198l-0.226,0.197h-0.649
                        l-0.649,0.228v0.647l-0.226,0.424l-0.198,0.227h0.198v0.196h0.226l-0.226,0.451v0.196l-0.198,0.227l0.198,0.425v0.226h0.226v0.198
                        l-0.226-0.198h-0.198l0.198,0.426v0.422l0.423,0.45v0.196l-0.423,0.425l0.423,0.45l0.226,0.649l-0.226,0.196v0.228h-0.198
                        l0.198,0.647l-0.198,0.423l0.198,0.426v0.45h0.028v0.141v0.027v0.026h0.028l0.226,0.228v0.424l-0.226,0.847l0.226,0.452
                        l0.424,1.072v1.521l0.198,0.424h0.452l0.423,1.07v2.396l0.226,0.197v0.451l0.198,0.198v0.422l0.226,0.228l0.198,0.425v0.45h0.226
                        v0.417l0.226,1.071l0.198,0.228h0.226v0.225l0.226,0.198l0.198,0.423l0.423,0.452v0.647l0.226,0.424l0.226,0.227h0.423l0.648,0.619
                        h0.226l0.339,0.366v0.28l0.113,0.087v0.196l0.451,0.226l0.198,0.198v1.103l0.226,1.069l0.226,0.227l-0.226,0.649l0.226,0.425
                        l-0.451,0.226h-0.204l0.198,0.197l-0.198,0.226h0.424v-0.226h0.226l0.198,0.226v0.228v0.647l0.423-0.227l0.226-0.228l-0.226,0.452
                        h-0.198v0.197h0.198l0.451,0.425v0.226h0.198l0.451-0.226h0.648l0.424,0.226l0.649,0.425l0.198,0.45l0.648,0.196l0.226,0.423h0.226
                        h0.196l0.451,0.228l0.847,0.874l0.423,0.199h0.226v-0.199l0.226,0.199v0.225l-0.226-0.225v0.225l-0.226,0.227v0.197l-0.198,0.228
                        v0.195h-0.649l1.298,0.228h0.847l0.874,0.423h0.649l1.945,0.226l1.298,0.229l1.523,0.422l0.846,0.424l0.649,0.423l1.1,0.649
                        l0.424,0.451v0.195l0.225,0.452l0.425,0.422l0.846,0.425l0.226,0.227l0.648,0.198h0.424l0.451,0.227l0.198,0.422l0.226,0.229v0.423
                        l0.197,0.423l0.452,0.227h0.648l1.073-0.424h1.1v-0.226l0.649,0.226h0.191v-0.226h3.046l0.846-0.423h0.226l0.875-0.229l1.269-0.875
                        v-0.196l0.875-0.875l0.423-0.195l0.649-0.452h0.226l1.72-1.298l1.749-1.071l0.649-0.227v0.195l0.113-0.11l0.847-0.424l0.451-0.227
                        l1.72-0.648l2.595-0.873l2.594-0.62h0.451l0.846-0.229h0.649l0.874,0.425h1.299v0.424l0.423-0.197h0.226l0.422-0.227h0.65
                        l0.423-0.425v-0.225l-0.423-0.197l-0.423-0.452v-0.197h0.423l-0.227,0.197l0.451,0.452h0.423v0.197l0.226,0.225l0.422-0.422
                        l0.423-0.452h-0.227v-0.197l0.227,0.197h0.422v-0.197l0.229-0.228l0.875-0.647h0.847v-0.197h-0.65v-0.227h0.229v-0.226h-0.229
                        V282.5h0.229l0.196,0.422h0.451l-0.225,0.425h0.647l-0.226-0.197l0.226-0.45l0.226-0.198h0.199l0.225,0.198v-0.198l-0.225-0.45
                        l0.646-0.648l0.452-0.197h-0.225v-0.226h0.422v0.226l0.226-0.226l0.423,0.226v0.423h0.199v-0.423h0.226v-0.424l-0.226-0.227h0.226
                        v-0.225l-0.226-0.197v-0.228h0.226l-0.226-0.226h0.226v0.226l0.227,0.228l-0.227,0.197l0.227,0.225v-0.225h0.197v-0.425
                        l-0.197-0.226v-0.199l0.197-0.648l0.646-1.101l0.228-0.425h0.424v-0.197l-0.198-0.451v-0.197l0.198-0.647l0.225-0.451v-0.198
                        l0.199-0.422l0.225-0.65v-0.227h0.227v-0.422l0.424-0.873l0.225-0.648v-0.228l0.198,0.228l0.228-1.3v-0.874l0.226-0.847h0.198
                        l-0.198-0.228l0.198-0.196v0.196l0.225-1.07l0.198-0.228v-0.196l0.226-0.451h-0.338l0.112,0.227l-0.198-0.227h0.086l-0.086-0.198
                        h-0.225v-0.226l0.225,0.226h0.198l0.226-0.226h0.226l0.198-0.227v-0.425l0.225-0.423l0.227-0.874l-0.227-0.198h0.227v-0.226
                        l0.197-0.229v-0.196l0.424-0.647l0.648-1.298h0.227l0.423-0.848v-0.026v-0.029h0.057l0.085-0.084l-0.225-0.647l0.648-1.523
                        l0.423-1.072l0.423-0.873l0.228-0.226v-0.621l0.423-1.522v-0.874l0.452-0.874l0.196-0.196v-0.425l-0.196-0.227l-0.228,0.227v-0.227
                        H119.9v-0.226h-0.196v-0.197h0.422l0.228-0.228v0.425l0.196,0.226v-0.874h0.228l0.197-0.196v-0.874l0.226-0.425l0.226-0.875
                        l0.424-0.846l0.197-0.648v-0.215h-0.197l-0.649,0.228l0.847-0.452v-0.423l0.226-0.197v-0.452l0.227-1.07l-0.227-0.227h0.227v-0.649
                        l0.227-0.195v-1.299h0.197l0.226-0.648l0.197-0.874l0.227-0.424l0.226-0.226l0.198-0.649v-0.648l0.228-0.873l0.197-0.648
                        l0.226-0.848l-0.226-0.647l0.452-1.101l0.196-2.145l0.227-1.299l0.423-1.945h-0.198l0.424-1.298l0.226-0.451v-0.197h0.198
                        l0.226-0.873l0.197-1.3l0.452-0.646l0.424-1.299v-0.112h0.084v-2.877h0.902v-1.325h0.733l0.085-0.112h0.198l0.451-0.848
                        l0.198-0.227v-0.226l0.226-0.424v-0.422l0.649-1.723l-0.226-0.227h-0.424l-0.225-0.227l-0.649-0.423v-0.227l0.452,0.451
                        l0.422,0.196h0.65v0.227l0.649-0.874v-0.846l0.423-0.452l0.226-1.071v-0.226v-0.196h0.197l0.423-1.945l0.227-0.425l0.422-0.647
                        l0.452-1.298v-1.945l0.198-0.227h-0.198l-0.227-0.227h0.425v-0.64h-0.425l-0.227-0.197h0.227l0.425-0.226l0.225,0.226v-0.226
                        l1.072-2.172l0.227-0.847l0.423-0.648l0.226-0.874v-0.648l-0.226,0.424v-0.848h0.226v-0.873l0.423-1.102v-0.198l0.425-1.298v-0.871
                        l0.227-0.647l0.423-1.523l0.424-1.72l0.226-0.227v-0.425l-0.226,0.228l-0.424,0.424v-0.424h0.424v-0.228l0.226-0.196v0.196h0.226
                        v-0.196l0.424-0.451l0.198-0.848l0.228-0.648v-0.874l-0.228-0.197l0.228-0.451l0.225-0.849v-0.227l0.425-0.423v-0.227l0.423-1.071
                        h-0.197v0.227h-0.228v-0.227l0.423-0.875h0.227l-0.227,0.648l0.424-0.848l0.648-0.647l0.647-2.171l0.452-0.648v-0.227l-0.226-0.197
                        h-0.029v-0.028h0.029l0.084-0.085l-0.648-0.451h-0.227v-0.197h0.227l0.648,0.423h0.197v0.228h0.206v-0.228l0.423-0.647l0.225-0.424
                        v-0.647h-0.225v-0.227l-0.198-0.197v-1.299l0.648-2.17v-1.095l0.198-0.424l0.452-0.648v-0.423h0.197l0.225-0.875l0.425-0.647
                        l0.226-0.648l0.424-1.072v-0.226h-0.197l0.197-0.424v0.424l0.423-0.846v-0.452l0.227-0.423v-0.198h0.198v-1.101l0.226-1.298h-0.226
                        l0.226-0.198v-0.447h0.226l-0.226,0.451h0.226l0.198-0.649l0.45-1.1v-0.198h-0.225l0.225-0.226v0.226l0.198-0.451l0.424-1.72
                        l0.226-0.228v-0.197l-1.071-1.102l0.846,0.649l0.226,0.451l0.874-1.749v-0.648l0.848-1.721l0.648-2.396l0.254-0.479h-0.424
                        l0.227-0.424v-0.649l0.423,0.198v0.227l-0.143,0.479l0.762-1.494l0.423-1.298h-0.227l0.227-0.226l0.647-1.299l0.198-1.522
                        l0.227-0.649l0.227-0.423v-1.072l0.196-0.648l0.226-0.451l0.227-0.197h0.648v-0.227h-0.229v-0.424l-0.226-0.226l-0.198-0.424
                        v-0.423l0.198-0.424l0.226-0.45v-0.762h0.058v-0.198l0.226-0.648v-0.198l0.197-0.648l0.649-1.522l0.451-1.298v-0.649l0.423-1.1
                        v-0.424h-0.226l-0.197-0.423l-0.226-0.228l-0.424,0.228l0.198-0.228h0.226v-0.846l-0.226-0.226v-0.424l0.226-0.874l-0.424,0.226
                        v-1.101h0.198v-0.197h-0.424v-0.424h-0.197l0.197-0.875h-0.197l-0.227-0.423h-0.226v-0.226h-0.199v-0.423l0.199-1.102l0.451-1.071
                        v-0.226l0.197-0.198l0.227-0.649l-0.227,0.198h-0.197l0.197-0.198h0.227l0.424-0.648l0.226-0.423v-0.648l0.197-0.226l0.226-0.227
                        v-0.415l0.198-0.423v-0.424l0.226-0.226v-0.226l0.225-0.198v-0.226l0.198-0.226l0.227-0.424l0.226-0.197v-0.648l0.197-0.226
                        l1.299-0.423v-0.226h0.647l1.75-0.226l0.648-0.198l0.197-0.226h-0.648l-0.198-0.197l-0.451-0.227l-0.424-0.423l-0.197-0.452
                        l-0.227-0.197l0.227-0.226l-0.244-0.201v-0.648l0.423-0.451l0.225,0.226h0.199v-0.226l0.228-0.197v-0.226l0.226-0.226v-0.197
                        l0.198-0.227v-0.193v-0.226l0.647-0.649l0.227-0.423v-0.227l-0.227-0.226l0.227-0.197v-0.874h0.225v-0.198l0.198-0.225v-0.226
                        l-0.198-0.198l0.198-0.226h-0.198l-0.225,0.226l-0.227-0.451v-0.648l-0.196-0.423v-0.198l-0.228-0.226v-0.226l0.228-0.198v-0.226
                        l0.196-0.226h-0.196l0.423-0.423v-0.649h0.225v-0.649l-0.225-0.198l-0.227-0.451h-0.196l-0.228-0.198h-0.422v-0.226h-0.651h-0.225
                        v-0.226h-0.198l-0.451-0.424h-0.196l0.196-0.197l-0.196-0.226V96.99l-0.452-0.424v-0.649l0.226-0.451v-0.17v-0.649l0.677-1.072
                        l0.198-0.226l-0.423-0.198v-0.458l-0.226-0.423v-0.424l-0.198-0.226v-0.424l-0.228-0.423l-0.225-0.226l-0.198-0.875l-0.227-0.423
                        V89.03l0.227-0.423l-0.227-0.423l0.227-0.423l-0.227-0.226v-0.226h-0.197l-0.226-0.198l-0.227-0.423v-0.226l0.227-0.423
                        l0.226-0.226l0.424-0.648l1.072-1.073h0.874v0.198l0.198-0.198l0.874-0.226h0.875l0.197-0.198l0.226,0.198l0.423,0.226h0.218
                        l-0.225,0.423v0.423l0.225,0.227v0.198l-0.225,0.451v0.847l0.422,0.226l0.227,0.423l0.424,0.226l0.226,0.197v0.226l-0.226,0.875
                        l0.226,0.649v0.423l0.226,0.423l0.396,0.396l0.028,0.057v0.085l0.026-0.113h0.198l0.226,0.197v0.225l-0.226,0.423l0.226,0.649
                        v0.451l0.848,0.847l0.648,0.226l0.874,0.423l0.226,0.225v0.649l0.197,0.197l0.227-0.197h0.848l0.226-0.226v-0.226l-0.226-0.197
                        l0.676-0.648l-0.226-0.226l0.423-0.422l0.424-0.226l0.227-0.198v-0.45l0.225-0.424h0.198l0.423-0.226v-0.197h-0.423l0.226-0.649
                        l0.422-1.1l0.427-1.297l0.647-1.298l0.451-0.649v-1.269l0.197-0.451l0.424-0.424l-0.197-0.423l0.197-0.226v-1.072h0.226
                        l0.227-0.451v-0.198l-0.227-0.649v-0.424l-0.226-0.649v-0.226l-0.197-0.226h-0.227v-0.198h-0.197v-0.226h-0.229l0.225-0.226v-0.196
                        l-0.649-0.875l-0.875-0.846v-0.451h-0.225v-0.198h0.225l-0.225-0.452l0.225-0.198l-0.225-0.226v-0.424h-0.198V74.98l-0.227-0.198
                        v-0.451l-0.195-0.196h-0.226l-0.228-0.226l0.228-0.423h-0.228l-0.422-0.648v-0.226v-0.649l-0.228-0.198v-0.451h-0.197v-0.423
                        l-0.451-0.423v-1.72l-0.198-0.226v-0.649h-0.227v-0.424l-0.196-0.451v-1.946h-0.226v-1.72l0.422-1.298v-0.875l-0.195-0.421h-0.226
                        l-0.649-0.649v-1.071l0.228-0.874l0.195-1.719v-0.226h-0.195l-0.228-0.198v-0.226h-0.226v-0.226v-0.198h-0.028v-0.084h0.058V55.21
                        v-0.197v-1.1l0.197-0.649v-0.198h-0.197l0.197-0.874v-1.07l-0.197-0.226l-0.226-0.649l-0.227-0.226v-0.424l-0.197-0.226
                        l-0.226-0.423h-0.197v-0.425l0.197,0.424v-1.072l-0.197-0.424h-0.228l-0.226-0.226V46.35l-0.197-0.423l-0.424-0.226v-0.423V45.08
                        v-0.677h-0.226v-0.198v-0.451l0.226-0.649v-0.847h-0.45v0.169v0.226h-0.227l-0.227-0.226v-0.649h-0.423l0.423-0.198h-0.196v-0.226
                        h0.196v-0.226l-0.423-0.424v-0.42l-0.226-0.225v-0.226l-0.197-0.198v-0.226l-0.226-0.198l-0.197,0.423l-0.227,0.226l0.424-0.649
                        v-0.226l0.225-0.874l-0.225-0.424l0.225-0.648l-0.225-0.198v-0.226l0.225-0.423l-0.225-0.226h-0.198V35.74h-0.227l-0.424-0.847
                        l0.198-0.226l-0.424-0.198h-0.423v-0.452l-0.228-0.198l0.228-0.225h0.195v-0.226l-0.195-0.198h-0.874l-0.198-0.423h0.198
                        l-0.426-0.451l0.228-0.198v-0.226h-0.228l0.228-0.423l-0.649-0.226v-0.195h-0.226l0.226-0.226h-0.451v0.226l-0.195-0.226v-0.424
                        h-0.226v-0.225h-0.225l-0.198,0.423v0.423l-0.227,0.226h-0.197l0.197,0.226v0.198l-0.197-0.198h-0.226l-0.227,0.197l-0.196-0.198
                        l0.647-0.452V30.8l-0.226-0.424l-0.227-0.226v-0.649l-0.846-0.874h-0.227l-0.197-0.198v0.198l-0.227,0.226l-0.227-0.226
                        l0.227-0.198h-0.227l-0.197,0.198V27.78l0.197-0.226v-0.198l0.227-0.226l-0.227-0.226v-0.423l-0.423-0.226h0.226v-1.072h0.197
                        v0.423l0.227,0.226h-0.227l0.227,0.198h-0.227v0.226h0.649V26.03l-0.197-0.198h0.197l-0.197-0.648v-0.226h-0.227v-0.649
                        l-0.227-0.423l-0.197,0.226v0.198h0.197v0.451l-0.197-0.226v-0.226h-0.226l-0.197,0.226l0.197,0.226h-0.423V24.31l0.197-0.198
                        h-0.197v-0.226h-0.228l-0.225,0.226h-0.197v-0.226h0.197l0.225-0.423h0.228v0.198l0.197,0.226v0.226l0.227-0.226v-0.226h-0.227
                        l0.227-0.424l-0.227-0.226v-0.198h-0.197v-0.226h-0.648l-0.423,0.226v0.198h-0.226l-0.226-0.198h0.226v-0.226l-0.422-0.423v0.226
                        l-0.227-0.226h0.227v-0.226h-0.227v-0.423l-0.227-0.226V20.87h-0.196l0.196-0.198h-0.196v-0.226h-0.227l-0.425-0.424l-0.226,0.198
                        l-0.197,0.451h0.197v0.198l0.226,0.226v0.198l-0.226,0.451h-0.649l-0.196-0.226v-0.203l0.196-0.198v-0.226h-0.647l0.226,0.226
                        h-0.226l-0.197,0.423l-0.227-0.226v0.226h-0.227h-0.197l-0.225,0.423l-0.196-0.198v-0.226h-0.452l0.228-0.226v-0.198h0.226
                        l-0.226-0.226h0.226l-0.226-0.198l0.226-0.226h0.197l0.227,0.226v0.423l0.197,0.198h0.225v-0.198h0.227l0.198-0.423v-0.452h-0.425
                        l-0.225-0.198l-0.197,0.198h-0.452l0.226-0.198v-0.226l0.423-0.226v-0.194h-0.423v-0.226l0.227-0.423l0.197-0.226h0.225v0.226
                        l0.227,0.226l-0.227,0.198h0.227l0.198,0.226V19.17l0.226,0.226h0.197l0.226-0.226h-0.226v-0.198h0.226v-0.226l0.227,0.226h0.195
                        v0.198l0.649,0.648v-0.424h-0.197V18.52h-0.226v-0.847l0.226,0.198v-0.198l-0.226-0.225v-0.213l-0.228-0.198l0.228-0.226h-0.648
                        v-0.195h0.227v-0.451l-0.227,0.226v-0.649l-0.226-0.226h-0.085v-0.141h0.028v-0.423l-0.226-0.225h-0.226v0.225h-0.197l0.197-0.225
                        v-0.198H151.975L151.975,14.529z M153.101,19.408l-0.198,0.198v0.226h0.198V19.408z M164.102,34.497l0.225-0.226l-0.225-0.226
                        V34.497z M161.9,109.487h-0.225v0.226L161.9,109.487z M151.806,148.633l-0.085,0.169h0.026L151.806,148.633z M56.621,287.361
                        l0.198-0.197v-0.226l-0.198,0.226V287.361z M56.818,286.939v-0.423l-0.198-0.425h-0.226L56.818,286.939z M56.395,286.094
                        l-0.198-0.45h-0.028L56.395,286.094z M55.548,284.993v0.226h0.198L55.548,284.993z M51.008,282.003l-0.198-0.225h-0.225
                        L51.008,282.003z M39.388,206.136l0.226-0.225l-0.226-0.424V206.136z M49.514,192.545l0.198,0.226v-0.226H49.514z M58.566,176.61
                        l0.198-0.227v-0.197L58.566,176.61z M54.25,155.458l0.198-0.226l-0.198-0.197V155.458z M50.218,138.396l-0.057-0.227v0.227H50.218z
                        M53.49,116.652l0.112-0.113l0.113-0.311L53.49,116.652z M53.716,116.229l0.113,0.112l0.113-0.112H53.716z M75.517,97.445
                        l0.198-0.198h-0.198V97.445z M76.785,95.95h-0.197v0.226L76.785,95.95z M97.542,93.638l0.422,0.226l0.423,0.197h0.227l-1.072-0.847
                        h-0.226l0.226,0.198V93.638L97.542,93.638z M98.191,85.43l0.422-0.198h-0.225L98.191,85.43z M128.341,46.511l-0.227-0.198v0.198
                        H128.341z M134.149,48.034l0.226-0.198V47.61h-0.226V48.034z M142.556,24.57v0.226h0.225L142.556,24.57z M150.733,16.39h-0.197
                        v0.226L150.733,16.39z M151.382,15.966V15.74l-0.197-0.225l-0.227,0.226l0.227,0.226v-0.226L151.382,15.966z M125.745,56.974
                        l-0.197,0.226h0.197V56.974z M127.691,60.894l0.197,0.17v0.423l-0.028-0.028v-0.31l-0.169,0.141V60.894L127.691,60.894z
                        M112.632,76.349v0.226h-0.227L112.632,76.349z M102.054,83.062l0.226,0.226v0.226l-0.226,0.198l0.226,0.226h0.198l-0.198,0.198
                        v-0.198h-0.226l-0.225-0.226v-0.198h0.225v-0.226h-0.225l-0.395,0.198l0.198-0.198h0.197L102.054,83.062z M85.669,97.473h0.451
                        l0.423,0.423v0.226H86.12v0.226h0.197v0.198l0.226,0.451l-0.226,0.198v-0.198H86.12v-0.226l-0.226-0.226v0.226l-0.648,0.226v-0.451
                        H85.02l-0.198,0.226L84.4,98.546v-0.198h-0.452l0.226-0.226h0.649l0.198-0.226h0.452l0.197-0.197L85.669,97.473L85.669,97.473z
                        M58.03,106.949h0.198l0.226,0.423h-0.226v-0.198L58.03,106.949z M138.944,121.981h0.226v0.197l0.423,0.226v0.649l0.227,0.424
                        v0.226l-0.227,0.424l-0.227,0.227v0.423l-0.198,0.424h-0.226l-0.649,0.226v0.226l0.228,0.198v0.423h-0.423l-0.227,0.225v1.071
                        l-0.424,0.875l-0.225,0.226v-0.226l-0.227-0.423v-0.874l-0.197-1.073l0.847-0.648l0.227-0.423l0.422-0.226v-0.424h0.228v-0.227
                        l-0.423-0.424v-0.423l0.196-0.227h0.228l0.197,0.227h0.226v-0.227l-0.226-0.648L138.944,121.981z M104.086,157.037h0.227
                        l0.648,0.648h0.197l0.228-0.198h0.227l-0.452,0.425v0.227h-0.197l-0.225,0.196l-0.424,0.229h-0.227l-0.226-0.229l0.451-0.422
                        v-0.452l-0.227-0.197V157.037L104.086,157.037z M146.954,162.282v0.197h-0.226L146.954,162.282z M58.764,167.754h0.226
                        l-0.226,0.451V167.754z M57.101,179.543h0.226l-0.226,0.197V179.543z M48.865,192.996v0.423h-0.226v-0.226L48.865,192.996z
                        M41.984,205.715l0.197,0.424v0.196l0.226,0.229h-0.226v-0.229l-0.197-0.423V205.715z M78.083,206.871l0.226,0.198v0.874h-0.423
                        l-0.226-0.452l0.226-0.423L78.083,206.871z M43.45,215.727v0.226l0.198,0.226h0.226l0.847,0.425l0.226,0.226l-0.226,0.648
                        l-0.198,0.197l-0.451,0.227l-0.846,0.197H43l-0.198-0.197L43,217.675l-0.198-0.197H42.35l-0.198-0.648v-0.226h0.198v0.424
                        l0.226,0.225h0.423v-0.225v-0.424v-0.228l0.226-0.423L43.45,215.727z M45.395,222.241h0.198v0.198l0.226-0.198l0.226,0.198v0.45
                        l-0.226,0.196h-0.226l-0.649-0.423h0.226h0.226L45.395,222.241L45.395,222.241z M44.748,260.795h0.226v0.451l0.424,0.424
                        l0.198,1.299l0.226,0.874v0.848l-0.226-0.424l-0.198-0.227v-0.423v-0.226l-0.225-0.227v-0.425l-0.198-0.225v-0.426l-0.452-0.422
                        v-0.227l0.226-0.198L44.748,260.795L44.748,260.795z M96.724,284.853l0.424,0.196v0.227L96.724,284.853z M57.466,285.642h0.226
                        v0.226L57.466,285.642z"
          />
        </g>
       
    );
};

export default Madagascar;
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../Utils";
import { cityDetails } from "../../actions/citydetails.action";

const Cities = () => {
  const mytour = useSelector((state) => state.oneTourReducer);
  const itineraries = useSelector((state) => state.itineraryReducer);
  const cities = useSelector((state) => state.cityReducer);
  const [citylist, setCitylist] = useState([]);
  const dispatch = useDispatch();
  const [currentdetails, setCurrentdetails] = useState("");


  // liste des villes ----------------------------------------------------------
  useEffect(() => {
    const newCityList = Array.from({ length: 20 }, (_, i) => i + 1).map((i) => {
      const dbdaykey = `jour${i}`;
      const itineraryid = mytour[dbdaykey];
      const myitinerary =
        !isEmpty(itineraries) &&
        itineraries.find((itinerary) => itinerary.id === itineraryid);

      return (
        mytour[dbdaykey] &&
        mytour[dbdaykey] !== "0" &&
        !isEmpty(myitinerary) &&
        !isEmpty(cities) &&
        cities.find((city) => city.id == myitinerary.city)
      );
    });

    // Mettre à jour la liste des villes après avoir effectué toutes les itérations.
    setCitylist(newCityList);
    // !isEmpty(citylist) && console.log(citylist);
  }, [mytour, itineraries, cities]);


  // détails des villes ----------------------------------------------------------
  const showcityinfo = (e, id) => {
    const citydetails =
      !isEmpty(citylist) && citylist.find((city) => city.id == id);
    if (citydetails) {
      const data = {
        position: e.pageY,
        image: citydetails.image,
        title: citydetails.title,
        description: citydetails.description
      }
      dispatch(cityDetails(data));
      // setCurrentdetails(
      //   <div
      //     style={{ top: e.pageY }}
      //     className={`tourcontainer`}
      //     onMouseEnter={() => showcityinfo(e, id)}
      //     onMouseLeave={() => {
      //       setCurrentdetails("");
      //     }}
      //   >
      //     <div className="thumnimg">
      //       <div>
      //         <img
      //           className="imgthumb"
      //           src={`https://tours.viajes-madagascar.mg/uploads/${citydetails.image}`}
      //         />
      //       </div>
      //       <h4>{citydetails.title}</h4>
      //     </div>
      //     <div className="tourinfos">
      //       <p>{citydetails.description}</p>
      //     </div>
      //   </div>
      // );
    }
  };

  
  // montrer ou non les villes ----------------------------------------------------------
  const hideorshow = (id) => {
    const city = citylist.find((city) => city && city.mapid === id);

    if (city) {
      return "showme";
    } else {
      return "hideme";
    }
  };

  return (
    <g>
      {/* **********************************************************  
                                { Sainte Marie }
          ************************************************************ */}
      <g
        id="saintemarie"
        className={hideorshow("saintemarie")}
        onMouseEnter={(e) => showcityinfo(e, 13)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 335.5659 225.0542)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Sainte Marie
        </text>
        <path
          d="M334.196,203.128c-3.351,0-6.064,2.664-6.064,5.945c0,3.284,6.064,14.055,6.064,14.055s6.062-10.772,6.062-14.055
                        C340.257,205.792,337.542,203.128,334.196,203.128z"
        />
        <g id="Beach_Ste_Marie" className="beach">
          <g
            id="Beach_Ifaty_2_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M127.297,86.672c-0.285-0.166-0.572-0.246-0.859-0.242
                c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
                c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
                c0.002,0.001,0.004,0.002,0.006,0.002h0.001c0.001,0,0.001,0.001,0.002,0.001h0.001c0.006,0.002,0.013,0.002,0.019,0.002
                c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001h0.001c0.003,0,0.005-0.001,0.008-0.001
                c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004c0.002-0.001,0.003-0.001,0.005-0.001
                c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019c0.002,0.001,0.003,0.002,0.005,0.003
                c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001h0.001c0.007,0.002,0.015,0.003,0.022,0.003
                c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005c0.005-0.002,0.009-0.003,0.013-0.006
                c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023c0.001-0.001,0.001-0.003,0.002-0.004
                c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7c-0.379-0.524-0.964-0.914-1.648-1.097
                c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288l0,0c-0.001,0.002-0.001,0.003-0.002,0.005
                c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019c0.001,0.007,0.003,0.012,0.005,0.019
                c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002c0.003,0.006,0.008,0.011,0.012,0.016
                c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007c0.006,0.003,0.012,0.007,0.018,0.009
                c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001c0.007,0.001,0.013,0.002,0.02,0.001
                s0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003c0.638-0.319,1.14-0.184,1.533,0.411
                c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014
                c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002c0.023,0.01,0.05,0.01,0.074-0.002
                c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027c-1.34,0-2.567,0.42-3.146,1.072
                c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123c0.025,0.043,0.08,0.057,0.123,0.032
                c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243c0.28,0,0.56-0.081,0.837-0.243
                c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
                c0.043,0.025,0.098,0.011,0.123-0.032C127.355,86.752,127.34,86.697,127.297,86.672z M124.03,82.789
                c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C125.128,82.675,124.626,82.546,124.03,82.789z
                 M126.798,82.249c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
                C126.116,81.555,126.518,81.862,126.798,82.249z M122.396,82.327c0.171-0.428,0.52-0.775,0.999-0.99
                c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C123.469,82.239,122.978,82.103,122.396,82.327z
                 M124.728,82.862c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L124.728,82.862z
                 M125.623,86.672c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
                c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
                C126.015,86.485,125.818,86.558,125.623,86.672z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M120.209,82.357c-0.017,0-0.034-0.002-0.05-0.007
                c-0.055-0.016-0.1-0.058-0.118-0.113l-0.084-0.246l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
                c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133
                c-0.008-0.057,0.012-0.115,0.054-0.154l0.189-0.179l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163
                c0.024-0.052,0.072-0.09,0.129-0.101l0.256-0.048l0.01-0.258c0.003-0.058,0.033-0.111,0.082-0.142
                c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212c0.033-0.047,0.088-0.075,0.145-0.075
                c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099c0.054-0.022,0.115-0.016,0.163,0.015
                c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046c0.057,0.011,0.105,0.049,0.129,0.101
                c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177c0.042,0.04,0.062,0.097,0.054,0.155
                c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251c0.014,0.056,0,0.116-0.038,0.16
                c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245c-0.019,0.055-0.063,0.097-0.118,0.113
                c-0.055,0.016-0.116,0.005-0.161-0.032l-0.204-0.162l-0.202,0.162C120.288,82.343,120.249,82.357,120.209,82.357z M119.959,81.811
                c0.076,0,0.143,0.048,0.168,0.121l0.084,0.246l0.201-0.163c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162l0.081-0.245
                c0.027-0.079,0.103-0.128,0.186-0.12l0.259,0.026l-0.065-0.251c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118l-0.19-0.176
                c-0.06-0.057-0.074-0.147-0.031-0.219l0.131-0.225l-0.255-0.045c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26l-0.239,0.1
                c-0.077,0.031-0.164,0.006-0.212-0.063l-0.149-0.211l-0.148,0.211c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098
                l-0.009,0.259c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179
                l0.232,0.115c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028C119.947,81.812,119.953,81.811,119.959,81.811z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M120.523,81.719c-0.347,0-0.63-0.283-0.63-0.63c0-0.347,0.283-0.63,0.63-0.63
                c0.347,0,0.63,0.283,0.63,0.63C121.153,81.436,120.87,81.719,120.523,81.719z M120.523,80.639c-0.248,0-0.45,0.202-0.45,0.45
                c0,0.248,0.202,0.45,0.45,0.45c0.248,0,0.45-0.202,0.45-0.45C120.973,80.841,120.771,80.639,120.523,80.639z"
            />
          </g>
        </g>
      </g>

      {/* <g id="saintemarie"
          className={hideorshow("saintemarie")}
          onMouseEnter={(e) => showcityinfo(e, 0)}
          onMouseLeave={() => setCurrentdetails("")}
        >
          <text
            transform="matrix(1 0 0 1 334.2673 224.0908)"
            fontFamily="'ArialMT'"
            fontSize="12"
          >
            {" "}
            Sainte Marie{" "}
          </text>
          <path
            d="M332.897,202.165c-3.351,0-6.063,2.664-6.063,5.945c0,3.284,6.063,14.055,6.063,14.055s6.063-10.772,6.063-14.055
            C338.959,204.829,336.243,202.165,332.897,202.165z"
          />
        </g> */}
      



      {/* **********************************************************  
                                { Nosy Be }
          ************************************************************ */}
      <g
        id="nosybe"
        className={hideorshow("nosybe")}
        onMouseEnter={(e) => showcityinfo(e, 11)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 215.9077 77.3042)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Nosy be
        </text>
        <path
          d="M260.264,52.586c-3.35,0-6.063,2.663-6.063,5.945c0,3.285,6.063,14.055,6.063,14.055s6.063-10.771,6.063-14.055
                        C266.326,55.25,263.61,52.586,260.264,52.586z"
        />
        <g id="Beach_Nosy_BE" className="beach">
          <g
            id="Beach_Ifaty_5_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M90.914,26.004c-0.285-0.166-0.572-0.246-0.859-0.242
                c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
                c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
                c0.002,0.001,0.004,0.002,0.006,0.002h0.001c0.001,0,0.001,0.001,0.002,0.001h0.001c0.006,0.002,0.013,0.002,0.019,0.002
                c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001h0.001c0.003,0,0.005-0.001,0.008-0.001
                c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004c0.002-0.001,0.003-0.001,0.005-0.001
                c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019c0.002,0.001,0.003,0.002,0.005,0.003
                c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001h0.001c0.007,0.002,0.015,0.003,0.022,0.003
                c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005c0.005-0.002,0.009-0.003,0.013-0.006
                c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023c0.001-0.001,0.001-0.003,0.002-0.004
                c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7c-0.379-0.524-0.964-0.914-1.648-1.097
                c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288l0,0c-0.001,0.002-0.001,0.003-0.002,0.005
                c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019c0.001,0.007,0.003,0.012,0.005,0.019
                c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002c0.003,0.006,0.008,0.011,0.012,0.016
                c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007c0.006,0.003,0.012,0.007,0.018,0.009
                c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001c0.007,0.001,0.013,0.002,0.02,0.001
                c0.007-0.001,0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003c0.638-0.319,1.14-0.184,1.533,0.411
                c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014
                c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002c0.023,0.01,0.05,0.01,0.074-0.002
                c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027c-1.34,0-2.567,0.42-3.146,1.072
                c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123c0.025,0.043,0.08,0.057,0.123,0.032
                c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243c0.28,0,0.56-0.081,0.837-0.243
                c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
                c0.043,0.025,0.098,0.011,0.123-0.032C90.972,26.083,90.957,26.029,90.914,26.004z M87.647,22.121
                c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C88.745,22.007,88.243,21.878,87.647,22.121z
                 M90.415,21.581c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
                C89.733,20.886,90.135,21.194,90.415,21.581z M86.013,21.659c0.171-0.428,0.52-0.775,0.999-0.99
                c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C87.086,21.57,86.595,21.435,86.013,21.659z
                 M88.345,22.194c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L88.345,22.194z
                 M89.24,26.004c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
                c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
                C89.632,25.817,89.435,25.89,89.24,26.004z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M83.826,21.689c-0.017,0-0.034-0.002-0.05-0.007
                c-0.055-0.016-0.1-0.058-0.118-0.113l-0.084-0.246l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
                c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133
                c-0.008-0.057,0.012-0.115,0.054-0.154l0.189-0.179l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163s0.072-0.09,0.129-0.101
                l0.256-0.048l0.01-0.258c0.003-0.058,0.033-0.111,0.082-0.142c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212
                c0.033-0.047,0.088-0.075,0.145-0.075c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099
                c0.054-0.022,0.115-0.016,0.163,0.015c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046
                c0.057,0.011,0.105,0.049,0.129,0.101c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177
                c0.042,0.04,0.062,0.097,0.054,0.155c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251
                c0.014,0.056,0,0.116-0.038,0.16c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245
                c-0.019,0.055-0.063,0.097-0.118,0.113c-0.055,0.016-0.116,0.005-0.161-0.032l-0.204-0.162l-0.202,0.162
                C83.905,21.675,83.866,21.689,83.826,21.689z M83.576,21.143c0.076,0,0.143,0.048,0.168,0.121l0.084,0.246l0.201-0.163
                c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162l0.081-0.245c0.027-0.079,0.103-0.128,0.186-0.12l0.259,0.026l-0.065-0.251
                c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118l-0.19-0.176c-0.06-0.057-0.074-0.147-0.031-0.219l0.131-0.225l-0.255-0.045
                c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26l-0.239,0.1c-0.077,0.031-0.164,0.006-0.212-0.063l-0.149-0.211l-0.148,0.211
                c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098l-0.009,0.259c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222
                c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179l0.232,0.115c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028
                C83.564,21.144,83.57,21.143,83.576,21.143z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M84.14,21.051c-0.347,0-0.63-0.283-0.63-0.63c0-0.347,0.283-0.63,0.63-0.63
                c0.347,0,0.63,0.283,0.63,0.63C84.77,20.768,84.487,21.051,84.14,21.051z M84.14,19.971c-0.248,0-0.45,0.202-0.45,0.45
                c0,0.248,0.202,0.45,0.45,0.45c0.248,0,0.45-0.202,0.45-0.45C84.59,20.173,84.388,19.971,84.14,19.971z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                      { Nosy Komba }
              ************************************************************ */}
      <g className={hideorshow("nosykomba")}>
        <g id="Parc_Nosy_Komba">
          <g
            id="parc_Ranomafana_4_"
            className="park"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.168,31.961c-0.896,0-1.625-0.729-1.625-1.625
                  c0-0.773,0.638-3.006,1.625-3.006c0.986,0,1.624,2.233,1.624,3.006C87.792,31.231,87.064,31.961,86.168,31.961z M86.168,27.633
                  c-0.659,0-1.322,1.853-1.322,2.703c0,0.728,0.593,1.322,1.322,1.322c0.728,0,1.321-0.593,1.321-1.322
                  C87.489,29.486,86.825,27.633,86.168,27.633z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.17,33.064c-0.083,0-0.151-0.068-0.151-0.152v-3.271
                  c0-0.084,0.067-0.152,0.151-0.152c0.084,0,0.152,0.068,0.152,0.152v3.271C86.323,32.996,86.255,33.064,86.17,33.064z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.17,30.445c-0.041,0-0.081-0.016-0.111-0.049
                  c-0.057-0.062-0.053-0.157,0.008-0.214l0.443-0.41c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.061,0.053,0.157-0.008,0.214
                  l-0.443,0.41C86.244,30.432,86.208,30.445,86.17,30.445z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.17,31.075c-0.037,0-0.074-0.014-0.103-0.041l-0.619-0.572
                  c-0.061-0.057-0.065-0.153-0.008-0.214c0.056-0.062,0.153-0.065,0.213-0.008l0.619,0.573c0.062,0.057,0.065,0.153,0.008,0.214
                  C86.253,31.058,86.211,31.075,86.17,31.075z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.288,32.225c-0.527,0-1.006-0.332-1.191-0.825
                  c-0.031-0.078,0.01-0.166,0.088-0.195c0.078-0.03,0.166,0.01,0.195,0.089c0.141,0.376,0.506,0.628,0.908,0.628
                  c0.535,0,0.969-0.434,0.969-0.969c0-0.535-0.434-0.969-0.969-0.969c-0.21,0-0.409,0.066-0.577,0.19
                  c-0.067,0.05-0.163,0.035-0.212-0.031c-0.05-0.068-0.036-0.163,0.031-0.213c0.22-0.164,0.483-0.25,0.758-0.25
                  c0.702,0,1.272,0.571,1.272,1.272C89.562,31.654,88.99,32.225,88.288,32.225z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.29,33.064c-0.084,0-0.152-0.068-0.152-0.152v-2.208
                  c0-0.084,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.208C88.443,32.996,88.375,33.064,88.29,33.064z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.29,31.351c-0.041,0-0.082-0.016-0.111-0.049
                  c-0.057-0.062-0.053-0.157,0.008-0.214l0.337-0.312c0.062-0.057,0.156-0.053,0.213,0.009c0.057,0.061,0.054,0.157-0.009,0.214
                  l-0.336,0.312C88.364,31.338,88.327,31.351,88.29,31.351z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.29,31.831c-0.037,0-0.074-0.014-0.103-0.041l-0.471-0.436
                  c-0.062-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.213-0.008l0.471,0.436c0.062,0.057,0.065,0.153,0.008,0.214
                  C88.372,31.815,88.331,31.831,88.29,31.831z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M84.17,33.064c-0.083,0-0.152-0.068-0.152-0.152v-2.145
                  c0-0.083,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.145C84.322,32.996,84.254,33.064,84.17,33.064z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M84.171,31.416c-0.041,0-0.082-0.016-0.112-0.049
                  c-0.056-0.062-0.054-0.157,0.009-0.214l0.339-0.313c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.062,0.053,0.157-0.009,0.214
                  l-0.338,0.313C84.244,31.403,84.208,31.416,84.171,31.416z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M84.17,31.897c-0.037,0-0.074-0.014-0.103-0.041l-0.473-0.436
                  c-0.061-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.473,0.437c0.062,0.057,0.065,0.153,0.008,0.214
                  C84.252,31.881,84.211,31.897,84.17,31.897z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M85.038,32.38h-1.737c-0.312,0-0.565-0.254-0.565-0.565
                  c0-0.179,0.085-0.345,0.224-0.45c-0.007-0.044-0.01-0.089-0.01-0.133c0-0.275,0.133-0.535,0.351-0.697c0-0.002,0-0.003,0-0.005
                  c0-0.48,0.39-0.869,0.869-0.869c0.241,0,0.474,0.101,0.637,0.278c0.057,0.062,0.054,0.157-0.008,0.214s-0.157,0.054-0.215-0.008
                  c-0.108-0.117-0.255-0.181-0.416-0.181c-0.311,0-0.566,0.254-0.566,0.566c0,0.022,0.002,0.044,0.004,0.065
                  c0.007,0.06-0.022,0.119-0.074,0.148c-0.172,0.102-0.281,0.289-0.281,0.489c0,0.054,0.008,0.109,0.024,0.162
                  c0.022,0.073-0.014,0.15-0.083,0.181c-0.094,0.043-0.155,0.136-0.155,0.239c0,0.144,0.117,0.262,0.262,0.262h1.737
                  c0.144,0,0.262-0.118,0.262-0.262c0-0.051-0.015-0.101-0.043-0.144c-0.046-0.07-0.026-0.164,0.043-0.21
                  c0.07-0.046,0.164-0.026,0.21,0.043c0.061,0.092,0.093,0.199,0.093,0.311C85.603,32.126,85.348,32.38,85.038,32.38z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M89.409,33.064h-6.523c-0.084,0-0.152-0.068-0.152-0.152 s0.068-0.152,0.152-0.152h6.523c0.084,0,0.152,0.068,0.152,0.152S89.494,33.064,89.409,33.064z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Tuléar }
          ************************************************************ */}
      <g
        id="tulear"
        className={hideorshow("tulear")}
        onMouseEnter={(e) => showcityinfo(e, 26)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 70.123 504.9019)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Tulear
        </text>

        <path
          fill="#989898"
          d="M89.191,476.414c-3.348,0-6.062,2.664-6.062,5.947s6.062,14.053,6.062,14.053s6.062-10.77,6.062-14.053
                      C95.253,479.08,92.539,476.414,89.191,476.414z"
        />
      </g>

      {/* **********************************************************  
                                { Antsirabe }
          ************************************************************ */}

      <g
        id="antsirabe"
        className={hideorshow("antsirabe")}
        onMouseEnter={(e) => showcityinfo(e, 20)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 216.771 348.9019)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Antsirabe
        </text>

        <path
          d="M213.839,330.634c-3.349,0-6.063,2.665-6.063,5.946c0,3.285,6.063,14.054,6.063,14.054s6.063-10.771,6.063-14.054
                        S217.187,330.634,213.839,330.634z"
        />
      </g>

      {/* **********************************************************  
                                { Antananarivo }
          ************************************************************ */}
      <g
        id="antananarivo"
        className={hideorshow("antananarivo")}
        onMouseEnter={(e) => showcityinfo(e, 6)}
        onMouseLeave={() => setCurrentdetails("")}
        // onMouseEnter={(e) => console.log(e)}
      >
        <text
          transform="matrix(1 0 0 1 195.4077 314.5542)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Antananarivo
        </text>

        <path
          d="M230.006,287.417c-3.35,0-6.062,2.665-6.062,5.946c0,3.286,6.062,14.054,6.062,14.054s6.063-10.77,6.063-14.054
                        C236.069,290.082,233.354,287.417,230.006,287.417z"
        />
      </g>

      {/* **********************************************************  
                                { Ranomafana }
          ************************************************************ */}
      <g
        id="ranomafana"
        className={hideorshow("ranomafana")}
        onMouseEnter={(e) => showcityinfo(e, 22)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 234.5547 403.1226)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Ranomafana
        </text>
        <path
          fill="#989898"
          d="M230.622,383.39c-3.349,0-6.063,2.664-6.063,5.945c0,3.285,6.063,14.055,6.063,14.055
                      s6.063-10.771,6.063-14.055S233.97,383.39,230.622,383.39z"
        />
        <g id="Parc_Ranomafana">
          <g
            id="parc_Ranomafana"
            className="park"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.073,138.769c-0.896,0-1.625-0.729-1.625-1.625
                          c0-0.773,0.638-3.006,1.625-3.006c0.986,0,1.624,2.233,1.624,3.006C89.697,138.039,88.969,138.769,88.073,138.769z
                          M88.073,134.441c-0.659,0-1.322,1.853-1.322,2.703c0,0.728,0.593,1.322,1.322,1.322c0.728,0,1.321-0.593,1.321-1.322
                          C89.394,136.294,88.73,134.441,88.073,134.441z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.075,139.872c-0.083,0-0.151-0.068-0.151-0.152v-3.271
                          c0-0.084,0.068-0.152,0.151-0.152c0.084,0,0.152,0.068,0.152,0.152v3.271C88.228,139.804,88.159,139.872,88.075,139.872z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.075,137.253c-0.041,0-0.081-0.016-0.111-0.049
                          c-0.057-0.062-0.053-0.157,0.008-0.214l0.443-0.41c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.061,0.053,0.157-0.008,0.214
                          l-0.443,0.41C88.149,137.24,88.113,137.253,88.075,137.253z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M88.075,137.883c-0.037,0-0.074-0.014-0.103-0.041l-0.619-0.572
                          c-0.061-0.057-0.065-0.153-0.008-0.214c0.056-0.062,0.153-0.065,0.214-0.008l0.619,0.573c0.062,0.057,0.065,0.153,0.008,0.214
                          C88.157,137.866,88.116,137.883,88.075,137.883z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M90.193,139.033c-0.527,0-1.006-0.332-1.191-0.825
                          c-0.03-0.078,0.01-0.166,0.088-0.195c0.078-0.03,0.166,0.01,0.195,0.089c0.141,0.376,0.506,0.628,0.908,0.628
                          c0.535,0,0.969-0.434,0.969-0.969c0-0.535-0.434-0.969-0.969-0.969c-0.21,0-0.409,0.066-0.577,0.19
                          c-0.067,0.05-0.163,0.035-0.212-0.031c-0.05-0.068-0.036-0.163,0.031-0.213c0.22-0.164,0.483-0.25,0.758-0.25
                          c0.702,0,1.272,0.571,1.272,1.272C91.466,138.462,90.895,139.033,90.193,139.033z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M90.195,139.872c-0.084,0-0.152-0.068-0.152-0.152v-2.208
                          c0-0.084,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.208C90.347,139.804,90.279,139.872,90.195,139.872z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M90.195,138.159c-0.041,0-0.082-0.016-0.111-0.049
                          c-0.057-0.062-0.053-0.157,0.008-0.214l0.337-0.312c0.062-0.057,0.157-0.053,0.214,0.009c0.057,0.061,0.053,0.157-0.009,0.214
                          l-0.337,0.312C90.269,138.146,90.232,138.159,90.195,138.159z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M90.195,138.639c-0.037,0-0.074-0.014-0.103-0.041l-0.471-0.436
                          c-0.062-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.471,0.436c0.062,0.057,0.065,0.153,0.008,0.214
                          C90.277,138.623,90.236,138.639,90.195,138.639z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.075,139.872c-0.083,0-0.152-0.068-0.152-0.152v-2.145
                          c0-0.083,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.145C86.227,139.804,86.159,139.872,86.075,139.872z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.076,138.224c-0.041,0-0.082-0.016-0.112-0.049
                          c-0.056-0.062-0.053-0.157,0.009-0.214l0.339-0.313c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.062,0.053,0.157-0.009,0.214
                          l-0.338,0.313C86.149,138.211,86.113,138.224,86.076,138.224z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.075,138.705c-0.037,0-0.074-0.014-0.103-0.041l-0.473-0.436
                          c-0.061-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.473,0.437c0.062,0.057,0.065,0.153,0.008,0.214
                          C86.157,138.689,86.116,138.705,86.075,138.705z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M86.942,139.188h-1.737c-0.312,0-0.565-0.254-0.565-0.565
                          c0-0.179,0.085-0.345,0.224-0.45c-0.007-0.044-0.01-0.089-0.01-0.133c0-0.275,0.133-0.535,0.351-0.697c0-0.002,0-0.003,0-0.005
                          c0-0.48,0.39-0.869,0.869-0.869c0.241,0,0.474,0.101,0.637,0.278c0.057,0.062,0.054,0.157-0.008,0.214
                          c-0.062,0.057-0.157,0.054-0.215-0.008c-0.108-0.117-0.255-0.181-0.415-0.181c-0.312,0-0.566,0.254-0.566,0.566
                          c0,0.022,0.002,0.044,0.004,0.065c0.007,0.06-0.022,0.119-0.074,0.148c-0.173,0.102-0.281,0.289-0.281,0.489
                          c0,0.054,0.008,0.109,0.024,0.162c0.022,0.073-0.014,0.15-0.083,0.181c-0.094,0.043-0.155,0.136-0.155,0.239
                          c0,0.144,0.117,0.262,0.262,0.262h1.737c0.144,0,0.262-0.118,0.262-0.262c0-0.051-0.015-0.101-0.043-0.144
                          c-0.046-0.07-0.027-0.164,0.043-0.21c0.07-0.046,0.164-0.026,0.21,0.043c0.061,0.092,0.093,0.199,0.093,0.311
                          C87.508,138.934,87.253,139.188,86.942,139.188z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M91.314,139.872h-6.523c-0.084,0-0.152-0.068-0.152-0.152
                          c0-0.084,0.068-0.152,0.152-0.152h6.523c0.084,0,0.152,0.068,0.152,0.152C91.466,139.804,91.398,139.872,91.314,139.872z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Andringitra }
          ************************************************************ */}
      <g
        id="andringitra"
        className={hideorshow("andringitra")}
        onMouseEnter={(e) => showcityinfo(e, 23)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 181.6802 457.1519)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Andringitra
        </text>

        <path d="M210.749,429.884c-3.349,0-6.063,2.665-6.063,5.946c0,3.285,6.063,14.054,6.063,14.054s6.063-10.771,6.063-14.054 S214.096,429.884,210.749,429.884z" />
      </g>

      {/* **********************************************************  
                                { Ranohira }
          ************************************************************ */}
      <g
        id="ranohira"
        className={hideorshow("ranohira")}
        onMouseEnter={(e) => showcityinfo(e, 8)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 151.2354 471.6626)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Ranohira
        </text>
        <path
          fill="#989898"
          d="M151.032,440.89c-3.349,0-6.063,2.664-6.063,5.945c0,3.285,6.063,14.055,6.063,14.055
                      s6.063-10.771,6.063-14.055S154.379,440.89,151.032,440.89z"
        />

        <g id="Parc_Isalo">
          <g
            id="Parc_Isalo_1_"
            className="park"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M47.31,162.058c-0.896,0-1.625-0.729-1.625-1.625
                          c0-0.773,0.638-3.006,1.625-3.006c0.986,0,1.624,2.233,1.624,3.006C48.935,161.329,48.206,162.058,47.31,162.058z M47.31,157.73
                          c-0.659,0-1.322,1.853-1.322,2.703c0,0.728,0.593,1.322,1.322,1.322c0.728,0,1.321-0.593,1.321-1.322
                          C48.631,159.583,47.968,157.73,47.31,157.73z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M47.313,163.161c-0.083,0-0.151-0.068-0.151-0.152v-3.271
                          c0-0.084,0.068-0.152,0.151-0.152c0.084,0,0.152,0.068,0.152,0.152v3.271C47.465,163.093,47.397,163.161,47.313,163.161z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M47.313,160.542c-0.041,0-0.081-0.016-0.111-0.049
                          c-0.057-0.062-0.053-0.157,0.008-0.214l0.443-0.41c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.061,0.053,0.157-0.008,0.214
                          l-0.443,0.41C47.386,160.529,47.35,160.542,47.313,160.542z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M47.313,161.172c-0.037,0-0.074-0.014-0.103-0.041l-0.619-0.572
                          c-0.061-0.057-0.065-0.153-0.008-0.214c0.056-0.062,0.153-0.065,0.214-0.008l0.619,0.573c0.062,0.057,0.065,0.153,0.008,0.214
                          C47.394,161.155,47.354,161.172,47.313,161.172z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M49.431,162.322c-0.527,0-1.006-0.332-1.191-0.825
                          c-0.03-0.078,0.01-0.166,0.088-0.195c0.078-0.03,0.166,0.01,0.195,0.089c0.141,0.376,0.506,0.628,0.908,0.628
                          c0.535,0,0.969-0.434,0.969-0.969c0-0.535-0.434-0.969-0.969-0.969c-0.21,0-0.409,0.066-0.577,0.19
                          c-0.067,0.05-0.163,0.035-0.212-0.031c-0.05-0.068-0.036-0.163,0.031-0.213c0.22-0.164,0.483-0.25,0.758-0.25
                          c0.702,0,1.272,0.571,1.272,1.272C50.703,161.751,50.132,162.322,49.431,162.322z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M49.433,163.161c-0.084,0-0.152-0.068-0.152-0.152v-2.208
                          c0-0.084,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.208C49.584,163.093,49.516,163.161,49.433,163.161z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M49.433,161.448c-0.041,0-0.082-0.016-0.111-0.049
                          c-0.057-0.062-0.053-0.157,0.008-0.214l0.337-0.312c0.062-0.057,0.157-0.053,0.214,0.009c0.057,0.061,0.053,0.157-0.009,0.214
                          l-0.337,0.312C49.506,161.436,49.469,161.448,49.433,161.448z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M49.433,161.928c-0.037,0-0.074-0.014-0.103-0.041l-0.471-0.436
                          c-0.062-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.471,0.436c0.062,0.057,0.065,0.153,0.008,0.214
                          C49.514,161.912,49.473,161.928,49.433,161.928z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M45.313,163.161c-0.083,0-0.152-0.068-0.152-0.152v-2.145
                          c0-0.083,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.145C45.465,163.093,45.397,163.161,45.313,163.161z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M45.313,161.513c-0.041,0-0.082-0.016-0.112-0.049
                          c-0.056-0.062-0.053-0.157,0.009-0.214l0.338-0.313c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.062,0.053,0.157-0.009,0.214
                          l-0.338,0.313C45.387,161.5,45.35,161.513,45.313,161.513z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M45.313,161.994c-0.037,0-0.074-0.014-0.103-0.041l-0.473-0.437
                          c-0.061-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.473,0.437c0.062,0.057,0.065,0.153,0.008,0.214
                          C45.394,161.978,45.354,161.994,45.313,161.994z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M46.179,162.477h-1.737c-0.312,0-0.565-0.254-0.565-0.565
                          c0-0.179,0.085-0.345,0.224-0.45c-0.007-0.044-0.01-0.089-0.01-0.133c0-0.275,0.133-0.535,0.351-0.697c0-0.002,0-0.003,0-0.005
                          c0-0.48,0.39-0.869,0.869-0.869c0.241,0,0.474,0.101,0.637,0.278c0.057,0.062,0.054,0.157-0.008,0.214
                          c-0.062,0.057-0.157,0.054-0.215-0.008c-0.108-0.117-0.255-0.181-0.415-0.181c-0.312,0-0.566,0.254-0.566,0.566
                          c0,0.022,0.002,0.044,0.004,0.065c0.007,0.06-0.022,0.119-0.074,0.148c-0.173,0.102-0.281,0.289-0.281,0.489
                          c0,0.054,0.008,0.109,0.024,0.162c0.022,0.073-0.014,0.15-0.083,0.181c-0.094,0.043-0.155,0.136-0.155,0.239
                          c0,0.144,0.117,0.262,0.262,0.262h1.737c0.144,0,0.262-0.118,0.262-0.262c0-0.051-0.015-0.101-0.043-0.144
                          c-0.046-0.07-0.027-0.164,0.043-0.21c0.07-0.046,0.164-0.026,0.21,0.043c0.061,0.092,0.093,0.199,0.093,0.311
                          C46.745,162.223,46.491,162.477,46.179,162.477z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M50.551,163.161h-6.523c-0.084,0-0.152-0.068-0.152-0.152
                          c0-0.084,0.068-0.152,0.152-0.152h6.523c0.084,0,0.152,0.068,0.152,0.152C50.703,163.093,50.635,163.161,50.551,163.161z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Ifaty }
          ************************************************************ */}
      <g
        id="ifaty"
        className={hideorshow("ifaty")}
        onMouseEnter={(e) => showcityinfo(e, 25)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 94.0208 479.3378)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Ifaty
        </text>
        <path
          fill="#989898"
          d="M85.156,465.291c-3.354,0-6.063,2.664-6.063,5.947c0,3.277,6.063,14.053,6.063,14.053
          s6.062-10.771,6.062-14.053C91.218,467.956,88.504,465.291,85.156,465.291z"
        />

        <g id="Beach_Ifaty" className="beach">
          <g
            id="Beach_Ifaty_1_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M26.969,170.351c-0.285-0.166-0.572-0.246-0.859-0.242
                          c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
                          c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
                          c0.002,0.001,0.004,0.002,0.006,0.002c0,0,0.001,0,0.001,0c0.001,0,0.001,0.001,0.002,0.001c0,0,0.001,0,0.001,0
                          c0.006,0.002,0.013,0.002,0.019,0.002c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001c0,0,0.001,0,0.001,0
                          c0.003,0,0.005-0.001,0.008-0.001c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004
                          c0.002-0.001,0.003-0.001,0.005-0.001c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019
                          c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001c0,0,0.001,0,0.001,0
                          c0.007,0.002,0.015,0.003,0.022,0.003c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005
                          c0.005-0.002,0.009-0.003,0.013-0.006c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023
                          c0.001-0.001,0.001-0.003,0.002-0.004c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7
                          c-0.379-0.524-0.964-0.914-1.648-1.097c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288c0,0,0,0,0,0
                          c-0.001,0.002-0.001,0.003-0.002,0.005c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019
                          c0.001,0.007,0.003,0.012,0.005,0.019c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002
                          c0.003,0.006,0.008,0.011,0.012,0.016c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007
                          c0.006,0.003,0.012,0.007,0.018,0.009c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001
                          c0.007,0.001,0.013,0.002,0.02,0.001c0.007-0.001,0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003
                          c0.638-0.319,1.14-0.184,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003
                          c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002
                          c0.023,0.01,0.05,0.01,0.074-0.002c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027
                          c-1.34,0-2.567,0.42-3.146,1.072c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123
                          c0.025,0.043,0.08,0.057,0.123,0.032c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243
                          s0.56-0.081,0.837-0.243c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
                          c0.043,0.025,0.098,0.011,0.123-0.032C27.027,170.431,27.012,170.376,26.969,170.351z M23.702,166.468
                          c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C24.8,166.354,24.298,166.225,23.702,166.468z
                          M26.47,165.928c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
                          C25.788,165.234,26.19,165.541,26.47,165.928z M22.068,166.006c0.171-0.428,0.52-0.775,0.999-0.99
                          c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C23.141,165.918,22.65,165.782,22.068,166.006z
                          M24.4,166.541c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L24.4,166.541z
                          M25.295,170.351c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
                          c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
                          C25.687,170.164,25.49,170.237,25.295,170.351z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M19.881,166.036c-0.017,0-0.034-0.002-0.05-0.007
                          c-0.055-0.016-0.1-0.058-0.118-0.113l-0.084-0.246l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
                          c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133
                          c-0.008-0.057,0.012-0.115,0.054-0.154l0.189-0.179l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163s0.072-0.09,0.129-0.101
                          l0.256-0.048l0.01-0.258c0.003-0.058,0.033-0.111,0.082-0.142c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212
                          c0.033-0.047,0.088-0.075,0.145-0.075c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099
                          c0.054-0.022,0.115-0.016,0.163,0.015c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046
                          c0.057,0.011,0.105,0.049,0.129,0.101c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177
                          c0.042,0.04,0.062,0.097,0.054,0.155c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251
                          c0.014,0.056,0,0.116-0.038,0.16c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245
                          c-0.019,0.055-0.063,0.097-0.118,0.113c-0.055,0.016-0.116,0.005-0.161-0.032l-0.204-0.162l-0.202,0.162
                          C19.96,166.022,19.921,166.036,19.881,166.036z M19.631,165.49c0.076,0,0.143,0.048,0.168,0.121l0.084,0.246l0.201-0.163
                          c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162l0.081-0.245c0.027-0.079,0.103-0.128,0.186-0.12l0.259,0.026l-0.065-0.251
                          c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118l-0.19-0.176c-0.06-0.057-0.074-0.147-0.031-0.219l0.131-0.225l-0.255-0.045
                          c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26l-0.239,0.1c-0.077,0.031-0.164,0.006-0.212-0.063l-0.149-0.211l-0.148,0.211
                          c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098l-0.009,0.259c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222
                          c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179l0.232,0.115c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028
                          C19.619,165.491,19.625,165.49,19.631,165.49z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M20.195,165.398c-0.347,0-0.63-0.283-0.63-0.63s0.283-0.63,0.63-0.63
                          s0.63,0.283,0.63,0.63S20.542,165.398,20.195,165.398z M20.195,164.318c-0.248,0-0.45,0.202-0.45,0.45s0.202,0.45,0.45,0.45
                          c0.248,0,0.45-0.202,0.45-0.45S20.443,164.318,20.195,164.318z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Andasibe }
          ************************************************************ */}

      <g
        id="andasibe"
        className={hideorshow("andasibe")}
        onMouseEnter={(e) => showcityinfo(e, 7)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <g id="Parc_Andasibe">
          <g
            className="park"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#82c8a0"
              strokeMiterlimit="10"
              d="M96.43,116.179c-0.896,0-1.625-0.729-1.625-1.625
                          c0-0.773,0.638-3.006,1.625-3.006c0.986,0,1.624,2.233,1.624,3.006C98.054,115.45,97.326,116.179,96.43,116.179z M96.43,111.851
                          c-0.659,0-1.322,1.853-1.322,2.703c0,0.728,0.593,1.322,1.322,1.322c0.728,0,1.321-0.593,1.321-1.322
                          C97.751,113.704,97.087,111.851,96.43,111.851z"
            />
            <path
              stroke="#82c8a0"
              strokeMiterlimit="10"
              d="M96.432,117.283c-0.083,0-0.151-0.068-0.151-0.152v-3.271
                          c0-0.084,0.068-0.152,0.151-0.152c0.084,0,0.152,0.068,0.152,0.152v3.271C96.584,117.214,96.516,117.283,96.432,117.283z"
            />
            <path
              stroke="#82c8a0"
              strokeMiterlimit="10"
              d="M96.432,114.663c-0.041,0-0.081-0.016-0.111-0.049
                          c-0.057-0.062-0.053-0.157,0.008-0.214l0.443-0.41c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.061,0.053,0.157-0.008,0.214
                          l-0.443,0.41C96.506,114.65,96.469,114.663,96.432,114.663z"
            />
            <path
              stroke="#82c8a0"
              strokeMiterlimit="10"
              d="M96.432,115.294c-0.037,0-0.074-0.014-0.103-0.041l-0.619-0.572
                          c-0.061-0.057-0.065-0.153-0.008-0.214c0.056-0.062,0.153-0.065,0.214-0.008l0.619,0.573c0.062,0.057,0.065,0.153,0.008,0.214
                          C96.514,115.277,96.473,115.294,96.432,115.294z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M98.55,116.443c-0.527,0-1.006-0.332-1.191-0.825
                          c-0.03-0.078,0.01-0.166,0.088-0.195c0.078-0.03,0.166,0.01,0.195,0.089c0.141,0.376,0.506,0.628,0.908,0.628
                          c0.535,0,0.969-0.434,0.969-0.969c0-0.535-0.434-0.969-0.969-0.969c-0.21,0-0.409,0.066-0.577,0.19
                          c-0.067,0.05-0.163,0.035-0.212-0.031c-0.05-0.068-0.036-0.163,0.031-0.213c0.22-0.164,0.483-0.25,0.758-0.25
                          c0.702,0,1.272,0.571,1.272,1.272C99.822,115.872,99.252,116.443,98.55,116.443z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M98.552,117.283c-0.084,0-0.152-0.068-0.152-0.152v-2.208
                          c0-0.084,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.208C98.704,117.214,98.636,117.283,98.552,117.283z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M98.552,115.57c-0.041,0-0.082-0.016-0.111-0.049
                          c-0.057-0.062-0.053-0.157,0.008-0.214l0.337-0.312c0.062-0.057,0.157-0.053,0.214,0.009c0.057,0.061,0.053,0.157-0.009,0.214
                          l-0.337,0.312C98.626,115.557,98.589,115.57,98.552,115.57z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M98.552,116.05c-0.037,0-0.074-0.014-0.103-0.041l-0.471-0.436
                          c-0.062-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.471,0.436c0.062,0.057,0.065,0.153,0.008,0.214
                          C98.633,116.033,98.593,116.05,98.552,116.05z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M94.432,117.283c-0.083,0-0.152-0.068-0.152-0.152v-2.145
                          c0-0.083,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.145C94.584,117.214,94.516,117.283,94.432,117.283z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M94.432,115.635c-0.041,0-0.082-0.016-0.112-0.049
                          c-0.056-0.062-0.053-0.157,0.009-0.214l0.338-0.313c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.062,0.053,0.157-0.009,0.214
                          l-0.338,0.313C94.506,115.622,94.469,115.635,94.432,115.635z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M94.432,116.116c-0.037,0-0.074-0.014-0.103-0.041l-0.473-0.437
                          c-0.061-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.473,0.437c0.062,0.057,0.065,0.153,0.008,0.214
                          C94.513,116.1,94.473,116.116,94.432,116.116z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M95.298,116.598h-1.737c-0.312,0-0.565-0.254-0.565-0.565
                          c0-0.179,0.085-0.345,0.224-0.45c-0.007-0.044-0.01-0.089-0.01-0.133c0-0.275,0.133-0.535,0.351-0.697c0-0.002,0-0.003,0-0.005
                          c0-0.48,0.39-0.869,0.869-0.869c0.241,0,0.474,0.101,0.637,0.278c0.057,0.062,0.054,0.157-0.008,0.214
                          c-0.062,0.057-0.157,0.054-0.215-0.008c-0.108-0.117-0.255-0.181-0.415-0.181c-0.312,0-0.566,0.254-0.566,0.566
                          c0,0.022,0.002,0.044,0.004,0.065c0.007,0.06-0.022,0.119-0.074,0.148c-0.173,0.102-0.281,0.289-0.281,0.489
                          c0,0.054,0.008,0.109,0.024,0.162c0.022,0.073-0.014,0.15-0.083,0.181c-0.094,0.043-0.155,0.136-0.155,0.239
                          c0,0.144,0.117,0.262,0.262,0.262h1.737c0.144,0,0.262-0.118,0.262-0.262c0-0.051-0.015-0.101-0.043-0.144
                          c-0.046-0.07-0.027-0.164,0.043-0.21c0.07-0.046,0.164-0.026,0.21,0.043c0.061,0.092,0.093,0.199,0.093,0.311
                          C95.864,116.344,95.61,116.598,95.298,116.598z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M99.671,117.283h-6.523c-0.084,0-0.152-0.068-0.152-0.152
                          c0-0.084,0.068-0.152,0.152-0.152h6.523c0.084,0,0.152,0.068,0.152,0.152C99.822,117.214,99.755,117.283,99.671,117.283z"
            />
          </g>
        </g>
        <text
          transform="matrix(1 0 0 1 270.1958 318.3042)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Andasibe
        </text>
        <path
          d="M271.506,292.016c-3.35,0-6.062,2.665-6.062,5.946c0,3.286,6.062,14.054,6.062,14.054s6.063-10.77,6.063-14.054
                        C277.569,294.68,274.854,292.016,271.506,292.016z"
        />
      </g>

      {/* **********************************************************  
                                { Manambato }
          ************************************************************ */}

      <g
        id="manambato"
        className={hideorshow("manambato")}
        onMouseEnter={(e) => showcityinfo(e, 27)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(0.9463 0 0 1 300.4077 305.1929)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12.6805"
        >
          Akanin’ny Nofy
        </text>
        <path
          d="M300.506,282.465c-3.35,0-6.062,2.665-6.062,5.946c0,3.287,6.062,14.054,6.062,14.054s6.063-10.771,6.063-14.054
                        C306.569,285.129,303.854,282.465,300.506,282.465z"
        />
        <g id="Beach_Manambato" className="beach">
          <g
            id="Beach_Ifaty_3_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M117.688,105.889c-0.285-0.166-0.572-0.246-0.859-0.242
                c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
                c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
                c0.002,0.001,0.004,0.002,0.006,0.002h0.001c0.001,0,0.001,0.001,0.002,0.001h0.001c0.006,0.002,0.013,0.002,0.019,0.002
                c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001h0.001c0.003,0,0.005-0.001,0.008-0.001
                c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004c0.002-0.001,0.003-0.001,0.005-0.001
                c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019c0.002,0.001,0.003,0.002,0.005,0.003
                c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001h0.001c0.007,0.002,0.015,0.003,0.022,0.003
                c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005c0.005-0.002,0.009-0.003,0.013-0.006
                c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023c0.001-0.001,0.001-0.003,0.002-0.004
                c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7c-0.379-0.524-0.964-0.914-1.648-1.097
                c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288l0,0c-0.001,0.002-0.001,0.003-0.002,0.005
                c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019c0.001,0.007,0.003,0.012,0.005,0.019
                c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002c0.003,0.006,0.008,0.011,0.012,0.016
                c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007c0.006,0.003,0.012,0.007,0.018,0.009
                c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001c0.007,0.001,0.013,0.002,0.02,0.001
                c0.007-0.001,0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003c0.638-0.319,1.14-0.184,1.533,0.411
                c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014
                c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002c0.023,0.01,0.05,0.01,0.074-0.002
                c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027c-1.34,0-2.567,0.42-3.146,1.072
                c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123c0.025,0.043,0.08,0.057,0.123,0.032
                c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243c0.28,0,0.56-0.081,0.837-0.243
                c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
                c0.043,0.025,0.098,0.011,0.123-0.032C117.746,105.969,117.732,105.914,117.688,105.889z M114.422,102.006
                c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C115.519,101.892,115.018,101.763,114.422,102.006z
                M117.189,101.466c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
                C116.508,100.772,116.91,101.079,117.189,101.466z M112.788,101.544c0.171-0.428,0.52-0.775,0.999-0.99
                c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C113.86,101.456,113.37,101.32,112.788,101.544z
                M115.119,102.079c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L115.119,102.079z
                M116.014,105.889c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
                c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
                C116.406,105.702,116.21,105.775,116.014,105.889z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M110.601,101.574c-0.017,0-0.034-0.002-0.05-0.007
                c-0.055-0.016-0.1-0.058-0.118-0.113l-0.084-0.246l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
                c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133
                c-0.008-0.057,0.012-0.115,0.054-0.154l0.189-0.179l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163
                c0.024-0.052,0.072-0.09,0.129-0.101l0.256-0.048l0.01-0.258c0.003-0.058,0.033-0.111,0.082-0.142
                c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212c0.033-0.047,0.088-0.075,0.145-0.075
                c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099c0.054-0.022,0.115-0.016,0.163,0.015
                c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046c0.057,0.011,0.105,0.049,0.129,0.101
                c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177c0.042,0.04,0.062,0.097,0.054,0.155
                c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251c0.014,0.056,0,0.116-0.038,0.16
                c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245c-0.019,0.055-0.063,0.097-0.118,0.113
                c-0.055,0.016-0.116,0.005-0.161-0.032l-0.204-0.162l-0.202,0.162C110.679,101.56,110.64,101.574,110.601,101.574z
                M110.351,101.028c0.076,0,0.143,0.048,0.168,0.121l0.084,0.246l0.201-0.163c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162
                l0.081-0.245c0.027-0.079,0.103-0.128,0.186-0.12l0.259,0.026l-0.065-0.251c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118
                l-0.19-0.176c-0.06-0.057-0.074-0.147-0.031-0.219l0.131-0.225l-0.255-0.045c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26
                l-0.239,0.1c-0.077,0.031-0.164,0.006-0.212-0.063l-0.149-0.211l-0.148,0.211c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098
                l-0.009,0.259c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179
                l0.232,0.115c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028C110.338,101.029,110.344,101.028,110.351,101.028z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M110.915,100.936c-0.347,0-0.63-0.283-0.63-0.63c0-0.347,0.283-0.63,0.63-0.63
                c0.347,0,0.63,0.283,0.63,0.63C111.544,100.653,111.262,100.936,110.915,100.936z M110.915,99.856c-0.248,0-0.45,0.202-0.45,0.45
                c0,0.248,0.202,0.45,0.45,0.45c0.248,0,0.45-0.202,0.45-0.45C111.364,100.058,111.162,99.856,110.915,99.856z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Toamasina }
          ************************************************************ */}

      <g
        id="toamasina"
        className={hideorshow("toamasina")}
        onMouseEnter={(e) => showcityinfo(e, 30)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 309.6577 281.6851)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Toamasina
        </text>

        <path
          d="M308.506,259.551c-3.35,0-6.062,2.665-6.062,5.946c0,3.286,6.062,14.054,6.062,14.054s6.063-10.77,6.063-14.054
              C314.569,262.215,311.854,259.551,308.506,259.551z"
        />
      </g>

      {/* **********************************************************  
                                { Miandrivazo }
          ************************************************************ */}

      <g
        id="miandrivazo"
        className={hideorshow("miandrivazo")}
        onMouseEnter={(e) => showcityinfo(e, 14)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 127.123 345.3315)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Miandrivazo
        </text>

        <path
          d="M154.089,317.902c-3.349,0-6.062,2.664-6.062,5.945c0,3.286,6.062,14.055,6.062,14.055s6.063-10.771,6.063-14.055
                        C160.152,320.566,157.437,317.902,154.089,317.902z"
        />
      </g>

      {/* **********************************************************  
                                { Morondava }
          ************************************************************ */}

      <g
        id="morondava"
        className={hideorshow("morondava")}
        onMouseEnter={(e) => showcityinfo(e, 10)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 108.873 377.6343)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Morondava
        </text>

        <path
          d="M107.589,351.902c-3.349,0-6.062,2.665-6.062,5.946c0,3.285,6.062,14.054,6.062,14.054s6.063-10.771,6.063-14.054
                        S110.937,351.902,107.589,351.902z"
        />
        <g id="Beach_Morondava" className="beach">
          <g
            id="Beach_Ifaty_11_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M35.808,129.68c-0.285-0.166-0.572-0.246-0.859-0.242
                c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
                c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
                c0.002,0.001,0.004,0.002,0.006,0.002h0.001c0.001,0,0.001,0.001,0.002,0.001h0.001c0.006,0.002,0.013,0.002,0.019,0.002
                c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001h0.001c0.003,0,0.005-0.001,0.008-0.001
                c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004c0.002-0.001,0.003-0.001,0.005-0.001
                c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019c0.002,0.001,0.003,0.002,0.005,0.003
                c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001h0.001c0.007,0.002,0.015,0.003,0.022,0.003
                c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005c0.005-0.002,0.009-0.003,0.013-0.006
                c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023c0.001-0.001,0.001-0.003,0.002-0.004
                c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7c-0.379-0.524-0.964-0.914-1.648-1.097
                c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288l0,0c-0.001,0.002-0.001,0.003-0.002,0.005
                c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019c0.001,0.007,0.003,0.012,0.005,0.019
                c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002c0.003,0.006,0.008,0.011,0.012,0.016
                c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007c0.006,0.003,0.012,0.007,0.018,0.009
                c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001c0.007,0.001,0.013,0.002,0.02,0.001
                c0.007-0.001,0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003c0.638-0.319,1.14-0.184,1.533,0.411
                c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014
                c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002c0.023,0.01,0.05,0.01,0.074-0.002
                c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027c-1.34,0-2.567,0.42-3.146,1.072
                c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123c0.025,0.043,0.08,0.057,0.123,0.032
                c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243c0.28,0,0.56-0.081,0.837-0.243
                c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
                c0.043,0.025,0.098,0.011,0.123-0.032C35.866,129.76,35.851,129.705,35.808,129.68z M32.542,125.797
                c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C33.639,125.683,33.138,125.555,32.542,125.797z
                 M35.309,125.257c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
                C34.627,124.563,35.03,124.871,35.309,125.257z M30.907,125.336c0.171-0.428,0.52-0.775,0.999-0.99
                c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C31.98,125.247,31.49,125.111,30.907,125.336z
                 M33.239,125.87c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L33.239,125.87z
                 M34.134,129.68c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
                c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
                C34.526,129.493,34.33,129.566,34.134,129.68z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M28.72,125.365c-0.017,0-0.034-0.002-0.05-0.007
                c-0.055-0.016-0.1-0.058-0.118-0.113L28.468,125l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
                c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133
                c-0.008-0.057,0.012-0.115,0.054-0.154L28,123.972l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163
                c0.024-0.052,0.072-0.09,0.129-0.101l0.256-0.048l0.01-0.258c0.003-0.058,0.033-0.111,0.082-0.142
                c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212c0.033-0.047,0.088-0.075,0.145-0.075
                c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099c0.054-0.022,0.115-0.016,0.163,0.015
                c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046c0.057,0.011,0.105,0.049,0.129,0.101
                c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177c0.042,0.04,0.062,0.097,0.054,0.155
                c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251c0.014,0.056,0,0.116-0.038,0.16
                c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245c-0.019,0.055-0.063,0.097-0.118,0.113
                c-0.055,0.016-0.116,0.005-0.161-0.032l-0.204-0.162l-0.202,0.162C28.799,125.352,28.76,125.365,28.72,125.365z M28.471,124.82
                c0.076,0,0.143,0.048,0.168,0.121l0.084,0.246l0.201-0.163c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162l0.081-0.245
                c0.027-0.079,0.103-0.128,0.186-0.12l0.259,0.026l-0.065-0.251c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118l-0.19-0.176
                c-0.06-0.057-0.074-0.147-0.031-0.219l0.131-0.225l-0.255-0.045c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26l-0.239,0.1
                c-0.077,0.031-0.164,0.006-0.212-0.063l-0.149-0.211l-0.148,0.211c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098
                l-0.009,0.259c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179
                l0.232,0.115c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028C28.458,124.821,28.464,124.82,28.471,124.82z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M29.035,124.727c-0.347,0-0.63-0.283-0.63-0.63c0-0.347,0.283-0.63,0.63-0.63
                c0.347,0,0.63,0.283,0.63,0.63C29.664,124.445,29.381,124.727,29.035,124.727z M29.035,123.648c-0.248,0-0.45,0.202-0.45,0.45
                c0,0.248,0.202,0.45,0.45,0.45c0.248,0,0.45-0.202,0.45-0.45C29.484,123.85,29.282,123.648,29.035,123.648z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Belo sur Mer}
          ************************************************************ */}

      <g
        id="belosurmer"
        className={hideorshow("belosurmer")}
        onMouseEnter={(e) => showcityinfo(e, 16)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 65.123 398.9019)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Belo-Sur-Mer
        </text>

        <path
          d="M95.089,373.152c-3.349,0-6.062,2.665-6.062,5.946c0,3.285,6.062,14.054,6.062,14.054s6.063-10.771,6.063-14.054
                        S98.437,373.152,95.089,373.152z"
        />
      </g>

      {/* **********************************************************  
                                { Bekopaka }
          ************************************************************ */}

      <g
        id="bekopaka"
        className={hideorshow("bekopaka")}
        onMouseEnter={(e) => showcityinfo(e, 9)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 99.873 288.6851)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Bekopaka
        </text>

        <path
          d="M125.452,291.134c-3.349,0-6.062,2.665-6.062,5.946c0,3.285,6.062,14.054,6.062,14.054s6.063-10.77,6.063-14.054
                        C131.515,293.798,128.8,291.134,125.452,291.134z"
        />
        <g id="Parc_Tsingy_Bemaraha">
          <g
            id="parc_Ranomafana_1_"
            className="park"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M50.856,106.485c-0.896,0-1.625-0.729-1.625-1.625
                c0-0.773,0.638-3.006,1.625-3.006c0.986,0,1.624,2.233,1.624,3.006C52.48,105.755,51.752,106.485,50.856,106.485z M50.856,102.157
                c-0.659,0-1.322,1.853-1.322,2.703c0,0.728,0.593,1.322,1.322,1.322c0.728,0,1.321-0.593,1.321-1.322
                C52.177,104.01,51.513,102.157,50.856,102.157z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M50.858,107.588c-0.083,0-0.151-0.068-0.151-0.152v-3.271
                c0-0.084,0.068-0.152,0.151-0.152c0.084,0,0.152,0.068,0.152,0.152v3.271C51.011,107.52,50.942,107.588,50.858,107.588z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M50.858,104.969c-0.041,0-0.081-0.016-0.111-0.049
                c-0.057-0.062-0.053-0.157,0.008-0.214l0.443-0.41c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.061,0.053,0.157-0.008,0.214
                l-0.443,0.41C50.932,104.956,50.896,104.969,50.858,104.969z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M50.858,105.599c-0.037,0-0.074-0.014-0.103-0.041l-0.619-0.572
                c-0.061-0.057-0.065-0.153-0.008-0.214c0.056-0.062,0.153-0.065,0.214-0.008l0.619,0.573c0.062,0.057,0.065,0.153,0.008,0.214
                C50.94,105.582,50.899,105.599,50.858,105.599z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M52.976,106.749c-0.527,0-1.006-0.332-1.191-0.825
                c-0.03-0.078,0.01-0.166,0.088-0.195c0.078-0.03,0.166,0.01,0.195,0.089c0.141,0.376,0.506,0.628,0.908,0.628
                c0.535,0,0.969-0.434,0.969-0.969c0-0.535-0.434-0.969-0.969-0.969c-0.21,0-0.409,0.066-0.577,0.19
                c-0.067,0.05-0.163,0.035-0.212-0.031c-0.05-0.068-0.036-0.163,0.031-0.213c0.22-0.164,0.483-0.25,0.758-0.25
                c0.702,0,1.272,0.571,1.272,1.272C54.249,106.178,53.678,106.749,52.976,106.749z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M52.978,107.588c-0.084,0-0.152-0.068-0.152-0.152v-2.208
                c0-0.084,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.208C53.13,107.52,53.062,107.588,52.978,107.588z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M52.978,105.875c-0.041,0-0.082-0.016-0.111-0.049
                c-0.057-0.062-0.053-0.157,0.008-0.214l0.337-0.312c0.062-0.057,0.157-0.053,0.214,0.009c0.057,0.061,0.053,0.157-0.009,0.214
                l-0.337,0.312C53.052,105.862,53.015,105.875,52.978,105.875z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M52.978,106.355c-0.037,0-0.074-0.014-0.103-0.041l-0.471-0.436
                c-0.062-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.471,0.436c0.062,0.057,0.065,0.153,0.008,0.214
                C53.06,106.339,53.019,106.355,52.978,106.355z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M48.858,107.588c-0.083,0-0.152-0.068-0.152-0.152v-2.145
                c0-0.083,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.145C49.01,107.52,48.942,107.588,48.858,107.588z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M48.859,105.94c-0.041,0-0.082-0.016-0.112-0.049
                c-0.056-0.062-0.053-0.157,0.009-0.214l0.339-0.313c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.062,0.053,0.157-0.009,0.214
                l-0.338,0.313C48.932,105.927,48.896,105.94,48.859,105.94z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M48.858,106.421c-0.037,0-0.074-0.014-0.103-0.041l-0.473-0.436
                c-0.061-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.473,0.437c0.062,0.057,0.065,0.153,0.008,0.214
                C48.94,106.405,48.899,106.421,48.858,106.421z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M49.725,106.904h-1.737c-0.312,0-0.565-0.254-0.565-0.565
                c0-0.179,0.085-0.345,0.224-0.45c-0.007-0.044-0.01-0.089-0.01-0.133c0-0.275,0.133-0.535,0.351-0.697c0-0.002,0-0.003,0-0.005
                c0-0.48,0.39-0.869,0.869-0.869c0.241,0,0.474,0.101,0.637,0.278c0.057,0.062,0.054,0.157-0.008,0.214
                c-0.062,0.057-0.157,0.054-0.215-0.008c-0.108-0.117-0.255-0.181-0.415-0.181c-0.312,0-0.566,0.254-0.566,0.566
                c0,0.022,0.002,0.044,0.004,0.065c0.007,0.06-0.022,0.119-0.074,0.148c-0.173,0.102-0.281,0.289-0.281,0.489
                c0,0.054,0.008,0.109,0.024,0.162c0.022,0.073-0.014,0.15-0.083,0.181c-0.094,0.043-0.155,0.136-0.155,0.239
                c0,0.144,0.117,0.262,0.262,0.262h1.737c0.144,0,0.262-0.118,0.262-0.262c0-0.051-0.015-0.101-0.043-0.144
                c-0.046-0.07-0.027-0.164,0.043-0.21s0.164-0.026,0.21,0.043c0.061,0.092,0.093,0.199,0.093,0.311
                C50.291,106.65,50.036,106.904,49.725,106.904z"
            />
            <path
              stroke="#008000"
              strokeMiterlimit="10"
              d="M54.097,107.588h-6.523c-0.084,0-0.152-0.068-0.152-0.152
                c0-0.084,0.068-0.152,0.152-0.152h6.523c0.084,0,0.152,0.068,0.152,0.152C54.249,107.52,54.181,107.588,54.097,107.588z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Tsiribihina }
          ************************************************************ */}

      <g
        id="tsiribihina"
        className={hideorshow("tsiribihina")}
        onMouseEnter={(e) => showcityinfo(e, 15)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text transform="matrix(1 0 0 1 52.873 326.3042)">
          <tspan x="0" y="4" fontFamily="'MyriadPro-Regular'" fontSize="12">
            Belo-Sur{" "}
          </tspan>
          <tspan x="0" y="14.4" fontFamily="'MyriadPro-Regular'" fontSize="12">
            Tsiribihina
          </tspan>
        </text>
        <path
          d="M117.521,326.599c-3.349,0-6.062,2.665-6.062,5.946c0,3.285,6.062,14.054,6.062,14.054s6.063-10.771,6.063-14.054
                        S120.868,326.599,117.521,326.599z"
        />
      </g>

      {/* **********************************************************  
                                { Diego Suarez }
          ************************************************************ */}

      <g
        id="diego"
        className={hideorshow("diego")}
        onMouseEnter={(e) => showcityinfo(e, 17)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 304.6997 52.0542)"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Diego Suarez
        </text>

        <path
          d="M304.511,25.314c-3.349,0-6.063,2.665-6.063,5.946c0,3.285,6.063,14.054,6.063,14.054s6.063-10.77,6.063-14.054
                        C310.574,27.978,307.859,25.314,304.511,25.314z"
        />
        <g id="Beach_Diego" className="beach">
          <g
            id="Beach_Ifaty_4_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M118.885,13.974c-0.285-0.166-0.572-0.246-0.859-0.242
                c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
                c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
                c0.002,0.001,0.004,0.002,0.006,0.002h0.001c0.001,0,0.001,0.001,0.002,0.001h0.001c0.006,0.002,0.013,0.002,0.019,0.002
                c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001h0.001c0.003,0,0.005-0.001,0.008-0.001
                c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004c0.002-0.001,0.003-0.001,0.005-0.001
                c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019c0.002,0.001,0.003,0.002,0.005,0.003
                c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001h0.001c0.007,0.002,0.015,0.003,0.022,0.003
                c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005c0.005-0.002,0.009-0.003,0.013-0.006
                c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023c0.001-0.001,0.001-0.003,0.002-0.004
                c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7c-0.379-0.524-0.964-0.914-1.648-1.097
                c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288l0,0c-0.001,0.002-0.001,0.003-0.002,0.005
                c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019c0.001,0.007,0.003,0.012,0.005,0.019
                c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002c0.003,0.006,0.008,0.011,0.012,0.016
                c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007c0.006,0.003,0.012,0.007,0.018,0.009
                c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001c0.007,0.001,0.013,0.002,0.02,0.001
                c0.007-0.001,0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003c0.638-0.319,1.14-0.184,1.533,0.411
                c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014
                c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002c0.023,0.01,0.05,0.01,0.074-0.002
                c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027c-1.34,0-2.567,0.42-3.146,1.072
                c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123c0.025,0.043,0.08,0.057,0.123,0.032
                c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243c0.28,0,0.56-0.081,0.837-0.243
                c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
                c0.043,0.025,0.098,0.011,0.123-0.032C118.943,14.054,118.928,13.999,118.885,13.974z M115.619,10.091
                c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C116.716,9.977,116.215,9.848,115.619,10.091z
                 M118.386,9.551c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
                C117.704,8.857,118.107,9.164,118.386,9.551z M113.984,9.629c0.171-0.428,0.52-0.775,0.999-0.99
                c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C115.057,9.541,114.567,9.405,113.984,9.629z
                 M116.316,10.164c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L116.316,10.164z
                 M117.211,13.974c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
                c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
                C117.603,13.787,117.407,13.86,117.211,13.974z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M111.797,9.659c-0.017,0-0.034-0.002-0.05-0.007
                c-0.055-0.016-0.1-0.058-0.118-0.113l-0.084-0.246l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
                c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133
                c-0.008-0.057,0.012-0.115,0.054-0.154l0.189-0.179l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163s0.072-0.09,0.129-0.101
                l0.256-0.048l0.01-0.258c0.003-0.058,0.033-0.111,0.082-0.142c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212
                c0.033-0.047,0.088-0.075,0.145-0.075c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099
                c0.054-0.022,0.115-0.016,0.163,0.015c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046
                c0.057,0.011,0.105,0.049,0.129,0.101c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177
                c0.042,0.04,0.062,0.097,0.054,0.155c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251
                c0.014,0.056,0,0.116-0.038,0.16c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245
                c-0.019,0.055-0.063,0.097-0.118,0.113s-0.116,0.005-0.161-0.032l-0.204-0.162l-0.202,0.162
                C111.876,9.645,111.837,9.659,111.797,9.659z M111.548,9.113c0.076,0,0.143,0.048,0.168,0.121L111.8,9.48L112,9.317
                c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162l0.081-0.245c0.027-0.079,0.103-0.128,0.186-0.12l0.259,0.026l-0.065-0.251
                c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118l-0.19-0.176c-0.06-0.057-0.074-0.147-0.031-0.219l0.131-0.225l-0.255-0.045
                c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26l-0.239,0.1c-0.077,0.031-0.164,0.006-0.212-0.063l-0.149-0.211l-0.148,0.211
                c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098l-0.009,0.259c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222
                c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179l0.232,0.115c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028
                C111.535,9.114,111.541,9.113,111.548,9.113z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M112.112,9.021c-0.347,0-0.63-0.283-0.63-0.63c0-0.347,0.283-0.63,0.63-0.63
                c0.347,0,0.63,0.283,0.63,0.63C112.741,8.738,112.458,9.021,112.112,9.021z M112.112,7.941c-0.248,0-0.45,0.202-0.45,0.45
                c0,0.248,0.202,0.45,0.45,0.45c0.248,0,0.45-0.202,0.45-0.45C112.561,8.143,112.359,7.941,112.112,7.941z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Zafimaniry }
          ************************************************************ */}

      <g
        id="zafimaniry"
        className={hideorshow("zafimaniry")}
        onMouseEnter={(e) => showcityinfo(e, 21)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          id="Ambositra_1_"
          transform="matrix(1 0 0 1 227.7227 372.8901)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Zafimaniry
        </text>
        <path
          fill="#989898"
          d="M223.872,355.89c-3.349,0-6.063,2.664-6.063,5.945c0,3.285,6.063,14.055,6.063,14.055
                      s6.063-10.771,6.063-14.055S227.22,355.89,223.872,355.89z"
        />
      </g>

      {/* **********************************************************  
                                { Ambositra }
          ************************************************************ */}

      <g
        id="ambositra"
        className={hideorshow("ambositra")}
        onMouseEnter={(e) => showcityinfo(e, 31)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          id="Ambositra_1_"
          transform="matrix(1 0 0 1 227.7227 372.8901)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Ambositra
        </text>
        <path
          fill="#989898"
          d="M223.872,355.89c-3.349,0-6.063,2.664-6.063,5.945c0,3.285,6.063,14.055,6.063,14.055
                      s6.063-10.771,6.063-14.055S227.22,355.89,223.872,355.89z"
        />
      </g>

      {/* **********************************************************  
                                { Fianarantsoa }
          ************************************************************ */}

      <g
        id="fianarantsoa"
        className={hideorshow("fianarantsoa")}
        onMouseEnter={(e) => showcityinfo(e, 29)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 148.9893 417.6577)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Fianarantsoa
        </text>
        <path
          fill="#989898"
          d="M215.058,393.39c-3.35,0-6.063,2.664-6.063,5.945c0,3.285,6.063,14.055,6.063,14.055
                      s6.063-10.771,6.063-14.055S218.405,393.39,215.058,393.39z"
        />
      </g>

      {/* **********************************************************  
                                { Montagne d'Ambre }
          ************************************************************ */}

      <g
        id="Montagne_d_x27_Ambre"
        className={hideorshow("ambre")}
        onMouseEnter={(e) => showcityinfo(e, 0)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 302.4207 61.976)"
          fill="#D7D7D7"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Montagne D’ambre
        </text>
        <path
          fill="#D7D7D7"
          d="M302.235,35.236c-3.354,0-6.063,2.665-6.063,5.946c0,3.285,6.063,14.054,6.063,14.054
          s6.063-10.77,6.063-14.054C308.294,37.9,305.58,35.236,302.235,35.236z"
        />
      </g>

      {/* **********************************************************  
                                { Ankarana }
          ************************************************************ */}

      <g
        id="Ankarana"
        className={hideorshow("ankarana")}
        onMouseEnter={(e) => showcityinfo(e, 0)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 299.1453 75.3456)"
          fill="#D7D7D7"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Ankarana
        </text>
        <path
          fill="#D7D7D7"
          d="M298.961,48.606c-3.354,0-6.063,2.665-6.063,5.946c0,3.285,6.063,14.054,6.063,14.054
          s6.063-10.77,6.063-14.054C305.019,51.27,302.305,48.606,298.961,48.606z"
        />
      </g>

      {/* **********************************************************  
                                { Manakara }
          ************************************************************ */}

      <g
        id="Manakara"
        className={hideorshow("manakara")}
        onMouseEnter={(e) => showcityinfo(e, 35)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 252.2605 449.2597)"
          fill="#D7D7D7"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Manakara
        </text>
        <path
          fill="#D7D7D7"
          d="M252.075,422.52c-3.354,0-6.063,2.665-6.063,5.946c0,3.285,6.063,14.054,6.063,14.054
          s6.063-10.771,6.063-14.054C258.134,425.185,255.42,422.52,252.075,422.52z"
        />
      </g>

      {/* **********************************************************  
                                { Manja }
          ************************************************************ */}

      <g
        id="manja"
        className={hideorshow("manja")}
        onMouseEnter={(e) => showcityinfo(e, 32)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 113.241 421.332)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Manja
        </text>
        <path
          fill="#989898"
          d="M113.242,391.408c-3.354,0-6.063,2.664-6.063,5.947c0,3.277,6.063,14.053,6.063,14.053
          s6.062-10.771,6.062-14.053C119.303,394.073,116.59,391.408,113.242,391.408z"
        />
      </g>

      {/* **********************************************************  
                              { Morombe }
        ************************************************************ */}
      <g
        id="morombe"
        className={hideorshow("morombe")}
        onMouseEnter={(e) => showcityinfo(e, 33)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 81.4446 428.7939)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Morombe
        </text>
        <path
          fill="#989898"
          d="M77.383,406.37c-3.354,0-6.063,2.664-6.063,5.947c0,3.277,6.063,14.053,6.063,14.053
            s6.062-10.771,6.062-14.053C83.445,409.035,80.731,406.37,77.383,406.37z"
        />
        <g id="Beach_Belo-sur-Mer" className="beach">
          <g
            id="Beach_Ifaty_9_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M31.03,137.429c-0.285-0.166-0.572-0.246-0.859-0.242
                  c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
                  c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
                  c0.002,0.001,0.004,0.002,0.006,0.002h0.001c0.001,0,0.001,0.001,0.002,0.001h0.001c0.006,0.002,0.013,0.002,0.019,0.002
                  c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001h0.001c0.003,0,0.005-0.001,0.008-0.001
                  c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004c0.002-0.001,0.003-0.001,0.005-0.001
                  c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019c0.002,0.001,0.003,0.002,0.005,0.003
                  c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001h0.001c0.007,0.002,0.015,0.003,0.022,0.003
                  c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005c0.005-0.002,0.009-0.003,0.013-0.006
                  c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023c0.001-0.001,0.001-0.003,0.002-0.004
                  c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7c-0.379-0.524-0.964-0.914-1.648-1.097
                  c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288l0,0c-0.001,0.002-0.001,0.003-0.002,0.005
                  c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019c0.001,0.007,0.003,0.012,0.005,0.019
                  c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002c0.003,0.006,0.008,0.011,0.012,0.016
                  c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007c0.006,0.003,0.012,0.007,0.018,0.009
                  c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001c0.007,0.001,0.013,0.002,0.02,0.001
                  c0.007-0.001,0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003c0.638-0.319,1.14-0.184,1.533,0.411
                  c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014
                  c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002c0.023,0.01,0.05,0.01,0.074-0.002
                  c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027c-1.34,0-2.567,0.42-3.146,1.072
                  c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123c0.025,0.043,0.08,0.057,0.123,0.032
                  c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243c0.28,0,0.56-0.081,0.837-0.243
                  c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
                  c0.043,0.025,0.098,0.011,0.123-0.032C31.088,137.509,31.073,137.454,31.03,137.429z M27.763,133.546
                  c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C28.861,133.432,28.359,133.303,27.763,133.546z
                   M30.531,133.006c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
                  C29.849,132.312,30.251,132.619,30.531,133.006z M26.129,133.084c0.171-0.428,0.52-0.775,0.999-0.99
                  c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C27.202,132.996,26.711,132.86,26.129,133.084z
                   M28.461,133.619c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L28.461,133.619z
                   M29.356,137.429c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
                  c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
                  C29.748,137.242,29.551,137.315,29.356,137.429z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M23.942,133.114c-0.017,0-0.034-0.002-0.05-0.007
                  c-0.055-0.016-0.1-0.058-0.118-0.113l-0.084-0.246l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
                  c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133s0.012-0.115,0.054-0.154
                  l0.189-0.179l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163c0.024-0.052,0.072-0.09,0.129-0.101l0.256-0.048l0.01-0.258
                  c0.003-0.058,0.033-0.111,0.082-0.142c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212
                  c0.033-0.047,0.088-0.075,0.145-0.075c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099
                  c0.054-0.022,0.115-0.016,0.163,0.015c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046
                  c0.057,0.011,0.105,0.049,0.129,0.101c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177
                  c0.042,0.04,0.062,0.097,0.054,0.155c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251
                  c0.014,0.056,0,0.116-0.038,0.16c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245
                  c-0.019,0.055-0.063,0.097-0.118,0.113c-0.055,0.016-0.116,0.005-0.161-0.032l-0.204-0.162l-0.202,0.162
                  C24.021,133.1,23.982,133.114,23.942,133.114z M23.692,132.568c0.076,0,0.143,0.048,0.168,0.121l0.084,0.246l0.201-0.163
                  c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162l0.081-0.245c0.027-0.079,0.103-0.128,0.186-0.12l0.259,0.026l-0.065-0.251
                  c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118l-0.19-0.176c-0.06-0.057-0.074-0.147-0.031-0.219l0.131-0.225l-0.255-0.045
                  c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26l-0.239,0.1c-0.077,0.031-0.164,0.006-0.212-0.063l-0.149-0.211l-0.148,0.211
                  c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098l-0.009,0.259c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222
                  c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179l0.232,0.115c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028
                  C23.68,132.569,23.686,132.568,23.692,132.568z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M24.256,132.476c-0.347,0-0.63-0.283-0.63-0.63c0-0.347,0.283-0.63,0.63-0.63
                  c0.347,0,0.63,0.283,0.63,0.63C24.886,132.193,24.603,132.476,24.256,132.476z M24.256,131.397c-0.248,0-0.45,0.202-0.45,0.45
                  c0,0.248,0.202,0.45,0.45,0.45c0.248,0,0.45-0.202,0.45-0.45C24.706,131.598,24.504,131.397,24.256,131.397z"
            />
          </g>
        </g>
      </g>
      {/* **********************************************************  
                              { Salary }
        ************************************************************ */}
      <g
        id="Salary"
        className={hideorshow("salary")}
        onMouseEnter={(e) => showcityinfo(e, 34)}
        onMouseLeave={() => setCurrentdetails("")}
      >
        <text
          transform="matrix(1 0 0 1 79.7844 457.3759)"
          fill="#343434"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12"
        >
          Salary
        </text>
        <path
          fill="#989898"
          d="M75.383,439.26c-3.354,0-6.063,2.664-6.063,5.947c0,3.277,6.063,14.053,6.063,14.053
          s6.062-10.771,6.062-14.053C81.445,441.925,78.731,439.26,75.383,439.26z"
        />
        <g id="Beach_Salary" className="beach">
          <g
            id="Beach_Ifaty_7_"
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
          >
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M23.561,161.87c-0.285-0.166-0.572-0.246-0.859-0.242
              c-0.416-0.469-1.168-0.817-2.055-0.976l0.663-2.474c0.162,0.096,0.31,0.245,0.446,0.45c0.005,0.007,0.01,0.013,0.016,0.018
              c0.001,0.001,0.002,0.001,0.002,0.002c0.002,0.001,0.003,0.002,0.005,0.003c0.006,0.005,0.013,0.008,0.02,0.01
              c0.002,0.001,0.004,0.002,0.006,0.002h0.001c0.001,0,0.001,0.001,0.002,0.001h0.001c0.006,0.002,0.013,0.002,0.019,0.002
              c0.001,0,0.002,0.001,0.004,0.001c0.001,0,0.001-0.001,0.002-0.001h0.001c0.003,0,0.005-0.001,0.008-0.001
              c0.005-0.001,0.011-0.001,0.017-0.003c0.003-0.001,0.005-0.003,0.008-0.004c0.002-0.001,0.003-0.001,0.005-0.001
              c0.638-0.319,1.14-0.185,1.533,0.411c0.005,0.008,0.011,0.014,0.018,0.019c0.002,0.001,0.003,0.002,0.005,0.003
              c0.008,0.006,0.017,0.011,0.027,0.014c0.001,0,0.001,0,0.002,0.001h0.001c0.007,0.002,0.015,0.003,0.022,0.003
              c0.009,0,0.018-0.002,0.027-0.005c0.003-0.001,0.006-0.003,0.009-0.005c0.005-0.002,0.009-0.003,0.013-0.006
              c0.001,0,0.001-0.001,0.002-0.002c0.009-0.006,0.017-0.014,0.023-0.023c0.001-0.001,0.001-0.003,0.002-0.004
              c0.004-0.007,0.008-0.014,0.01-0.023c0.152-0.566,0.025-1.17-0.358-1.7c-0.379-0.524-0.964-0.914-1.648-1.097
              c-0.683-0.183-1.385-0.138-1.975,0.127c-0.595,0.267-1.006,0.724-1.159,1.288l0,0c-0.001,0.002-0.001,0.003-0.002,0.005
              c-0.001,0.004,0,0.008-0.001,0.013c-0.001,0.007-0.002,0.013-0.001,0.019c0.001,0.007,0.003,0.012,0.005,0.019
              c0.001,0.004,0.001,0.008,0.003,0.013c0.001,0.001,0.001,0.001,0.002,0.002c0.003,0.006,0.008,0.011,0.012,0.016
              c0.003,0.004,0.006,0.008,0.01,0.011c0.004,0.003,0.009,0.005,0.013,0.007c0.006,0.003,0.012,0.007,0.018,0.009
              c0.001,0,0.001,0.001,0.002,0.001c0.004,0.001,0.008,0.001,0.013,0.001c0.007,0.001,0.013,0.002,0.02,0.001
              c0.007-0.001,0.013-0.003,0.02-0.006c0.004-0.001,0.008-0.001,0.011-0.003c0.638-0.319,1.14-0.184,1.533,0.411
              c0.005,0.008,0.011,0.014,0.018,0.02c0.002,0.001,0.003,0.002,0.005,0.003c0.008,0.006,0.017,0.011,0.027,0.014
              c0.001,0,0.001,0.001,0.002,0.001c0,0,0,0,0.001,0c0.004,0.001,0.008,0.001,0.012,0.002c0.023,0.01,0.05,0.01,0.074-0.002
              c0.21-0.105,0.405-0.16,0.586-0.166l-0.678,2.532c-0.188-0.017-0.379-0.027-0.573-0.027c-1.34,0-2.567,0.42-3.146,1.072
              c-0.287-0.004-0.574,0.076-0.859,0.242c-0.043,0.025-0.057,0.08-0.032,0.123c0.025,0.043,0.08,0.057,0.123,0.032
              c0.502-0.292,0.991-0.293,1.493,0c0.278,0.162,0.557,0.243,0.837,0.243c0.28,0,0.56-0.081,0.837-0.243
              c0.502-0.292,0.991-0.293,1.493,0c0.556,0.324,1.119,0.324,1.674,0c0.503-0.293,0.991-0.293,1.493,0
              c0.043,0.025,0.098,0.011,0.123-0.032C23.619,161.95,23.604,161.896,23.561,161.87z M20.294,157.988
              c0.32-1.055,0.875-1.66,1.219-1.568c0.342,0.092,0.52,0.886,0.276,1.951C21.392,157.874,20.89,157.745,20.294,157.988z
              M23.062,157.447c0.307,0.425,0.435,0.9,0.37,1.356c-0.392-0.485-0.885-0.613-1.47-0.383c0.18-0.78,0.15-1.477-0.055-1.866
              C22.38,156.753,22.782,157.061,23.062,157.447z M18.66,157.526c0.171-0.428,0.52-0.775,0.999-0.99
              c0.436-0.195,0.938-0.26,1.446-0.195c-0.373,0.234-0.746,0.823-0.981,1.589C19.733,157.437,19.242,157.301,18.66,157.526z
              M20.992,158.061c0.052,0.008,0.103,0.022,0.153,0.04l-0.676,2.522c-0.052-0.008-0.104-0.016-0.157-0.022L20.992,158.061z
              M21.887,161.87c-0.503,0.293-0.991,0.293-1.493,0c-0.555-0.324-1.119-0.324-1.674,0c-0.502,0.293-0.991,0.293-1.493,0
              c-0.195-0.114-0.392-0.187-0.588-0.22c0.579-0.56,1.697-0.914,2.919-0.914c1.221,0,2.339,0.355,2.918,0.914
              C22.279,161.684,22.082,161.757,21.887,161.87z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M16.473,157.556c-0.017,0-0.034-0.002-0.05-0.007
              c-0.055-0.016-0.1-0.058-0.118-0.113l-0.084-0.246l-0.257,0.027c-0.057,0.005-0.114-0.017-0.152-0.06
              c-0.038-0.044-0.052-0.103-0.038-0.16l0.062-0.253l-0.231-0.116c-0.052-0.026-0.087-0.076-0.095-0.133
              c-0.008-0.057,0.012-0.115,0.054-0.154l0.189-0.179l-0.132-0.222c-0.029-0.05-0.032-0.111-0.008-0.163
              c0.024-0.052,0.072-0.09,0.129-0.101l0.256-0.048l0.01-0.258c0.003-0.058,0.033-0.111,0.082-0.142
              c0.048-0.031,0.109-0.037,0.163-0.015l0.241,0.098l0.148-0.212c0.033-0.047,0.088-0.075,0.145-0.075
              c0.058,0,0.112,0.028,0.145,0.075l0.15,0.213l0.239-0.099c0.054-0.022,0.115-0.016,0.163,0.015
              c0.049,0.031,0.079,0.084,0.082,0.142l0.011,0.26l0.254,0.046c0.057,0.011,0.105,0.049,0.129,0.101
              c0.024,0.053,0.021,0.114-0.008,0.164l-0.131,0.225l0.189,0.177c0.042,0.04,0.062,0.097,0.054,0.155
              c-0.008,0.057-0.044,0.107-0.095,0.133l-0.232,0.118l0.063,0.251c0.014,0.056,0,0.116-0.038,0.16
              c-0.038,0.044-0.095,0.066-0.152,0.06l-0.259-0.026l-0.082,0.245c-0.019,0.055-0.063,0.097-0.118,0.113s-0.116,0.005-0.161-0.032
              l-0.204-0.162l-0.202,0.162C16.552,157.542,16.513,157.556,16.473,157.556z M16.223,157.01c0.076,0,0.143,0.048,0.168,0.121
              l0.084,0.246l0.201-0.163c0.065-0.052,0.156-0.052,0.221,0l0.204,0.162l0.081-0.245c0.027-0.079,0.103-0.128,0.186-0.12
              l0.259,0.026l-0.065-0.251c-0.02-0.08,0.018-0.163,0.092-0.201l0.232-0.118l-0.19-0.176c-0.06-0.057-0.074-0.147-0.031-0.219
              l0.131-0.225l-0.255-0.045c-0.082-0.015-0.141-0.084-0.145-0.167l-0.011-0.26l-0.239,0.1c-0.077,0.031-0.164,0.006-0.212-0.063
              l-0.149-0.211l-0.148,0.211c-0.048,0.068-0.135,0.093-0.212,0.062l-0.242-0.098l-0.009,0.259
              c-0.004,0.083-0.063,0.152-0.145,0.167l-0.256,0.048l0.132,0.222c0.042,0.072,0.029,0.162-0.031,0.219l-0.189,0.179l0.232,0.115
              c0.074,0.038,0.112,0.121,0.092,0.202l-0.063,0.253l0.257-0.028C16.211,157.011,16.217,157.01,16.223,157.01z"
            />
            <path
              stroke="#0077CC"
              strokeMiterlimit="10"
              d="M16.787,156.917c-0.347,0-0.63-0.283-0.63-0.63c0-0.347,0.283-0.63,0.63-0.63
              c0.347,0,0.63,0.283,0.63,0.63C17.417,156.635,17.134,156.917,16.787,156.917z M16.787,155.838c-0.248,0-0.45,0.202-0.45,0.45
              c0,0.248,0.202,0.45,0.45,0.45c0.248,0,0.45-0.202,0.45-0.45C17.237,156.04,17.035,155.838,16.787,155.838z"
            />
          </g>
        </g>
      </g>

      {/* **********************************************************  
                                { Ankarafantsika }
          ************************************************************ */}

      <g id="Parc_Ankarafantsika" className={hideorshow("ankarafantsika")}>
        <g
          id="parc_Ranomafana_2_"
          className="park"
          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
        >
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M69.027,80.668c-0.896,0-1.625-0.729-1.625-1.625
              c0-0.773,0.638-3.006,1.625-3.006c0.986,0,1.624,2.233,1.624,3.006C70.65,79.938,69.923,80.668,69.027,80.668z M69.027,76.34
              c-0.659,0-1.322,1.853-1.322,2.703c0,0.728,0.593,1.322,1.322,1.322c0.728,0,1.321-0.593,1.321-1.322
              C70.347,78.193,69.684,76.34,69.027,76.34z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M69.029,81.771c-0.083,0-0.151-0.068-0.151-0.152v-3.271
              c0-0.084,0.067-0.152,0.151-0.152c0.084,0,0.152,0.068,0.152,0.152v3.271C69.182,81.703,69.113,81.771,69.029,81.771z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M69.029,79.152c-0.041,0-0.081-0.016-0.111-0.049
              c-0.057-0.062-0.053-0.157,0.008-0.214l0.443-0.41c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.061,0.053,0.157-0.008,0.214
              l-0.443,0.41C69.103,79.139,69.066,79.152,69.029,79.152z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M69.029,79.782c-0.037,0-0.074-0.014-0.103-0.041l-0.619-0.572
              c-0.061-0.057-0.065-0.153-0.008-0.214c0.056-0.062,0.153-0.065,0.213-0.008l0.619,0.573c0.062,0.057,0.065,0.153,0.008,0.214
              C69.111,79.765,69.07,79.782,69.029,79.782z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M71.147,80.932c-0.527,0-1.006-0.332-1.191-0.825
              c-0.031-0.078,0.01-0.166,0.088-0.195c0.078-0.03,0.166,0.01,0.195,0.089c0.141,0.376,0.506,0.628,0.908,0.628
              c0.535,0,0.969-0.434,0.969-0.969c0-0.535-0.434-0.969-0.969-0.969c-0.21,0-0.409,0.066-0.577,0.19
              c-0.067,0.05-0.163,0.035-0.212-0.031c-0.05-0.068-0.036-0.163,0.031-0.213c0.22-0.164,0.483-0.25,0.758-0.25
              c0.702,0,1.272,0.571,1.272,1.272C72.42,80.361,71.849,80.932,71.147,80.932z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M71.149,81.771c-0.084,0-0.152-0.068-0.152-0.152v-2.208
              c0-0.084,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.208C71.301,81.703,71.233,81.771,71.149,81.771z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M71.149,80.058c-0.041,0-0.082-0.016-0.111-0.049
              c-0.057-0.062-0.053-0.157,0.008-0.214l0.337-0.312c0.062-0.057,0.156-0.053,0.213,0.009c0.057,0.061,0.054,0.157-0.009,0.214
              l-0.336,0.312C71.222,80.045,71.186,80.058,71.149,80.058z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M71.149,80.538c-0.037,0-0.074-0.014-0.103-0.041l-0.471-0.436
              c-0.062-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.213-0.008l0.471,0.436c0.062,0.057,0.065,0.153,0.008,0.214
              C71.231,80.522,71.19,80.538,71.149,80.538z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M67.028,81.771c-0.083,0-0.152-0.068-0.152-0.152v-2.145
              c0-0.083,0.068-0.152,0.152-0.152c0.084,0,0.152,0.068,0.152,0.152v2.145C67.181,81.703,67.113,81.771,67.028,81.771z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M67.03,80.123c-0.041,0-0.082-0.016-0.112-0.049
              c-0.056-0.062-0.054-0.157,0.009-0.214l0.339-0.313c0.061-0.057,0.157-0.053,0.214,0.008c0.057,0.062,0.053,0.157-0.009,0.214
              l-0.338,0.313C67.103,80.11,67.067,80.123,67.03,80.123z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M67.028,80.604c-0.037,0-0.074-0.014-0.103-0.041l-0.473-0.436
              c-0.061-0.057-0.065-0.153-0.008-0.214c0.057-0.062,0.153-0.065,0.214-0.008l0.473,0.437c0.062,0.057,0.065,0.153,0.008,0.214
              C67.11,80.588,67.069,80.604,67.028,80.604z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M67.896,81.087h-1.737c-0.312,0-0.565-0.254-0.565-0.565
              c0-0.179,0.085-0.345,0.224-0.45c-0.007-0.044-0.01-0.089-0.01-0.133c0-0.275,0.133-0.535,0.351-0.697c0-0.002,0-0.003,0-0.005
              c0-0.48,0.39-0.869,0.869-0.869c0.241,0,0.474,0.101,0.637,0.278c0.057,0.062,0.054,0.157-0.008,0.214
              c-0.062,0.057-0.157,0.054-0.215-0.008c-0.108-0.117-0.255-0.181-0.416-0.181c-0.311,0-0.566,0.254-0.566,0.566
              c0,0.022,0.002,0.044,0.004,0.065c0.007,0.06-0.022,0.119-0.074,0.148c-0.172,0.102-0.281,0.289-0.281,0.489
              c0,0.054,0.008,0.109,0.024,0.162c0.022,0.073-0.014,0.15-0.083,0.181c-0.094,0.043-0.155,0.136-0.155,0.239
              c0,0.144,0.117,0.262,0.262,0.262h1.737c0.144,0,0.262-0.118,0.262-0.262c0-0.051-0.015-0.101-0.043-0.144
              c-0.046-0.07-0.026-0.164,0.043-0.21c0.07-0.046,0.164-0.026,0.21,0.043c0.061,0.092,0.093,0.199,0.093,0.311
              C68.462,80.833,68.207,81.087,67.896,81.087z"
          />
          <path
            stroke="#008000"
            strokeMiterlimit="10"
            d="M72.268,81.771h-6.523c-0.084,0-0.152-0.068-0.152-0.152
              c0-0.084,0.068-0.152,0.152-0.152h6.523c0.084,0,0.152,0.068,0.152,0.152C72.42,81.703,72.352,81.771,72.268,81.771z"
          />
        </g>
      </g>
    </g>
  );
};

export default Cities;
